import { types, Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree';

export const LOCATION_TYPE_SEAPORT = 'Seaport';
export const LOCATION_TYPE_AIRPORT = 'Airport';
export const LOCATION_TYPE_ICD = 'ICD';
export const LOCATION_TYPE_CITY = 'City';
export const LOCATION_TYPE_RAIL_TERMINAL = 'RailTerminal';
export const LOCATION_TYPE_PORT_TERMINAL = 'PortTerminal';
export const LOCATION_TYPE_CFS = 'CFS';
export const LOCATION_TYPE_WAREHOUSE = 'Warehouse';
export const LOCATION_TYPE_AIR_FREIGHT_STATION = 'AirFreightStation';

export declare type LocationType =
  | 'Seaport'
  | 'Airport'
  | 'ICD'
  | 'City'
  | 'RailTerminal'
  | 'PortTerminal'
  | 'CFS'
  | 'Warehouse'
  | 'AirFreightStation';

const Location = types.model({
  id: types.identifier,
  name: types.string,
  type: types.maybe(
    types.maybeNull(
      types.union(
        types.literal(LOCATION_TYPE_SEAPORT),
        types.literal(LOCATION_TYPE_AIRPORT),
        types.literal(LOCATION_TYPE_ICD),
        types.literal(LOCATION_TYPE_CITY),
        types.literal(LOCATION_TYPE_RAIL_TERMINAL),
        types.literal(LOCATION_TYPE_PORT_TERMINAL),
        types.literal(LOCATION_TYPE_CFS),
        types.literal(LOCATION_TYPE_WAREHOUSE),
        types.literal(LOCATION_TYPE_AIR_FREIGHT_STATION)
      )
    )
  ),
  city: types.maybe(
    types.maybeNull(
      types.union(types.string, types.model({ name: types.maybe(types.maybeNull(types.string)) }))
    )
  ),
  city_code: types.maybe(types.maybeNull(types.string)),
  state: types.maybe(types.maybeNull(types.string)),
  state_code: types.maybe(types.maybeNull(types.string)),
  country: types.maybe(
    types.maybeNull(
      types.union(
        types.string,
        types.model({
          name: types.maybe(types.maybeNull(types.string)),
          code: types.maybe(types.maybeNull(types.string)),
        })
      )
    )
  ),
  country_name: types.maybe(types.maybeNull(types.string)),
  country_code: types.maybe(types.maybeNull(types.string)),
  unlocode: types.maybe(types.maybeNull(types.string)),
  iata_code: types.maybe(types.maybeNull(types.string)),
  address: types.maybe(types.maybeNull(types.string)),
  is_customs_location: types.maybe(types.maybeNull(types.boolean)),
  customs_code: types.maybe(types.maybeNull(types.string)),
  city_id: types.maybe(types.maybeNull(types.number)),
  parent_id: types.maybe(types.maybeNull(types.number)),
});

export type LocationValue = Instance<typeof Location>;
export type LocationIn = SnapshotIn<typeof Location>;
export type LocationOut = SnapshotOut<typeof Location>;

export default Location;
