import React from 'react';
import { Row, Col, Select, Form, Input } from '@shipmnts/pixel-hub';
import { ERPNextConfigData } from 'network/utils/ErpNextConfigDataWrapper';
import CompanyAccountsTable from './CompanyAccountsTable';
import { useSession } from 'common';
import { CompanyValue } from 'network/models/Company';
import { GlobalSearch } from '@shipmnts/pixel-hub';

interface CustomerLedgerDetailsFormProps {
  erpnextConfigData: ERPNextConfigData;
  company?: CompanyValue;
}

const ROW_GUTTER = 32;

const CustomerLedgerDetailsForm = React.memo(function CustomerLedgerDetailsForm(
  props: CustomerLedgerDetailsFormProps
): JSX.Element {
  const { erpnextConfigData, company } = props;
  const session = useSession();
  const tenant_country_code = session?.company_account?.default_company?.country_of_incorporation;
  return (
    <>
      <Row gutter={ROW_GUTTER}>
        <Col span={12} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <Form.Item name="default_currency" label="Default Billing Currency">
            <GlobalSearch doc_type="ERP::Currency" />
          </Form.Item>
        </Col>
        <Col span={12} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <Form.Item
            name={`receivable_tax_withholding_category`}
            label={`Receivable Tax Withholding Category`}
          >
            <GlobalSearch doc_type="ERP::ReceivableTaxWithholdingCategories" />
          </Form.Item>
        </Col>
        {tenant_country_code === 'IN' &&
          company?.country_of_incorporation === 'IN' &&
          company &&
          !company?.tax_registration_number && (
            <Col span={12} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
              <Form.Item
                name="tax_registration_number"
                label={`Tax ID (PAN)`}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          )}
        <Col span={12} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
          <Form.Item name="is_msme" label="Is MSME" rules={[{ required: true }]}>
            <Select
              style={{
                width: 90,
              }}
              options={[
                {
                  value: 1,
                  label: 'Yes',
                },
                {
                  value: 0,
                  label: 'No',
                },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <CompanyAccountsTable account_type="receivable" erpnextConfigData={erpnextConfigData} />
        </Col>
      </Row>
    </>
  );
});

export default CustomerLedgerDetailsForm;
