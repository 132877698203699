import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  CheckboxChangeEvent,
  Col,
  Form,
  GlobalSearch,
  InputNumber,
  Row,
  Select,
  Space,
} from '@shipmnts/pixel-hub';
import { uniqueId as _uniqueId } from 'lodash';

import {
  containerSettingsMap,
  containerTypesArray,
  containerTypesHash,
  LOAD_TYPE_FCL,
  WEIGHT_UNIT_KGS,
  WEIGHT_UNIT_MTS,
} from 'common/baseConstants';
import { useInquiryForm } from './InquiryForm';
import { isLiveReeferCargo } from 'sales_hub/models/Cargo';
import { ContainerDetails } from 'operations';
import { ContainerRequestValue } from 'sales_hub/models/ContainerRequest';
import CargoNewComponent from 'operations/modules/shipment/components/DetailLayout/Cargo/CargoNewComponent/CargoNewComponent';

const QuickContainerDetails = () => {
  const { isDetailForm, form, disable } = useInquiryForm();
  const [selectedKeys, setSelectedKeys] = useState<Array<number>>([]);
  const containerRequestsRef = useRef<{ runValidation: () => boolean }>();

  const cargos = Form.useWatch('cargos', form);
  const isLiveReefer = useMemo(() => (cargos ? isLiveReeferCargo(cargos) : false), [cargos]);

  // is live reefer cargo is selected, clear other container details
  useEffect(() => {
    if (isLiveReefer) {
      const container_requests = form.getFieldValue('containerRequests');
      const new_container_requests = container_requests.map((cr: ContainerRequestValue) => {
        const new_cr = cr;
        if (
          cr.container_type_code &&
          !Object.keys(containerSettingsMap.reefer).includes(cr.container_type_code)
        ) {
          new_cr['container_type_code'] = undefined;
          new_cr['container_type'] = undefined;
          new_cr['container_settings'] = undefined;
        }
        return new_cr;
      });
      form.setFieldValue('containerRequests', new_container_requests);
    }
  }, [isLiveReefer, form]);

  const toggleSelectedKey = (e: CheckboxChangeEvent, index: number) => {
    if (e.target.checked) setSelectedKeys([index].concat(selectedKeys));
    else setSelectedKeys(selectedKeys.filter((key) => key !== index));
  };

  return (
    <div style={{ marginBottom: '1em' }}>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const cargos = getFieldValue('cargos');
          const isLiveReefer = cargos ? isLiveReeferCargo(cargos) : false;
          const containerTypeOptions = Object.entries(
            isLiveReefer ? { Reefer: containerTypesArray['Reefer'] } : containerTypesArray
          ).map(([key, value]) => ({
            label: key,
            options: value?.map((v: any) => ({
              value: v.key,
              label: v.name,
            })),
          }));

          return isDetailForm ? (
            <Form.Item
              name="containerRequests"
              noStyle
              rules={[
                {
                  validator: (rule, value) => {
                    if (!containerRequestsRef?.current?.runValidation()) {
                      return Promise.resolve();
                    }
                    return Promise.reject();
                  },
                },
              ]}
            >
              <ContainerDetails
                ref={containerRequestsRef}
                required_fields={['container_type_code', 'quantity']}
                isLiveReefer={isLiveReefer}
                required={false}
                disableAdd={disable}
                disableDelete={disable}
                disabled={disable}
              />
            </Form.Item>
          ) : (
            <Form.List name={'containerRequests'}>
              {(fields, { add, remove }, { errors }) => {
                return (
                  <div style={{ marginBottom: '1rem' }}>
                    {fields.map((field, index) => {
                      return (
                        <Row gutter={{ lg: 16 }} key={index}>
                          {fields.length > 1 && (
                            <div style={{ paddingLeft: '12px', width: '24px' }}>
                              <Form.Item label={index === 0 ? ' ' : undefined}>
                                <Checkbox
                                  checked={selectedKeys.includes(index)}
                                  onChange={(e) => toggleSelectedKey(e, index)}
                                />
                              </Form.Item>
                            </div>
                          )}
                          <Col xs={24} sm={12} lg={6}>
                            <Form.Item
                              name={[field.name, 'container_type_code']}
                              label={index === 0 ? 'Container Type' : undefined}
                              style={{ marginBottom: 0 }}
                              rules={[{ required: true }]}
                            >
                              <GlobalSearch
                                doc_type="Shipment::ContainerType"
                                selectProps={{
                                  options: containerTypeOptions,
                                }}
                                onChange={(val) => {
                                  console.log(val);
                                  const containerRequests = form.getFieldValue('containerRequests');
                                  console.log(val, containerRequests);
                                  const containerType = containerTypesHash[val];
                                  Object.assign(containerRequests[field.name], {
                                    container_type: containerType,
                                  });
                                  form.setFieldsValue({ containerRequests });
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12} lg={2}>
                            <Form.Item
                              name={[field.name, 'quantity']}
                              label={index === 0 ? 'Qty' : undefined}
                              style={{ marginBottom: 0 }}
                              rules={[{ required: true }]}
                            >
                              <InputNumber style={{ width: '100%' }} precision={0} min={1} />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12} lg={6}>
                            <Form.Item label={index === 0 ? 'Weight' : undefined}>
                              <Space.Compact>
                                <Form.Item
                                  name={[field.name, 'weight_per_container']}
                                  style={{ marginBottom: 0 }}
                                >
                                  <InputNumber
                                    style={{ width: '180px' }}
                                    min={0}
                                    precision={2}
                                    placeholder="Weight per Container"
                                  />
                                </Form.Item>
                                <Form.Item
                                  name={[field.name, 'weight_unit']}
                                  style={{ marginBottom: 0 }}
                                >
                                  <Select
                                    style={{ width: '80px' }}
                                    placeholder={'Unit'}
                                    options={[WEIGHT_UNIT_KGS, WEIGHT_UNIT_MTS].map((opt) => ({
                                      value: opt,
                                      label: opt,
                                    }))}
                                  />
                                </Form.Item>
                              </Space.Compact>
                            </Form.Item>
                          </Col>
                        </Row>
                      );
                    })}
                    <Form.ErrorList errors={errors} />
                    <Row gutter={8}>
                      <Col>
                        <Button
                          size="small"
                          onClick={() =>
                            add({
                              container_type: '22G0',
                              quantity: 1,
                              weight_unit: WEIGHT_UNIT_KGS,
                              _id: _uniqueId('cr_'),
                            })
                          }
                        >
                          Add Container
                        </Button>
                      </Col>
                      {fields.length > 1 && (
                        <Col>
                          <Button
                            danger
                            size="small"
                            onClick={() => {
                              remove(selectedKeys);
                              setSelectedKeys([]);
                            }}
                            disabled={!selectedKeys.length}
                          >
                            Delete Container(s)
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </div>
                );
              }}
            </Form.List>
          );
        }}
      </Form.Item>
    </div>
  );
};

const InquiryCargoDetails = () => {
  const { freightType, loadType, form, cargoRef } = useInquiryForm();

  const showContainerDetails = useMemo(() => loadType === LOAD_TYPE_FCL, [loadType]);

  return (
    <>
      <h3 className="inq-form-section-title">{`${
        showContainerDetails ? 'Container & ' : ''
      } Cargo Details`}</h3>
      {showContainerDetails && <QuickContainerDetails />}
      <CargoNewComponent loadType={loadType} freightType={freightType} ref={cargoRef} form={form} />
    </>
  );
};

export default InquiryCargoDetails;
