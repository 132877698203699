import { startCase as _startCase } from 'lodash';

// Data is procured from https://adrdangerousgoods.com/eng/substances/all/
// #JS
// var temp1 = $("#searchResults ul").children
// arr = []
// for(var i=0; i<temp1.length; i++){
//     var child = temp1[i];
//     arr.push(child.innerText);
// }
// Copy the arr as a text array to rails console
// #RUBY
// raw = [....]
// haz_json = {}

// raw.each do |text|
//   next unless text.present?
//   arr = text.split("\n")
//   min_index = 0
//   min_index = 1 if arr.length == 4
//   un_number = arr[min_index]
//   psn = arr[min_index + 1]
//   dg_class = arr[min_index + 2].split(",")[0].split("(")[0].strip
//   haz_json[un_number] = { "psn" => psn, "class" => dg_class } unless haz_json[un_number].present?
// end
// File.open("haz_json.json", "w") { |f| f.write(haz_json.to_json) }

import { RadioOptionProps } from 'common/commonTypeDefs';

import hazData from './dg_mapping.json';

export const sessionKey = `${process.env.WORKOS_CLIENT_ID}::session`;

type FieldType =
  | 'Boolean'
  | 'Text'
  | 'RichText'
  | 'Table'
  | 'Date'
  | 'Section Break'
  | 'Row Break'
  | 'Search'
  | 'Radio'
  | 'Row Gutter'
  | 'InputWithUnit'
  | 'CheckBoxGroup'
  | 'Button'
  | 'UploadDoc'
  | 'Typography';

export type FormRendererField = {
  field_name: string | string[];
  field_type: FieldType;
  field_required?: boolean;
  label?: string;
  component_props?: { [key: string]: any };
  form_item_props?: { [key: string]: any };
  reqd?: boolean;
  disabled?: boolean;
  default?: any;
  extra_props?: any;
  col_span?: number;
  dependencies?: any;
  dependent_rules?: any;
  noStyle?: boolean;
};
export const RENDER_SELECT = 'select';
export const RENDER_INPUT = 'input';
export const RENDER_INPUT_NUMBER = 'input_number';
export const RENDER_TEXT = 'text_view';
export const RENDER_FORMATTED_TEXT = 'formatted_text_view';
export const RENDER_NUMBER = 'number';
export const RENDER_DATE = 'date';
export const RENDER_HIDDEN_FIELD = 'hidden_field';
export const RENDER_CUSTOM_FIELD = 'custom_field';
export const RENDER_CHECKBOX = 'checkbox';
export const LOAD_TYPE_FCL = 'fcl';
export const LOAD_TYPE_LCL = 'lcl';

export const LOAD_TYPES: RadioOptionProps[] = [
  { key: LOAD_TYPE_FCL, name: 'FCL' },
  { key: LOAD_TYPE_LCL, name: 'LCL' },
];

export const RTO_TYPE_LOOSE = 'loose';

export const BOOKING_TYPE_SHIPPING_LINE = 'shipping_line';
export const BOOKING_TYPE_VENDOR = 'vendor';
export const BOOKING_TYPE_SELF = 'self';

export const BOOKING_TYPE_MAP: { [key: string]: string } = {
  [BOOKING_TYPE_SHIPPING_LINE]: 'Shipping Line',
  [BOOKING_TYPE_VENDOR]: 'Vendor',
  [BOOKING_TYPE_SELF]: 'CRO',
};

export const TEMPERATURE_UNIT_CELSIUS = '°C';
export const TEMPERATURE_UNIT_FAHRENHEIT = '°F';

export const AIRFLOW_UNIT_CBM_PER_HR = 'cbm_per_hr';
export const AIRFLOW_UNIT_CFT_PER_HR = 'cft_per_hr';

export const AIRFLOW_UNITS = [
  { key: AIRFLOW_UNIT_CBM_PER_HR, name: 'CBM/hour' },
  { key: AIRFLOW_UNIT_CFT_PER_HR, name: 'CFT/hour' },
];

export const STUFFING_LOCATION_TYPE_FACTORY = 'factory';
export const STUFFING_LOCATION_TYPE_CFS = 'cfs';
export const STUFFING_LOCATION_TYPE_ICD = 'icd';

export function stuffing_location_types(load_type: string) {
  return [
    {
      value: STUFFING_LOCATION_TYPE_FACTORY,
      label: 'Factory',
      disabled: load_type === LOAD_TYPE_LCL,
    },
    { value: STUFFING_LOCATION_TYPE_CFS, label: 'CFS', disabled: false },
    { value: STUFFING_LOCATION_TYPE_ICD, label: 'ICD', disabled: false },
  ];
}

export const DIMENSION_CMS = 'cms';

export const WEIGHT_UNIT_KGS = 'kgs';
export const WEIGHT_UNIT_MTS = 'mts';

export const VOLUME_UNIT_CBM = 'cbm';

export const SHIPMNTS_WEB_APP = 'shipmntsWeb';
export const FINANCE_APP = 'erpnext';

export const CONTAINER_TYPES: { [key: string]: string } = {
  '22G0': '20 Standard Dry',
  '22F0': '20 Flexi Dry',
  '22ODO': '20 One Door Open',
  '22P1': '20 Flat Rack',
  '22U6': '20 Hard Top',
  '22H0': '20 Insulated',
  '22U0': '20 Open Top',
  '29P0': '20 Platform',
  '22P3': '20 Flat (collapsible)',
  '22R1': '20 Reefer',
  '22T3': '20 Tank',
  '42G0': '40 Standard Dry',
  '42F0': '40 Flexi Dry',
  '42ODO': '40 One Door Open',
  '42P1': '40 Flat Rack',
  '42T3': '40 Tank',
  '42P3': '40 Flat (collapsible)',
  '42U6': '40 Hard Top',
  '4EG0': '40 HC Pallet Wide',
  '45G0': '40 High Cube',
  '45U6': '40 High Cube Hard Top',
  '45U1': '40 High Cube Open Top',
  '42H0': '40 Insulated',
  '42U0': '40 Open Top',
  '49P0': '40 Platform',
  '45P3': '40 Platform High Cube',
  '45R1': '40 Reefer High Cube',
  L5G0: '45 High Cube',
};

export const containerTypesFlatArray = Object.keys(CONTAINER_TYPES).map((key) => ({
  label: CONTAINER_TYPES[key],
  value: key,
}));

export const containerSettingsMap: { [key: string]: { [key: string]: string } } = {
  standard_dry: {
    '22G0': '20 Standard Dry',
    '22F0': '20 Flexi Dry',
    '42G0': '40 Standard Dry',
    '42F0': '40 Flexi Dry',
    '22ODO': '20 One Door Open',
    '42ODO': '40 One Door Open',
    '45G0': '40 High Cube',
    '45U6': '40 High Cube Hard Top',
    '42U6': '40 Hard Top',
    L5G0: '45 High Cube',
  },
  reefer: {
    '22R1': '20 Reefer',
    '45R1': '40 Reefer High Cube',
  },
  out_of_dimension: {
    '22P1': '20 Flat Rack',
    '22U6': '20 Hard Top',
    '22U0': '20 Open Top',
    '29P0': '20 Platform',
    '22P3': '20 Flat (collapsible)',
    '42P1': '40 Flat Rack',
    '42P3': '40 Flat (collapsible)',
    '4EG0': '40 HC Pallet Wide',
    '42U0': '40 Open Top',
    '49P0': '40 Platform',
    '45P3': '40 Platform High Cube',
    '45U1': '40 High Cube Open Top',
  },
  tank: {
    '22T3': '20 Tank',
    '42T3': '40 Tank',
  },
  insulated: {
    '22H0': '20 Insulated',
    '42H0': '40 Insulated',
  },
};

export const containerTypesHash: { [key: string]: string } = Object.values(
  containerSettingsMap
).reduce((containerHash, containerSetting) => {
  containerHash = { ...containerHash, ...containerSetting };
  return containerHash;
}, {});

export const containerTypesArray: {
  [key: string]: Array<{ key: string; name: string }>;
} = Object.keys(containerSettingsMap).reduce(
  (containerObj: { [key: string]: Array<{ key: string; name: string }> }, setting_key: string) => {
    const containerTypes = containerSettingsMap[setting_key];
    containerObj[_startCase(setting_key)] = Object.keys(containerTypes).reduce(
      (array: Array<{ key: string; name: string }>, container_code: string) => {
        array.push({ key: container_code, name: containerTypes[container_code] });
        return array;
      },
      []
    );
    return containerObj;
  },
  {}
);

export const oceanPackageTypes = [
  { name: 'Aerosol', key: 'Aerosol' },
  { name: 'Ammo Pack', key: 'Ammo Pack' },
  { name: 'Ampoule,non-protected', key: 'Ampoule,non-protected' },
  { name: 'Ampoule,protected', key: 'Ampoule,protected' },
  { name: 'Atomizer', key: 'Atomizer' },
  { name: 'Bag', key: 'Bag' },
  { name: 'PP Bag', key: 'PP Bag' },
  { name: 'Bag, large', key: 'Bag, large' },
  { name: 'Bag, paper', key: 'Bag, paper' },
  { name: 'Bag, paper, multi-wall', key: 'Bag, paper, multi-wall' },
  { name: 'Bag,paper,multiwall,H2O resis', key: 'Bag,paper,multiwall,H2O resis' },
  { name: 'Bag, plastic', key: 'Bag, plastic' },
  { name: 'Bag, plastics film', key: 'Bag, plastics film' },
  { name: 'Bag, textile', key: 'Bag, textile' },
  { name: 'Bag, textile, sift proof', key: 'Bag, textile, sift proof' },
  { name: 'Bag, textile, water resistant', key: 'Bag, textile, water resistant' },
  { name: 'Bag,text,no inner coat/liner', key: 'Bag,text,no inner coat/liner' },
  { name: 'Bag, woven plastic', key: 'Bag, woven plastic' },
  { name: 'Bag, woven plastic, sift proof', key: 'Bag, woven plastic, sift proof' },
  { name: 'Bag, woven plastic, water resistant', key: 'Bag, woven plastic, water resistant' },
  { name: 'Bag,woven plstc,no inner coat/lin', key: 'Bag,woven plstc,no inner coat/lin' },
  { name: 'Bale', key: 'Bale' },
  { name: 'Bale,compressed', key: 'Bale,compressed' },
  { name: 'Bale,non-compressed', key: 'Bale,non-compressed' },
  { name: 'Balloon,non-protected', key: 'Balloon,non-protected' },
  { name: 'Balloon,protected', key: 'Balloon,protected' },
  { name: 'Bar', key: 'Bar' },
  { name: 'Barge', key: 'Barge' },
  { name: 'Barrel', key: 'Barrel' },
  { name: 'Barrel, wooden', key: 'Barrel, wooden' },
  { name: 'Barrel, wooden, bung type', key: 'Barrel, wooden, bung type' },
  { name: 'Barrel, wooden, removable head', key: 'Barrel, wooden, removable head' },
  { name: 'Bars,inbundle/bunch/truss', key: 'Bars,inbundle/bunch/truss' },
  { name: 'Basin', key: 'Basin' },
  { name: 'Basket or hamper', key: 'Basket or hamper' },
  { name: 'Basket, with handle, cardboard', key: 'Basket, with handle, cardboard' },
  { name: 'Basket, with handle, plastic', key: 'Basket, with handle, plastic' },
  { name: 'Basket, with handle, wooden', key: 'Basket, with handle, wooden' },
  { name: 'Beam', key: 'Beam' },
  { name: 'Bin', key: 'Bin' },
  { name: 'Bing Chest', key: 'Bing Chest' },
  { name: 'Blocks', key: 'Blocks' },
  { name: 'Board', key: 'Board' },
  { name: 'Board,inbundle/bunch/truss', key: 'Board,inbundle/bunch/truss' },
  { name: 'Bobbin', key: 'Bobbin' },
  { name: 'Bolt', key: 'Bolt' },
  { name: 'Bottle', key: 'Bottle' },
  { name: 'Bottle, Gas', key: 'Bottle, Gas' },
  { name: 'Bottle,non-protected,bulbous', key: 'Bottle,non-protected,bulbous' },
  { name: 'Bottle,non-protected,cylindrical', key: 'Bottle,non-protected,cylindrical' },
  { name: 'Bottle,protectedbulbous', key: 'Bottle,protectedbulbous' },
  { name: 'Bottle,protectedcylindrical', key: 'Bottle,protectedcylindrical' },
  { name: 'Bottlecrate,bottlerack', key: 'Bottlecrate,bottlerack' },
  { name: 'Box', key: 'Box' },
  { name: 'Box, aluminium', key: 'Box, aluminium' },
  { name: 'Box,CHEP,Eurobox', key: 'Box,CHEP,Eurobox' },
  { name: 'Box, fibreboard', key: 'Box, fibreboard' },
  { name: 'Box, for liquids', key: 'Box, for liquids' },
  { name: 'Box, natural wood', key: 'Box, natural wood' },
  { name: 'Box, plastic', key: 'Box, plastic' },
  { name: 'Box, plastic, expanded', key: 'Box, plastic, expanded' },
  { name: 'Box, plastic, solid', key: 'Box, plastic, solid' },
  { name: 'Box, plywood', key: 'Box, plywood' },
  { name: 'Box, reconstituted wood', key: 'Box, reconstituted wood' },
  { name: 'Box, steel', key: 'Box, steel' },
  { name: 'Box, with inner container', key: 'Box, with inner container' },
  { name: 'Box, wooden, natural wood, ordinary', key: 'Box, wooden, natural wood, ordinary' },
  { name: 'Box,woodn,nat. wood,siftproof wall', key: 'Box,woodn,nat. wood,siftproof wall' },
  { name: 'Bucket', key: 'Bucket' },
  { name: 'Bulk', key: 'Bulk' },
  { name: 'Bulk Bag', key: 'Bulk Bag' },
  { name: 'Bulk,gas(at1031mbarand15 C)', key: 'Bulk,gas(at1031mbarand15 C)' },
  { name: 'Bulk,liq gas', key: 'Bulk,liq gas' },
  { name: 'Bulk,sol,fine partcls-powder', key: 'Bulk,sol,fine partcls-powder' },
  { name: 'Bulk,solid,granular particles-grain', key: 'Bulk,solid,granular particles-grain' },
  { name: 'Bulk,solid,granular partcls-nodules', key: 'Bulk,solid,granular partcls-nodules' },
  { name: 'Bunch', key: 'Bunch' },
  { name: 'Bundle', key: 'Bundle' },
  { name: 'Butt', key: 'Butt' },
  { name: 'Cabinet', key: 'Cabinet' },
  { name: 'Cage', key: 'Cage' },
  { name: 'Cage,CHEP', key: 'Cage,CHEP' },
  { name: 'Cage, roll', key: 'Cage, roll' },
  { name: 'Can', key: 'Can' },
  { name: 'Can Case', key: 'Can Case' },
  { name: 'Can, with handle and spout', key: 'Can, with handle and spout' },
  { name: 'Can,cylindrical', key: 'Can,cylindrical' },
  { name: 'Can,rectangular', key: 'Can,rectangular' },
  { name: 'Canister', key: 'Canister' },
  { name: 'Canvas', key: 'Canvas' },
  { name: 'Car Load, Rail', key: 'Car Load, Rail' },
  { name: 'Carboy', key: 'Carboy' },
  { name: 'Carboy,non-protected', key: 'Carboy,non-protected' },
  { name: 'Carboy,protected', key: 'Carboy,protected' },
  { name: 'Carrier', key: 'Carrier' },
  { name: 'Carton', key: 'Carton' },
  { name: 'Case', key: 'Case' },
  { name: 'Case, isothermic', key: 'Case, isothermic' },
  { name: 'Case, skeleton', key: 'Case, skeleton' },
  { name: 'Case, with pallet base', key: 'Case, with pallet base' },
  { name: 'Case, with pallet base, cardboard', key: 'Case, with pallet base, cardboard' },
  { name: 'Case, with pallet base, metal', key: 'Case, with pallet base, metal' },
  { name: 'Case, with pallet base, plastic', key: 'Case, with pallet base, plastic' },
  { name: 'Case, with pallet base, wooden', key: 'Case, with pallet base, wooden' },
  { name: 'Cask', key: 'Cask' },
  { name: 'Cheeses', key: 'Cheeses' },
  { name: 'Chest', key: 'Chest' },
  { name: 'Churn', key: 'Churn' },
  { name: 'Coffer', key: 'Coffer' },
  { name: 'Coffin', key: 'Coffin' },
  { name: 'Coil', key: 'Coil' },
  { name: 'Cmp Pack,glass rcpt', key: 'Cmp Pack,glass rcpt' },
  { name: 'Cmp Pack,glass rcpt in alum drum', key: 'Cmp Pack,glass rcpt in alum drum' },
  { name: 'Cmp Pack,glass rcpt,exnd plstc pck', key: 'Cmp Pack,glass rcpt,exnd plstc pck' },
  { name: 'Cmp Pack,glass rcpt in fibre drum', key: 'Cmp Pack,glass rcpt in fibre drum' },
  { name: 'Cmp Pack,glass rcpt in fibreb box', key: 'Cmp Pack,glass rcpt in fibreb box' },
  { name: 'Cmp Pack,glass rcpt in plywd drum', key: 'Cmp Pack,glass rcpt in plywd drum' },
  { name: 'Cmp Pack,glass rcpt, sld plstc pck', key: 'Cmp Pack,glass rcpt, sld plstc pck' },
  { name: 'Cmp Pack,glass rcpt,steel crt box', key: 'Cmp Pack,glass rcpt,steel crt box' },
  { name: 'Cmp Pack,glass rcpt in steel drum', key: 'Cmp Pack,glass rcpt in steel drum' },
  { name: 'Cmp Pack,glass rcpt,wickwork hamp', key: 'Cmp Pack,glass rcpt,wickwork hamp' },
  { name: 'Cmp Pack,glass rcpt in wooden box', key: 'Cmp Pack,glass rcpt in wooden box' },
  { name: 'Cmp Pack,plastic rcpt', key: 'Cmp Pack,plastic rcpt' },
  { name: 'Cmp Pack,plastic rcpt, alum crate', key: 'Cmp Pack,plastic rcpt, alum crate' },
  { name: 'Cmp Pack,plastic rcpt in alum drum', key: 'Cmp Pack,plastic rcpt in alum drum' },
  { name: 'Cmp Pack,plastic rcpt,fibre drum', key: 'Cmp Pack,plastic rcpt,fibre drum' },
  { name: 'Cmp Pack,plastic rcpt in fireb box', key: 'Cmp Pack,plastic rcpt in fireb box' },
  { name: 'Cmp Pack,plastic rcpt,plstc drum', key: 'Cmp Pack,plastic rcpt,plstc drum' },
  { name: 'Cmp Pack,plastic rcpt in plywd box', key: 'Cmp Pack,plastic rcpt in plywd box' },
  { name: 'Cmp Pack,plastic rcpt,plywd drum', key: 'Cmp Pack,plastic rcpt,plywd drum' },
  { name: 'Cmp Pack,plstc rcpt,solid plstc box', key: 'Cmp Pack,plstc rcpt,solid plstc box' },
  { name: 'Cmp Pack,plstc rcpt,steel crate box', key: 'Cmp Pack,plstc rcpt,steel crate box' },
  { name: 'Cmp Pack,plstc rcpt,steel drum', key: 'Cmp Pack,plstc rcpt,steel drum' },
  { name: 'Cmp Pack,plstc rcpt,in wooden box', key: 'Cmp Pack,plstc rcpt,in wooden box' },
  { name: 'Cones', key: 'Cones' },
  { name: 'CONEX - Container Express', key: 'CONEX - Container Express' },
  { name: 'ctr,not specif transport equipment', key: 'ctr,not specif transport equipment' },
  { name: 'Container, Commercial Highway Lift', key: 'Container, Commercial Highway Lift' },
  {
    name: 'Container, MAC-ISO Light Weight 8x8x20 Foot Air',
    key: 'Container, MAC-ISO Light Weight 8x8x20 Foot Air',
  },
  { name: 'Container, Navy Cargo Transporter', key: 'Container, Navy Cargo Transporter' },
  { name: 'Containers of Bulk Cargo', key: 'Containers of Bulk Cargo' },
  { name: 'Core', key: 'Core' },
  { name: 'Cover', key: 'Cover' },
  { name: 'Cradle', key: 'Cradle' },
  { name: 'Crate', key: 'Crate' },
  { name: 'Crate, beer', key: 'Crate, beer' },
  { name: 'Crate, bulk, cardboard', key: 'Crate, bulk, cardboard' },
  { name: 'Crate, bulk, plastic', key: 'Crate, bulk, plastic' },
  { name: 'Crate, bulk, wooden', key: 'Crate, bulk, wooden' },
  { name: 'Crate, framed', key: 'Crate, framed' },
  { name: 'Crate, fruit', key: 'Crate, fruit' },
  { name: 'Crate, milk', key: 'Crate, milk' },
  { name: 'Crate, multiple layer, cardboard', key: 'Crate, multiple layer, cardboard' },
  { name: 'Crate, multiple layer, plastic', key: 'Crate, multiple layer, plastic' },
  { name: 'Crate, multiple layer, wooden', key: 'Crate, multiple layer, wooden' },
  { name: 'Crate, shallow', key: 'Crate, shallow' },
  { name: 'Creel', key: 'Creel' },
  { name: 'Cube', key: 'Cube' },
  { name: 'Cup', key: 'Cup' },
  { name: 'Cylinder', key: 'Cylinder' },
  { name: 'Demijohn,non-protected', key: 'Demijohn,non-protected' },
  { name: 'Demijohn,protected', key: 'Demijohn,protected' },
  { name: 'Dispenser', key: 'Dispenser' },
  { name: 'Double-length Rack', key: 'Double-length Rack' },
  { name: 'Double-length Skid', key: 'Double-length Skid' },
  { name: 'Double-length Tote Bin', key: 'Double-length Tote Bin' },
  { name: 'Drum', key: 'Drum' },
  { name: 'Drum, aluminium', key: 'Drum, aluminium' },
  { name: 'Drum, aluminium, non-removable head', key: 'Drum, aluminium, non-removable head' },
  { name: 'Drum, aluminium, removable head', key: 'Drum, aluminium, removable head' },
  { name: 'Drum, fibre', key: 'Drum, fibre' },
  { name: 'Drum, iron', key: 'Drum, iron' },
  { name: 'Drum, plastic', key: 'Drum, plastic' },
  { name: 'Drum, plastic, non-removable head', key: 'Drum, plastic, non-removable head' },
  { name: 'Drum, plastic, removable head', key: 'Drum, plastic, removable head' },
  { name: 'Drum, plywood', key: 'Drum, plywood' },
  { name: 'Drum, steel', key: 'Drum, steel' },
  { name: 'Drum, steel, non-removable head', key: 'Drum, steel, non-removable head' },
  { name: 'Jerrican,plstc,no remov head (DG)', key: 'Jerrican,plstc,no remov head (DG)' },
  { name: 'Drum, steel, removable head', key: 'Drum, steel, removable head' },
  { name: 'Dry Bulk', key: 'Dry Bulk' },
  { name: 'Duffle Bag', key: 'Duffle Bag' },
  { name: 'Engine Container', key: 'Engine Container' },
  { name: 'Envelope', key: 'Envelope' },
  { name: 'Filmpack', key: 'Filmpack' },
  { name: 'Firkin', key: 'Firkin' },
  { name: 'Flask', key: 'Flask' },
  { name: 'Flo-bin', key: 'Flo-bin' },
  { name: 'Foodtainer', key: 'Foodtainer' },
  { name: 'Footlocker', key: 'Footlocker' },
  { name: 'Forward Reel', key: 'Forward Reel' },
  { name: 'Frame', key: 'Frame' },
  { name: 'Garments on Hangers', key: 'Garments on Hangers' },
  { name: 'Girder', key: 'Girder' },
  { name: 'Girders,inbundle/bunch/truss', key: 'Girders,inbundle/bunch/truss' },
  { name: 'Half-standard Rack', key: 'Half-standard Rack' },
  { name: 'Half-Standard Tote Bin', key: 'Half-Standard Tote Bin' },
  { name: 'Hamper', key: 'Hamper' },
  { name: 'Heads of Beef', key: 'Heads of Beef' },
  { name: 'Hogshead', key: 'Hogshead' },
  { name: 'Hopper Truck', key: 'Hopper Truck' },
  { name: 'Household Goods Containers, Wood', key: 'Household Goods Containers, Wood' },
  { name: 'Ingot', key: 'Ingot' },
  { name: 'Ingots,inbundle/bunch/truss', key: 'Ingots,inbundle/bunch/truss' },
  { name: 'Intermediate bulk container', key: 'Intermediate bulk container' },
  { name: 'Intermediate bulk ctr,alum', key: 'Intermediate bulk ctr,alum' },
  { name: 'Intermediate bulk ctr,alum,liquid', key: 'Intermediate bulk ctr,alum,liquid' },
  { name: 'Int bulk ctr,alum,liqu,press>10', key: 'Int bulk ctr,alum,liqu,press>10' },
  { name: 'Int bulk ctr,compos,flex plstc,liq', key: 'Int bulk ctr,compos,flex plstc,liq' },
  { name: 'Int bulk ctr,compos,flex plstc,pres', key: 'Int bulk ctr,compos,flex plstc,pres' },
  { name: 'Int bulk ctr,compos,flex plstc,sol', key: 'Int bulk ctr,compos,flex plstc,sol' },
  { name: 'Int bulk ctr,compos,rigid plstc,sol', key: 'Int bulk ctr,compos,rigid plstc,sol' },
  { name: 'Int bulk ctr,compos,rigd plstc,pres', key: 'Int bulk ctr,compos,rigd plstc,pres' },
  { name: 'Int bulk ctr,compos,rigd plstc,sol', key: 'Int bulk ctr,compos,rigd plstc,sol' },
  { name: 'Intermediate bulk container, metal', key: 'Intermediate bulk container, metal' },
  { name: 'Int bulk ctr,metal,liquid', key: 'Int bulk ctr,metal,liquid' },
  { name: 'Int bulk ctr,metal,press 10kpa', key: 'Int bulk ctr,metal,press 10kpa' },
  { name: 'Int bulk ctr,nat wood,inner liner', key: 'Int bulk ctr,nat wood,inner liner' },
  { name: 'Int bulk ctr,paper,multiwall', key: 'Int bulk ctr,paper,multiwall' },
  { name: 'Int bulk ctr,papr,multiwall,H2O res', key: 'Int bulk ctr,papr,multiwall,H2O res' },
  { name: 'Int bulk ctr,plstc film', key: 'Int bulk ctr,plstc film' },
  { name: 'Int bulk ctr,plywood,inner liner', key: 'Int bulk ctr,plywood,inner liner' },
  { name: 'Int bulk ctr,recnst wood,inn liner', key: 'Int bulk ctr,recnst wood,inn liner' },
  { name: 'Int Bulk ctr,rigid plstc,stand,liq', key: 'Int Bulk ctr,rigid plstc,stand,liq' },
  { name: 'Int Bulk ctr,rigid plstc,stand,pres', key: 'Int Bulk ctr,rigid plstc,stand,pres' },
  { name: 'Int bulk ctr,rigid plstc,stand,sol', key: 'Int bulk ctr,rigid plstc,stand,sol' },
  { name: 'Int Bulk ctr,rig plstc,stru eq,liq', key: 'Int Bulk ctr,rig plstc,stru eq,liq' },
  { name: 'Int Bulk ctr,rig plstc,stru eq,pres', key: 'Int Bulk ctr,rig plstc,stru eq,pres' },
  { name: 'Int Bulk ctr,rig plstc,stru eq,sol', key: 'Int Bulk ctr,rig plstc,stru eq,sol' },
  { name: 'Intermediate bulk container, steel', key: 'Intermediate bulk container, steel' },
  { name: 'Int Bulk ctr,steel,liquid', key: 'Int Bulk ctr,steel,liquid' },
  { name: 'Int Bulk ctr,steel,press>10kpa', key: 'Int Bulk ctr,steel,press>10kpa' },
  { name: 'Int Bulk ctr,text,no out coat/liner', key: 'Int Bulk ctr,text,no out coat/liner' },
  { name: 'Int Bulk ctr,text,coated', key: 'Int Bulk ctr,text,coated' },
  { name: 'Int Bulk ctr,text,coated and liner', key: 'Int Bulk ctr,text,coated and liner' },
  { name: 'Int Bulk ctr,text,liner', key: 'Int Bulk ctr,text,liner' },
  { name: 'Int Bulk ctr,woven plstc,coated', key: 'Int Bulk ctr,woven plstc,coated' },
  { name: 'Int Bulk ctr,woven plstc,coated/lin', key: 'Int Bulk ctr,woven plstc,coated/lin' },
  { name: 'Int Bulk ctr,woven plstc w/ liner', key: 'Int Bulk ctr,woven plstc w/ liner' },
  { name: 'IntBulk ctr,wov plstc,w/o coat/lin', key: 'IntBulk ctr,wov plstc,w/o coat/lin' },
  {
    name: 'Intermodal Trailer/Container Load (Rail)',
    key: 'Intermodal Trailer/Container Load (Rail)',
  },
  { name: 'Jar', key: 'Jar' },
  { name: 'Jerrican, plastic', key: 'Jerrican, plastic' },
  { name: 'Jerrican,plstc,no remov head', key: 'Jerrican,plstc,no remov head' },
  { name: 'Jerrican, plastic, removable head', key: 'Jerrican, plastic, removable head' },
  { name: 'Jerrican, steel', key: 'Jerrican, steel' },
  { name: 'Jerrican, steel, non-removable head', key: 'Jerrican, steel, non-removable head' },
  { name: 'Jerrican, steel, removable head', key: 'Jerrican, steel, removable head' },
  { name: 'Jerrican,cylindrical', key: 'Jerrican,cylindrical' },
  { name: 'Jerrican,rectangular', key: 'Jerrican,rectangular' },
  { name: 'Jug', key: 'Jug' },
  { name: 'Jutebag', key: 'Jutebag' },
  { name: 'Keg', key: 'Keg' },
  { name: 'Kit', key: 'Kit' },
  { name: 'Knockdown Rack', key: 'Knockdown Rack' },
  { name: 'Knockdown Tote Bin', key: 'Knockdown Tote Bin' },
  { name: 'Lift Van', key: 'Lift Van' },
  { name: 'Lifts', key: 'Lifts' },
  { name: 'Liner Bag Dry', key: 'Liner Bag Dry' },
  { name: 'Liner Bag Liquid', key: 'Liner Bag Liquid' },
  { name: 'Liquid Bulk', key: 'Liquid Bulk' },
  { name: 'Log', key: 'Log' },
  { name: 'Logs,inbundle/bunch/truss', key: 'Logs,inbundle/bunch/truss' },
  { name: 'Loose', key: 'Loose' },
  { name: 'Lot', key: 'Lot' },
  { name: 'Lug', key: 'Lug' },
  { name: 'Mat', key: 'Mat' },
  { name: 'Matchbox', key: 'Matchbox' },
  { name: 'MILVAN - Military Van', key: 'MILVAN - Military Van' },
  { name: 'Mixed Type Pack', key: 'Mixed Type Pack' },
  { name: 'MSCVAN - Military Sealift Command Van', key: 'MSCVAN - Military Sealift Command Van' },
  { name: 'Multiplybag', key: 'Multiplybag' },
  { name: 'Multi-Roll Pack', key: 'Multi-Roll Pack' },
  {
    name: 'Multiwall Container Secured to Warehouse Pallet',
    key: 'Multiwall Container Secured to Warehouse Pallet',
  },
  { name: 'Mutually defined', key: 'Mutually defined' },
  { name: 'Nest', key: 'Nest' },
  { name: 'Net', key: 'Net' },
  { name: 'Net, tube, plastic', key: 'Net, tube, plastic' },
  { name: 'Net, tube, textile', key: 'Net, tube, textile' },
  { name: 'Noil', key: 'Noil' },
  { name: 'Not available', key: 'Not available' },
  { name: 'On Hanger or Rack in Boxes', key: 'On Hanger or Rack in Boxes' },
  { name: 'On Own Wheel', key: 'On Own Wheel' },
  { name: 'Overwrap', key: 'Overwrap' },
  { name: 'Package', key: 'Package' },
  { name: 'Package, display, cardboard', key: 'Package, display, cardboard' },
  { name: 'Package, display, metal', key: 'Package, display, metal' },
  { name: 'Package, display, plastic', key: 'Package, display, plastic' },
  { name: 'Package, display, wooden', key: 'Package, display, wooden' },
  { name: 'Package, flow', key: 'Package, flow' },
  { name: 'Package, paper wrapped', key: 'Package, paper wrapped' },
  { name: 'Package, show', key: 'Package, show' },
  { name: 'Packed - not otherwise specified', key: 'Packed - not otherwise specified' },
  { name: 'Packet', key: 'Packet' },
  { name: 'Pail', key: 'Pail' },
  { name: 'Pallet', key: 'Pallet' },
  { name: 'Pallet, box', key: 'Pallet, box' },
  { name: 'Pallet,modul,collar 80cm*100cm', key: 'Pallet,modul,collar 80cm*100cm' },
  { name: 'Pallet,modul,collar 80cm*120cm', key: 'Pallet,modul,collar 80cm*120cm' },
  { name: 'Parcel', key: 'Parcel' },
  { name: 'Pieces', key: 'Pieces' },
  { name: 'Pims', key: 'Pims' },
  { name: 'Pipe', key: 'Pipe' },
  { name: 'Pipe Rack', key: 'Pipe Rack' },
  { name: 'Pipeline', key: 'Pipeline' },
  { name: 'Pipes, in bundle/bunch/truss', key: 'Pipes, in bundle/bunch/truss' },
  { name: 'Pitcher', key: 'Pitcher' },
  { name: 'Plank', key: 'Plank' },
  { name: 'Planks,inbundle/bunch/truss', key: 'Planks,inbundle/bunch/truss' },
  { name: 'Plate', key: 'Plate' },
  { name: 'Plates,inbundle/bunch/truss', key: 'Plates,inbundle/bunch/truss' },
  { name: 'Platform', key: 'Platform' },
  { name: 'Pot', key: 'Pot' },
  { name: 'Pouch', key: 'Pouch' },
  { name: 'Private Vehicle', key: 'Private Vehicle' },
  { name: 'Quarter of Beef', key: 'Quarter of Beef' },
  { name: 'Rack', key: 'Rack' },
  { name: 'Rack, clothing hanger', key: 'Rack, clothing hanger' },
  { name: 'Rail (Semiconductor)', key: 'Rail (Semiconductor)' },
  { name: 'Receptacle, glass', key: 'Receptacle, glass' },
  { name: 'Receptacle, metal', key: 'Receptacle, metal' },
  { name: 'Receptacle, plastic', key: 'Receptacle, plastic' },
  { name: 'Receptacle, plastic wrapped', key: 'Receptacle, plastic wrapped' },
  { name: 'Rednet', key: 'Rednet' },
  { name: 'Reel', key: 'Reel' },
  { name: 'Reverse Reel', key: 'Reverse Reel' },
  { name: 'Ring', key: 'Ring' },
  { name: 'Rod', key: 'Rod' },
  { name: 'Rods,inbundle/bunch/truss', key: 'Rods,inbundle/bunch/truss' },
  { name: 'Roll', key: 'Roll' },
  { name: 'Sachet', key: 'Sachet' },
  { name: 'Sack', key: 'Sack' },
  { name: 'Sack, multi-wall', key: 'Sack, multi-wall' },
  { name: 'Sea-chest', key: 'Sea-chest' },
  { name: 'SEAVAN - Sea Van', key: 'SEAVAN - Sea Van' },
  { name: 'Set', key: 'Set' },
  { name: 'Sheet', key: 'Sheet' },
  { name: 'Sheet, plastic wrapping', key: 'Sheet, plastic wrapping' },
  { name: 'Sheetmetal', key: 'Sheetmetal' },
  { name: 'Sheets,inbundle/bunch/truss', key: 'Sheets,inbundle/bunch/truss' },
  { name: 'Shook', key: 'Shook' },
  { name: 'Shrinkwrapped', key: 'Shrinkwrapped' },
  { name: 'Side of Beef', key: 'Side of Beef' },
  { name: 'Skid', key: 'Skid' },
  { name: 'Skid, elevating or lift truck', key: 'Skid, elevating or lift truck' },
  { name: 'Sleeve', key: 'Sleeve' },
  { name: 'Slip Sheet', key: 'Slip Sheet' },
  { name: 'Spin Cylinders', key: 'Spin Cylinders' },
  { name: 'Spindle', key: 'Spindle' },
  { name: 'Spool', key: 'Spool' },
  { name: 'Suitcase', key: 'Suitcase' },
  { name: 'Tank', key: 'Tank' },
  { name: 'Tank Car', key: 'Tank Car' },
  { name: 'Tank Truck', key: 'Tank Truck' },
  { name: 'Tank,cylindrical', key: 'Tank,cylindrical' },
  { name: 'Tank,rectangular', key: 'Tank,rectangular' },
  { name: 'Tea-chest', key: 'Tea-chest' },
  { name: 'Tierce', key: 'Tierce' },
  { name: 'Tin', key: 'Tin' },
  { name: 'Tote Bin', key: 'Tote Bin' },
  { name: 'Tote Can', key: 'Tote Can' },
  { name: 'Tray', key: 'Tray' },
  { name: 'Tray, one layer no cover, cardboard', key: 'Tray, one layer no cover, cardboard' },
  { name: 'Tray, one layer no cover, plastic', key: 'Tray, one layer no cover, plastic' },
  { name: 'Tray,1 layer no cover,polystyrene', key: 'Tray,1 layer no cover,polystyrene' },
  { name: 'Tray, one layer no cover, wooden', key: 'Tray, one layer no cover, wooden' },
  { name: 'Tray,2 layer no cover,cardbrd', key: 'Tray,2 layer no cover,cardbrd' },
  { name: 'Tray,2 layer no cover,plstc tray', key: 'Tray,2 layer no cover,plstc tray' },
  { name: 'Tray, two layers no cover, wooden', key: 'Tray, two layers no cover, wooden' },
  { name: 'Triwall Box', key: 'Triwall Box' },
  { name: 'Truck', key: 'Truck' },
  { name: 'Trunk', key: 'Trunk' },
  { name: 'Trunk and Chest', key: 'Trunk and Chest' },
  { name: 'Trunk, Salesmen Sample', key: 'Trunk, Salesmen Sample' },
  { name: 'Truss', key: 'Truss' },
  { name: 'Tub', key: 'Tub' },
  { name: 'Tube', key: 'Tube' },
  { name: 'Tube, with nozzle', key: 'Tube, with nozzle' },
  { name: 'Tube,collapsible', key: 'Tube,collapsible' },
  { name: 'Tubes,inbundle/bunch/truss', key: 'Tubes,inbundle/bunch/truss' },
  { name: 'Tun', key: 'Tun' },
  { name: 'Unpacked', key: 'Unpacked' },
  { name: 'Vacuum-packed', key: 'Vacuum-packed' },
  { name: 'Van Pack', key: 'Van Pack' },
  { name: 'Vat', key: 'Vat' },
  { name: 'Vehicles', key: 'Vehicles' },
  { name: 'Vial', key: 'Vial' },
  { name: 'Wheeled Carrier', key: 'Wheeled Carrier' },
  { name: 'Wickerbottle', key: 'Wickerbottle' },
  { name: 'Wrapped', key: 'Wrapped' },
  { name: 'Drum, wooden', key: 'Drum, wooden' },
  { name: 'Bag, super bulk', key: 'Bag, super bulk' },
  { name: 'Int Bulk ctr,rigid plstc', key: 'Int Bulk ctr,rigid plstc' },
  { name: 'Receptacle, fibre', key: 'Receptacle, fibre' },
  { name: 'Receptacle, paper', key: 'Receptacle, paper' },
  { name: 'Receptacle, wooden', key: 'Receptacle, wooden' },
  { name: 'Pallet,modul,collar 80cm*60cm', key: 'Pallet,modul,collar 80cm*60cm' },
  { name: 'Pallet, shrinkwrapped', key: 'Pallet, shrinkwrapped' },
  { name: 'Pallet, 100cms * 110cms', key: 'Pallet, 100cms * 110cms' },
  { name: 'Clamshell', key: 'Clamshell' },
  { name: 'Cone', key: 'Cone' },
  { name: 'Capsule', key: 'Capsule' },
  { name: 'Card', key: 'Card' },
  { name: 'Cartridge', key: 'Cartridge' },
  { name: 'Bag, flexible container', key: 'Bag, flexible container' },
  { name: 'Pack,cardboard,w/bottle grip holes', key: 'Pack,cardboard,w/bottle grip holes' },
  { name: 'Unpacked unpackaged, single unit', key: 'Unpacked unpackaged, single unit' },
  { name: 'Unpackd multple units', key: 'Unpackd multple units' },
  { name: 'Pen', key: 'Pen' },
  { name: 'Punnet', key: 'Punnet' },
  { name: 'Slab', key: 'Slab' },
  { name: 'Case, steel', key: 'Case, steel' },
  { name: 'Envelope, steel', key: 'Envelope, steel' },
  { name: 'Tub, with lid', key: 'Tub, with lid' },
  { name: 'Uncaged', key: 'Uncaged' },
  { name: 'Vanpack', key: 'Vanpack' },
  { name: 'Intermediate bulk ctr,composite', key: 'Intermediate bulk ctr,composite' },
  { name: 'Int bulk ctr,fibreboard', key: 'Int bulk ctr,fibreboard' },
  { name: 'Int bulk ctr,flex', key: 'Int bulk ctr,flex' },
  { name: 'Int bulk ctr,metal,other no steel', key: 'Int bulk ctr,metal,other no steel' },
  { name: 'Int bulk ctr,natural wood', key: 'Int bulk ctr,natural wood' },
  { name: 'Int bulk ctr,plywood', key: 'Int bulk ctr,plywood' },
  { name: 'Int bulk ctr,reconstituted wood', key: 'Int bulk ctr,reconstituted wood' },
  { name: 'Cart, flatbed', key: 'Cart, flatbed' },
  { name: 'Tray,w/ horizont stacked flat itms', key: 'Tray,w/ horizont stacked flat itms' },
  { name: 'Tray,rigd,lid stack-CENTS14482:2002', key: 'Tray,rigd,lid stack-CENTS14482:2002' },
  { name: 'Vehicle', key: 'Vehicle' },
  { name: 'Unit', key: 'Unit' },
  { name: 'Octabin', key: 'Octabin' },
  { name: 'Drum, plastic, non-removable head (DG)', key: 'Drum, plastic, non-removable head (DG)' },
  { name: 'Drum, plastic, removable head (DG)', key: 'Drum, plastic, removable head (DG)' },
  { name: 'Bag,paper,multiwall,H2O resis (DG)', key: 'Bag,paper,multiwall,H2O resis (DG)' },
  { name: 'Bag, gunny', key: 'Bag, gunny' },
  { name: 'Bag, jumbo', key: 'Bag, jumbo' },
  { name: 'Bag, polybag', key: 'Bag, polybag' },
  { name: 'Bag, tote', key: 'Bag, tote' },
  { name: 'Ball', key: 'Ball' },
  { name: 'Belt', key: 'Belt' },
  { name: 'Bulk, scrap metal', key: 'Bulk, scrap metal' },
  { name: 'Bundle, wooden', key: 'Bundle, wooden' },
  { name: 'Case, car', key: 'Case, car' },
  { name: 'Case, wooden', key: 'Case, wooden' },
  { name: 'Container, flexible', key: 'Container, flexible' },
  { name: 'Container, gallon', key: 'Container, gallon' },
  { name: 'Container, metal', key: 'Container, metal' },
  { name: 'Container, outer', key: 'Container, outer' },
  { name: 'Crate, metal', key: 'Crate, metal' },
  { name: 'Crate, wooden', key: 'Crate, wooden' },
  { name: 'Flexibag', key: 'Flexibag' },
  { name: 'Hanger', key: 'Hanger' },
  { name: 'Luggage', key: 'Luggage' },
  { name: 'Pallet, AS 4068-1993', key: 'Pallet, AS 4068-1993' },
  { name: 'Pallet, CHEP 100 cmx120 cm', key: 'Pallet, CHEP 100 cmx120 cm' },
  { name: 'Pallet, CHEP 40cmx60 cm', key: 'Pallet, CHEP 40cmx60 cm' },
  { name: 'Pallet, CHEP 80 cmx120 cm', key: 'Pallet, CHEP 80 cmx120 cm' },
  { name: 'Pallet, ISO T11', key: 'Pallet, ISO T11' },
  { name: 'Pallet, triwall', key: 'Pallet, triwall' },
  { name: 'Pallet, wooden', key: 'Pallet, wooden' },
  { name: 'Pan', key: 'Pan' },
  { name: 'Piece', key: 'Piece' },
  { name: 'Platform,unspecif weigh/dimens', key: 'Platform,unspecif weigh/dimens' },
  { name: 'Tablet', key: 'Tablet' },
  { name: 'Tank container, generic', key: 'Tank container, generic' },
  { name: 'Tyre', key: 'Tyre' },
  { name: 'Drum, steel, removable head (DG)', key: 'Drum, steel, removable head (DG)' },
  { name: 'Container, NOS', key: 'Container, NOS' },
  {
    name: 'Jerrican, steel, non-removable head (DG)',
    key: 'Jerrican, steel, non-removable head (DG)',
  },
  { name: 'Flexitank', key: 'Flexitank' },
  { name: 'Drum, steel, non-removable head (DG)', key: 'Drum, steel, non-removable head (DG)' },
];
export const packingGroup = [
  { key: 'group_1', value: 'Group I' },
  { key: 'group_2', value: 'Group II' },
  { key: 'group_3', value: 'Group III' },
];

export const getUNDGNumberOptions = () => {
  const options: Array<{ key: string; value: string }> = [];
  Object.keys(hazData).forEach((unCode) => {
    options.push({ key: unCode, value: unCode });
  });
  return options;
};

export const COMPANY_STATUS_CONTACT_CREATED = 'contact_created';
export const COMPANY_STATUS_PENDING = 'pending';
export const COMPANY_STATUS_APPROVED = 'approved';
export const COMPANY_STATUS_CREDIT_HOLD = 'credit_hold';
export const COMPANY_STATUS_LEGAL_DISPUTE = 'legal_dispute';
export const COMPANY_STATUS_ARRAY = [
  COMPANY_STATUS_APPROVED,
  COMPANY_STATUS_CREDIT_HOLD,
  COMPANY_STATUS_LEGAL_DISPUTE,
];

export const COMPANY_SEARCH_URL = `${process.env.NETWORK_API_URL}/companies/elastic_search`;

interface FinanceDoctypeMappingType {
  module: string;
  link_type: 'view' | 'form';
}

export const FINANCE_DOCTYPE_MAPPING: { [key: string]: FinanceDoctypeMappingType } = {
  Project: {
    module: 'Shipment::Shipment',
    link_type: 'view',
  },
  Customer: {
    module: 'Network::Company',
    link_type: 'view',
  },
  Supplier: {
    module: 'Network::Company',
    link_type: 'view',
  },
  Address: {
    module: 'Network::Address',
    link_type: 'form',
  },
};
export interface ActionsPossible {
  has_bulk_actions: boolean;
  show_tracker_drawer: boolean;
  has_actions: boolean;
  show_preview: boolean;
  quick_view: boolean;
  display_name?: string;
  template_types?: Array<{ value: string; label: string }>;
  has_custom_field?: boolean;
  has_record_loader?: boolean;
  pretty_display_col?: string;
  table_name?: string;
  is_centralized_di?: boolean;
}
const TERMS_AND_CONDITION = {
  value: 'terms_and_condition',
  label: 'Terms And Condition',
};
const PRINT = {
  value: 'print',
  label: 'Print',
};
const DOCUMENTATION_INSTRUCTIONS = {
  value: 'documentation_instructions',
  label: 'Documentation Instructions',
};
const REMARKS = {
  value: 'remarks',
  label: 'Remarks',
};
const DECLARATION = {
  value: 'declaration',
  label: 'Declaration',
};
const LETTER_HEAD = {
  value: 'letter_head',
  label: 'Letter Head',
};

export const DOC_TYPE_METHODS: { [key: string]: ActionsPossible } = {
  'Shipment::Shipment': {
    has_bulk_actions: true,
    show_tracker_drawer: true,
    has_actions: true,
    show_preview: false,
    display_name: 'Shipment',
    quick_view: true,
    has_custom_field: true,
    table_name: 'shipments',
    is_centralized_di: true,
  },
  'Shipment::ShipmentContainer': {
    show_tracker_drawer: true,
    has_bulk_actions: true,
    has_actions: true,
    show_preview: false,
    quick_view: false,
    display_name: 'Shipment Container',
    has_custom_field: true,
  },
  'Shipment::ShipmentContainerDuplicate': {
    show_tracker_drawer: true,
    has_bulk_actions: true,
    has_actions: true,
    show_preview: false,
    display_name: 'Shipment Container',
    quick_view: false,
  },
  'Shipment::ShipmentCustomDetail': {
    show_tracker_drawer: true,
    has_bulk_actions: true,
    has_actions: true,
    show_preview: false,
    quick_view: false,
    display_name: 'Shipment Custom Detail',
    has_custom_field: true,
  },
  'Shipment::OceanTransportOrder': {
    show_tracker_drawer: false,
    has_bulk_actions: true,
    has_actions: true,
    show_preview: false,
    quick_view: true,
    display_name: 'Ocean Transport Order',
    template_types: [TERMS_AND_CONDITION],
  },
  'Blueprint::DataImport': {
    show_tracker_drawer: false,
    has_bulk_actions: false,
    has_actions: true,
    show_preview: false,
    quick_view: true,
    display_name: 'All Data Import',
  },
  'Shipment::ShipmentDocument': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: true,
    display_name: 'Shipment Document',
    quick_view: false,
  },
  'Shipment::StockOrderItem': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: false,
  },
  'Email::Message': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: false,
  },
  'Network::VoyageSchedule': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: true,
  },
  'NewAccounting::FreightContract': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: false,
  },
  'Network::Company': {
    has_bulk_actions: true,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: true,
    has_record_loader: true,
    has_custom_field: true,
    display_name: 'Company',
    pretty_display_col: 'registered_name',
    table_name: 'companies',
    is_centralized_di: true,
  },
  'SalesHub::Inquiry': {
    has_bulk_actions: true,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: true,
    display_name: 'Inquiry',
    has_custom_field: true,
    template_types: [
      {
        value: PRINT.value,
        label: PRINT.label,
      },
    ],
  },
  'SalesHub::InquiryGlobal': {
    has_bulk_actions: false,
    show_tracker_drawer: true,
    has_actions: true,
    show_preview: false,
    quick_view: true,
  },
  'SalesHub::InquiryOption': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: true,
    display_name: 'Quotation',
    has_custom_field: true,
    template_types: [TERMS_AND_CONDITION],
  },
  'ReportManager::ViewSubscription': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: false,
  },
  'Shipment::Vehicle': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    has_record_loader: true,
    quick_view: false,
    display_name: 'Vehicle',
  },
  'Network::Template': {
    has_bulk_actions: true,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: true,
  },
  'Shipment::Driver': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    has_record_loader: true,
    quick_view: false,
    display_name: 'Driver',
  },
  'OrderManagement::ProductOrder': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: true,
    has_custom_field: true,
    display_name: 'Product Order',
  },
  'OrderManagement::ProductOrderGlobal': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: true,
  },
  'OrderManagement::ProductOrderItem': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: false,
    show_preview: false,
    quick_view: false,
    has_custom_field: true,
    display_name: 'Product Order Item',
  },
  'OrderManagement::SalesOrder': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: true,
    display_name: 'Sales Order',
    template_types: [TERMS_AND_CONDITION, PRINT, DOCUMENTATION_INSTRUCTIONS],
  },
  'OrderManagement::PurchaseOrder': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: true,
    display_name: 'Purchase Order',
    template_types: [TERMS_AND_CONDITION, PRINT, DOCUMENTATION_INSTRUCTIONS],
  },
  'Document::DeliveryOrder': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: true,
    display_name: 'Document Delivery Order',
    template_types: [TERMS_AND_CONDITION],
  },
  'Document::CargoArrivalNotice': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: false,
    show_preview: false,
    quick_view: false,
    display_name: 'Cargo Arrival Notice',
    template_types: [TERMS_AND_CONDITION, LETTER_HEAD],
  },
  'Network::CompanyAccount': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: true,
    display_name: 'Company Account',
    template_types: [LETTER_HEAD],
    has_record_loader: true,
  },
  'Docgen::GeneratedDocuments': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: true,
    display_name: 'Generated Documents',
    template_types: [LETTER_HEAD],
  },
  'Shipment::ShipmentInvoice': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: false,
    display_name: 'Commerical Invoice',
    has_custom_field: true,
    template_types: [TERMS_AND_CONDITION, PRINT, REMARKS, DECLARATION],
  },
  'Shipment::ShipmentManifest': {
    has_bulk_actions: false,
    show_tracker_drawer: true,
    has_actions: false,
    show_preview: false,
    quick_view: false,
    display_name: 'Shipment Manifest',
  },
  'Network::SalesPerson': {
    has_bulk_actions: true,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: false,
    has_record_loader: true,
    display_name: 'Sales Person',
  },
  'Network::UserContact': {
    has_bulk_actions: true,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: true,
    has_record_loader: true,
    display_name: 'User Contact',
    pretty_display_col: 'name',
    table_name: 'user_contacts',
    is_centralized_di: true,
  },
  'Blueprint::CustomField': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: false,
    display_name: 'Custom Fields',
  },
  'Network::UserAccount': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: false,
    show_preview: false,
    quick_view: false,
    has_record_loader: false,
    display_name: 'User Account',
  },
  'Network::BranchAccount': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: false,
    show_preview: false,
    quick_view: false,
    has_record_loader: true,
    display_name: 'Branch Account',
  },
  'Global::OceanVessel': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: false,
    show_preview: false,
    quick_view: false,
    has_record_loader: true,
    display_name: 'Ocean Vessel',
  },
  'Global::Location': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: false,
    show_preview: false,
    quick_view: false,
    has_record_loader: true,
    display_name: 'Location',
    pretty_display_col: 'name',
  },
  'Global::Carrier': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: false,
    show_preview: false,
    quick_view: false,
    has_record_loader: true,
    display_name: 'Carrier',
  },
  'Network::BusinessVertical': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: false,
    show_preview: false,
    quick_view: false,
    has_record_loader: true,
    display_name: 'Business Vertical',
  },
  'Network::Address': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: false,
    show_preview: false,
    quick_view: false,
    has_record_loader: true,
    display_name: 'Address',
    pretty_display_col: 'print_address',
  },
  'ServiceManagement::Ticket::Ticket': {
    has_bulk_actions: true,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    display_name: 'Ticket',
    quick_view: true,
  },
  'Email::Task': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    display_name: 'Tasks',
    quick_view: true,
  },
  'Wms::Product': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: true,
    has_custom_field: true,
    display_name: 'Warehouse Product',
  },
  'Wms::WarehouseTransaction': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: true,
    has_custom_field: false,
    display_name: 'Warehouse Transaction',
  },
  'Wms::WarehouseTransactionProduct': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: false,
    show_preview: false,
    quick_view: false,
    has_custom_field: true,
    display_name: 'Warehouse Transaction Product',
  },
  'Wms::IncomingShipment': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: true,
    display_name: 'Incoming Shipment',
    has_custom_field: true,
  },
  'Wms::Receipt': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: true,
    display_name: 'Receipt',
    has_custom_field: true,
  },
  'Wms::OutgoingShipment': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: true,
    display_name: 'Outgoing Shipment',
    has_custom_field: true,
  },
  'Wms::Delivery': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: true,
    display_name: 'Delivery',
    has_custom_field: true,
  },
  'Wms::GoodsTransfer': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    quick_view: true,
    display_name: 'Transfer',
    has_custom_field: true,
  },
  'Wms::WarehouseUnit': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    display_name: 'Warehouse Unit',
    quick_view: true,
  },
  'Shipment::Cargo': {
    show_tracker_drawer: false,
    has_bulk_actions: true,
    has_actions: true,
    show_preview: false,
    quick_view: false,
    display_name: 'Break Bulk And Bulk',
    has_custom_field: false,
  },
  'RateManagement::Contract': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    display_name: 'Rate Management Contracts',
    quick_view: false,
    template_types: [TERMS_AND_CONDITION],
  },

  'Document::ConsignmentNote': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: false,
    show_preview: false,
    quick_view: false,
    display_name: 'Consignment Note',
    template_types: [TERMS_AND_CONDITION],
  },
  'Shipment::Route': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: true,
    show_preview: false,
    has_record_loader: true,
    quick_view: false,
    display_name: 'Shipment Route',
  },
  'Network::CompanyGroup': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: false,
    show_preview: false,
    display_name: 'Company Group',
    quick_view: false,
    pretty_display_col: 'name',
    has_record_loader: true,
  },
  'ERP::Currency': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: false,
    show_preview: false,
    display_name: 'Currency',
    quick_view: false,
    has_record_loader: true,
  },
  'ERP::PaymentTermsTemplates': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: false,
    show_preview: false,
    display_name: 'Payment Terms Templates',
    quick_view: false,
    has_record_loader: true,
  },
  'ERP::Companies': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: false,
    show_preview: false,
    display_name: 'Companies',
    quick_view: false,
    has_record_loader: true,
  },
  'ERP::PayableTaxWithholdingCategories': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: false,
    show_preview: false,
    display_name: 'Payable Tax With Holding Categories',
    quick_view: false,
    has_record_loader: true,
  },
  'ERP::ReceivableTaxWithholdingCategories': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: false,
    show_preview: false,
    display_name: 'Receivable Tax With Holding Categories',
    quick_view: false,
    has_record_loader: true,
  },
  'ERP::PayableAccounts': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: false,
    show_preview: false,
    display_name: 'Payable Accounts',
    quick_view: false,
    has_record_loader: true,
  },
  'ERP::ReceivableAccounts': {
    has_bulk_actions: false,
    show_tracker_drawer: false,
    has_actions: false,
    show_preview: false,
    display_name: 'Receivable Accounts',
    quick_view: false,
    has_record_loader: true,
  },
};

export const CARRIER_TYPE_AIR = 'air';
export const CARRIER_TYPE_OCEAN = 'ocean';
export const CARRIER_TYPE_NVOCC = 'nvocc';
export const CARRIER_TYPE_BONDED_CARRIER = 'bonded_carrier';
export const CARRIER_TYPE_COLOADER = 'coloader';

export const COMMODITY_TYPE_GENERAL = 'General';
export const COMMODITY_TYPE_PERISHABLE = 'Perishable';
export const COMMODITY_TYPE_TEMP_CONTROLLED = 'Temp Controlled';
export const COMMODITY_TYPE_HAZARDOUS = 'Hazardous';
export const COMMODITY_TYPE_BATTERY = 'Battery';

export const COMMODITY_MAP: Record<string, string> = {
  is_general: COMMODITY_TYPE_GENERAL,
  is_perishable: COMMODITY_TYPE_PERISHABLE,
  is_temp_controlled: COMMODITY_TYPE_TEMP_CONTROLLED,
  is_hazardous: COMMODITY_TYPE_HAZARDOUS,
  is_battery: COMMODITY_TYPE_BATTERY,
};
export const PERMISSION_CUSTOMER_VIEW = 'customer_view';
export const PERMISSION_CUSTOMER_CREATE_EDIT = 'customer_edit';
export const PERMISSION_SUPPLIER_CREATE_EDIT = 'supplier_edit';

// Constants for company types
export const COMPANY_TYPE_MAP: Record<
  string,
  { is_customer: boolean; is_lead: boolean; is_vendor: boolean }
> = {
  Contact: { is_customer: false, is_vendor: false, is_lead: false },
  Lead: { is_customer: false, is_vendor: false, is_lead: true },
  Customer: { is_customer: true, is_vendor: false, is_lead: true },
  Supplier: { is_customer: false, is_vendor: true, is_lead: false },
  Agent: { is_customer: true, is_vendor: true, is_lead: true },
};

// grouping company types based on configurations
export const LEAD_COMPANY_TYPES = Object.keys(COMPANY_TYPE_MAP).filter(
  (key) => COMPANY_TYPE_MAP[key].is_lead
);
export const CUSTOMER_COMPANY_TYPES = Object.keys(COMPANY_TYPE_MAP).filter(
  (key) => COMPANY_TYPE_MAP[key].is_customer
);
export const VENDOR_COMPANY_TYPES = Object.keys(COMPANY_TYPE_MAP).filter(
  (key) => COMPANY_TYPE_MAP[key].is_vendor
);

// Company Stage Constants
export const WON_CUSTOMER = 'won_customer';
export const WON_SUPPLIER = 'won_supplier';
export const WON_AGENT = 'won_agent';
export const LEAD_STAGE = 'lead';
export const LOST_LEAD = 'lost';
export const CHURNED_CUSTOMER = 'churned';
export const ACTIVE_CUSTOMER = 'active_customer';

// Company Type Constants
export const CUSTOMER = 'Customer';
export const SUPPLIER = 'Supplier';
export const AGENT = 'Agent';
export const CONTACT = 'Contact';
export const LEAD = 'Lead';

export const COMPANY_STAGE_MAP = [
  { to: CUSTOMER, from: LEAD, stage: ACTIVE_CUSTOMER },
  { to: CUSTOMER, from: CONTACT, stage: ACTIVE_CUSTOMER },
  { to: AGENT, from: SUPPLIER, stage: ACTIVE_CUSTOMER },
  { to: AGENT, from: LEAD, stage: ACTIVE_CUSTOMER },
  { to: LEAD, from: CONTACT, stage: LEAD_STAGE },
  { to: AGENT, from: CUSTOMER, stage: null },
  { to: SUPPLIER, from: LEAD, stage: null },
  { to: SUPPLIER, from: CONTACT, stage: null },
  { to: CONTACT, from: LEAD, stage: null },
];
export const PERMISSION_BLUEPRINT_CUSTOM_FIELD_CREATE = 'create';
export const FREIGHT_TYPE_AIR = 'air';
export const FREIGHT_TYPE_OCEAN = 'ocean';
export const FREIGHT_TYPE_ROAD = 'road';
export const FREIGHT_TYPE_RAIL = 'rail';

export const VEHICLE_CATEGORY = [
  { name: 'car_carrier_trailer', value: 'Car Carrier Trailer' },
  { name: 'car_carrier_truck', value: 'Car Carrier Truck' },
  { name: 'chassis_on_trailer', value: 'Chassis On Trailer (COT)' },
  { name: 'crane', value: 'Crane' },
  { name: 'excavator', value: 'Excavator' },
  { name: 'flat_bed_trailer', value: 'Flat Bed Trailer' },
  { name: 'forklift', value: 'Forklift' },
  { name: 'heavy_commercial_vehicle', value: 'Heavy Commercial Vehicle (HCV)' },
  { name: 'intermediate_commercial_vehicle', value: 'Intermediate Commercial Vehicle (ICV)' },
  { name: 'light_commercial_vehicle', value: 'Light Commercial Vehicle (LCV)' },
  { name: 'loader', value: 'Loader' },
  { name: 'low_bed_trailer', value: 'Low Bed Trailer' },
  { name: 'medium_commercial_vehicle', value: 'Medium Commercial Vehicle (MCV)' },
  { name: 'semi_low_bed_trailer', value: 'Semi Low Bed Trailer' },
  { name: 'small_commercial_vehicle', value: 'Small Commercial Vehicle (SCV)' },
  { name: 'tractor', value: 'Tractor' },
];

export const TRADE_TYPE_EXPORT = 'export';
export const TRADE_TYPE_IMPORT = 'import';
export const TRADE_TYPE_DOMESTIC = 'domestic';
export const TRADE_TYPE_CROSS_TRADE = 'cross_trade';
export type TradeType = 'export' | 'import' | 'domestic' | 'cross_trade';

export const TRADE_TYPES: RadioOptionProps[] = [
  { key: TRADE_TYPE_EXPORT, name: 'Export' },
  { key: TRADE_TYPE_IMPORT, name: 'Import' },
];
export const PERMISSION_CREATE = 'create';
export const PERMISSION_EDIT = 'edit';
export const PERMISSION_DISABLE = 'disable';
export const PERMISSION_LIST = 'list';
