import {
  Button,
  Form,
  FormInstance,
  Typography,
  PrintPreviewDocType,
  FilePdfOutlined,
} from '@shipmnts/pixel-hub';
import React, { useContext, useState } from 'react';
import BillingFilter from './BillingFilters';
import BillingTable from './BillingTable';
import { axiosInstance as axios } from 'common/utils/axiosDefaults';
import { SessionDataValue } from 'common/models/SessionData';
import { useSession } from 'common';

interface ReportRows {
  data?: any[];
  opening_balance?: any;
  closing_balance?: any;
  total_balance?: any;
}

interface WmsBillingReportContextType {
  reportRows: ReportRows;
  setReportRows: (rows: ReportRows) => void;
  form?: FormInstance;
  fetchData?: (values: any) => void;
  session?: SessionDataValue;
}
const WmsBillingReportContext = React.createContext<WmsBillingReportContextType | null>(null);
export const useBillingReportContext = (): WmsBillingReportContextType => {
  const context = useContext(WmsBillingReportContext);
  if (!context)
    throw new Error(
      'WmsBillingReportContext must be called from within the WmsBillingReportContextProvider'
    );
  return context;
};

export default function WmsBillingReportLayout() {
  const [reportRows, setReportRows] = useState<ReportRows>({});
  const [form] = Form.useForm();
  const session = useSession();
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [context, setContext] = useState<any>(null);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const fetchData = async (values: any) => {
    try {
      setDataLoading(true);
      const response = await axios.post(
        `${process.env.REPORT_MANAGER_API_URL}/wms_billing/execute`,
        values
      );
      const responseData = {
        data: response.data.data,
        total_balance: response.data.total_balance,
        opening_balance: response.data.opening_balance,
        closing_balance: response.data.closing_balance,
      };
      setReportRows(responseData);
      setContext({ ...values, file_name: 'WMS_STORAGE_REPORT' }); //Setting context data for PDF Preview with generated report data
      return responseData;
    } catch (error) {
      console.error(error);
      return { error };
    } finally {
      setDataLoading(false);
    }
  };

  return (
    <WmsBillingReportContext.Provider
      value={{ reportRows, setReportRows, form, fetchData, session }}
    >
      <div
        style={{
          borderBottom: '1px solid #dadee7',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '10px 24px',
        }}
      >
        <Typography.Title level={4} style={{ margin: 0 }}>
          Warehouse Management / Storage Charges
        </Typography.Title>

        <div>
          <Button
            disabled={dataLoading}
            loading={dataLoading}
            size="small"
            htmlType="submit"
            onClick={form.submit}
            style={{ fontWeight: 'bold', marginRight: '10px' }}
            type="primary"
          >
            Generate
          </Button>
          <Button
            disabled={!context}
            size="small"
            style={{ marginLeft: '10px' }}
            icon={<FilePdfOutlined />}
            onClick={() => setShowPreview(true)}
          >
            PDF
          </Button>
          {showPreview && (
            <PrintPreviewDocType
              docType={'Wms::WarehouseBilling'}
              resource_id={'report'}
              context={context}
              onClose={() => setShowPreview(false)}
            />
          )}
        </div>
      </div>

      <BillingFilter />
      <BillingTable />
    </WmsBillingReportContext.Provider>
  );
}
