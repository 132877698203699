import React, { useEffect } from 'react';
import { RowNode } from '@ag-grid-community/core';
import { Col, ExclamationCircleOutlined, Form, message, Modal } from '@shipmnts/pixel-hub';
import { useMutation } from '@apollo/client';
import { BULK_UPSERT_COMPANY_FIELD } from 'operations/graphql/bulkActions';

/**
 * Renders an action for editing a company field and returns the necessary parameters for the modal.
 *
 * @param {RowNode[]} selectedNodes - The selected rows from the data grid.
 * @param {function} onSuccess - Callback function to execute on successful mutation.
 * @param {number} selectedNodeCounts - Number of selected nodes.
 * @param {object} extraProps - Additional properties needed for the action.
 * @param {function} onCloseModal - Callback to close the modal.
 * @returns {object} - Action parameters including the component to be rendered.
 */
const editCompanyFieldRenderer = (
  selectedNodes: RowNode[],
  onSuccess: () => void,
  selectedNodeCounts: number,
  extraProps: {
    field_name?: string;
    label?: string;
    Komponent?: React.ElementType;
    KomponentProps?: any;
    actionValue?: any;
  },
  onCloseModal: () => void
) => {
  return {
    actionParams: {
      selectedNodes: selectedNodes,
      onSuccess: onSuccess,
      selectedNodeCounts: selectedNodeCounts,
      onCloseModal: onCloseModal,
      fieldName: extraProps.field_name,
      label: extraProps.label,
      Komponent: extraProps.Komponent,
      KomponentProps: extraProps.KomponentProps,
      actionValue: extraProps.actionValue,
    },
    component: EditCompanyField,
  };
};

// Modal component for editing a company field
const EditCompanyField = React.memo(function EditCompanyField(props: {
  selectedNodes: RowNode[];
  selectedNodeCounts: number;
  onClose: () => void;
  onSuccess: () => void;
  fieldName: string;
  label: string;
  Komponent: React.ElementType;
  KomponentProps?: any;
  actionValue?: any;
}): JSX.Element {
  const {
    selectedNodes,
    onClose,
    onSuccess,
    fieldName,
    label,
    Komponent,
    KomponentProps,
    actionValue,
  } = props;

  const [updateEvent, { loading }] = useMutation(BULK_UPSERT_COMPANY_FIELD);

  useEffect(() => {
    // Prompt the user for confirmation if an action value is provided
    if (actionValue && !Komponent)
      Modal.confirm({
        icon: <ExclamationCircleOutlined />,
        title: 'Are you sure?',
        content: 'This action will affect all selected Companies!',
        okText: 'Yes',
        onOk() {
          updateCall({ ids: selectedNodes.map((n) => parseInt(n.data.id)), input: actionValue });
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionValue]);

  /**
   * Executes the mutation to update the company field.
   *
   * @param {object} values - The variables for the mutation.
   */
  const updateCall = (values: any) => {
    updateEvent({
      variables: values,
    })
      .then((res) => {
        if (res.errors) {
          message.error(res.errors[0].message);
          return;
        } else if (res?.data?.bulk_upsert_company_field) {
          message.success('Update successfully');
          onSuccess();
        }
      })
      .catch(() => {
        message.error('Error occurred while updating');
      });
  };

  /**
   * Handles form submission for updating the company field.
   *
   * @param {object} values - Form values submitted by the user.
   */
  const onFinish = async (values: any) => {
    const variables: {
      ids?: number[];
      input?: any;
    } = { ids: selectedNodes.map((n) => parseInt(n.data.id)), input: {} };

    // Check if the value exists
    if (values[fieldName]) {
      // Check if the value is an object and has an `id` property
      if (values[fieldName]?.id) {
        variables.input[fieldName] = values[fieldName].id; // Use the `id` if it exists
      } else {
        variables.input[fieldName] = values[fieldName]; // Use the value directly
      }
    } else {
      return; // Exit if no value is provided
    }

    variables.input = {
      ...variables.input,
      ...actionValue,
    };

    updateCall(variables);
  };

  const [form] = Form.useForm();

  return (
    <>
      {Komponent && (
        <Modal
          title={`${label}`}
          open={true}
          okText={'Update'}
          onOk={form.submit}
          onCancel={onClose}
          confirmLoading={loading}
        >
          <Form layout="inline" form={form} requiredMark={false} onFinish={onFinish}>
            <Col span={24}>
              <Form.Item name={fieldName} label={label} rules={[{ required: true }]}>
                <Komponent {...KomponentProps} />
              </Form.Item>
            </Col>
          </Form>
        </Modal>
      )}
    </>
  );
});

export default editCompanyFieldRenderer;
