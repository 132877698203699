import Location, { LocationValue } from 'common/models/Location';
import { Instance, getSnapshot, types } from 'mobx-state-tree';
import { ContractCharge, ContractChargeValue } from './ContractCharge';
import Carrier, { CarrierValue } from 'common/models/Carrier';
import { DISCOUNT_TYPE_PERCENTAGE, WEIGHT_UNIT_KGS } from '../constants';
import { isNil as _isNil } from 'lodash';
import Company, { CompanyValue } from 'network/models/Company';
import { WeightSlabType } from '../types';

export const AirContractLineItem = types
  .model({
    id: types.identifier,
    port_of_loading: types.maybeNull(Location),
    ports_of_discharge: types.optional(types.array(Location), []),
    flight_number: types.maybeNull(types.string),
    flight_date: types.maybeNull(types.number),
    min_charge: types.maybeNull(types.number),
    normal_charge: types.maybeNull(types.number),
    commodity_type: types.maybeNull(types.string),
    currency: types.maybeNull(types.string),
    carrier: types.maybeNull(types.maybeNull(Carrier)),
    carrier_product: types.maybeNull(types.string),
    contract_charges: types.optional(types.array(ContractCharge), []),
    charge_terms: types.maybeNull(types.string),
    _destroy: types.optional(types.boolean, false),
    supplier_company: types.maybe(types.maybeNull(Company)),
  })
  .actions((self) => ({
    setAirportOfDischarge(locations: LocationValue[]) {
      try {
        const newValues = locations
          .filter((location) => !!location)
          .map((location) => ({
            ...location,
            id: String(location.id),
          }));
        self.ports_of_discharge.replace(newValues);
      } catch (error) {
        console.error(error);
      }
    },
    setCarrier(carrier: CarrierValue) {
      try {
        self.carrier_product = null;
        self.carrier = carrier;
      } catch (error) {
        console.error(error);
      }
    },
    setValue(
      key: 'flight_number' | 'carrier_product' | 'currency' | 'charge_terms' | 'commodity_type',
      value: string | null
    ) {
      try {
        self[key] = value;
      } catch (error) {
        console.error(error);
      }
    },
    setFlightDate(date: number) {
      self.flight_date = date;
    },
    setMinCharge(charge: number) {
      try {
        self.min_charge = Number(charge);
      } catch (error) {
        console.error(error);
      }
    },
    setNormalCharge(charge: number) {
      try {
        self.normal_charge = Number(charge);
      } catch (error) {
        console.error(error);
      }
    },
    setChargeByWeight(amount: number, start_weight: number, end_weight?: number | null) {
      try {
        const charge = self.contract_charges.find(
          (charge) =>
            charge.start_weight === start_weight && !charge.charge_name && !charge.isDestroyed()
        );
        if (charge) {
          charge.charge_amount = Number(amount);
        } else {
          const charge = ContractCharge.create({
            id: 'new',
            start_weight: start_weight,
            end_weight: end_weight || null,
            charge_amount: Number(amount),
            weight_unit: WEIGHT_UNIT_KGS,
          });
          self.contract_charges.push(charge);
        }
      } catch (error) {
        console.error(error);
      }
    },
    setChargeBySurcharge(
      charge_name: string,
      charge_amount?: number | null,
      charge_tag?: string | null,
      min_charge_amount?: number
    ) {
      try {
        const charge = self.contract_charges.find(
          (charge) => charge.charge_name === charge_name && !charge.isDestroyed()
        );
        if (charge) {
          if (!_isNil(charge_amount)) charge.charge_amount = Number(charge_amount);
          if (!_isNil(min_charge_amount)) charge.min_charge_amount = Number(min_charge_amount);
          charge.charge_tag = charge_tag || null;
        } else {
          const charge = ContractCharge.create({
            id: 'new',
            min_charge_amount: Number(min_charge_amount || 0),
            charge_name,
            charge_amount: Number(charge_amount || 0),
            charge_tag: charge_tag || null,
            end_weight: null,
          });
          self.contract_charges.push(charge);
        }
      } catch (error) {
        console.error(error);
      }
    },
    // Function that sets discount for a specific weight slab, and creates new charge if it doesn't exist.
    setDiscountByWeight(
      start_weight: number,
      end_weight: number | null,
      discount: number | null,
      discount_type: string | null
    ) {
      try {
        const charge = self.contract_charges.find(
          (charge) =>
            charge.start_weight === start_weight && !charge.charge_name && !charge.isDestroyed()
        );
        // if charge is already present, update the discount.
        if (charge) {
          charge.setDiscount(Number(discount));
          charge.setDiscountType(discount_type || DISCOUNT_TYPE_PERCENTAGE);
        } else {
          const charge = ContractCharge.create({
            id: 'new',
            start_weight: start_weight,
            end_weight: end_weight,
            discount: Number(discount),
            discount_type: discount_type || DISCOUNT_TYPE_PERCENTAGE,
          });
          self.contract_charges.push(charge);
        }
      } catch (error) {
        console.error(error);
      }
    },
    setSupplierCompany(company: CompanyValue | null) {
      try {
        self.supplier_company = company;
      } catch (error) {
        console.error(error);
      }
    },
    setDestroy: (value: boolean) => (self._destroy = value),
    createCharge: (charge: ContractChargeValue) => {
      self.contract_charges.push(charge);
    },
    destroyAllCharges: () => {
      if (self.contract_charges)
        self.contract_charges?.forEach((charge) => charge.setDestroy(true));
      return true;
    },
    updateSlabs: (slabs: WeightSlabType[]) => {
      self.contract_charges
        ?.filter((charge) => !charge.charge_name && !charge.isDestroyed())
        ?.forEach((charge) => {
          const slab = slabs?.find((s) => {
            return s.start_weight === charge.start_weight && s.end_weight === charge.end_weight;
          });

          if (!slab) charge.setDestroy(true);
        });

      return true;
    },
    // in data import, when non-number value is passed
    setChargeAmountToZero: () => {
      if (self.contract_charges) {
        for (const charge of self.contract_charges || []) {
          if (!charge.charge_amount) charge.setChargeAmount(0);
        }
      }
    },
  }))
  .actions((self) => ({
    // Function that sets discounts for all weight slabs.
    setDiscountsForAllCharges(
      discounts: { [key: string]: number | undefined | string },
      weightSlabs: number[]
    ) {
      if (!discounts) return;
      weightSlabs.forEach((weight) => {
        const discountKey = `${weight}_discount`;
        const discount = discounts[discountKey];
        const end_weight =
          weightSlabs.findIndex((w) => w === weight) + 1 < weightSlabs.length
            ? weightSlabs[weightSlabs.findIndex((w) => w === weight) + 1]
            : null;
        if (discount !== undefined) {
          // Set discounts and discount type for each weight slab.
          self.setDiscountByWeight(
            weight,
            end_weight,
            discount as number,
            discounts.basis ? `${discounts.basis}` : DISCOUNT_TYPE_PERCENTAGE
          );
        } else if (discounts.basis) {
          //if only Discount Basis is set
          self.setDiscountByWeight(weight, end_weight, null, `${discounts.basis}`);
        }
      });
    },
    updateCurrencyFromCharge: (currency?: string | null) => {
      if (!self.currency && currency) self.currency = currency;
    },
  }))
  .views((self) => ({
    getID() {
      return self.id;
    },
    isDestroy() {
      return self._destroy;
    },
    getAllDiscounts() {
      return self.contract_charges.reduce((acc: any, charge) => {
        if (charge.discount) {
          acc[`${charge.start_weight}_discount`] = charge.discount;
          acc.basis = charge.discount_type;
        }
        return acc;
      }, {});
    },
    getDiscountByStartWeight(weight: number, discount_type = DISCOUNT_TYPE_PERCENTAGE) {
      const charge = self.contract_charges.find(
        (charge) => charge.start_weight === weight && !charge.charge_name && !charge.isDestroyed()
      );
      if (!charge) return '';
      return charge.getDiscountString(discount_type);
    },
    getAirportOfDischarge() {
      return self.ports_of_discharge.map((pod) => getSnapshot(pod));
    },
    getCarrier() {
      return self.carrier;
    },
    getCarrierName() {
      return self.carrier ? self.carrier.name : '';
    },
    getSupplierCompany() {
      return self.supplier_company;
    },
    getCarrierIataCode() {
      return self.carrier ? self.carrier.iata_carrier_code : '';
    },
    getValue(
      key:
        | 'flight_number'
        | 'carrier_product'
        | 'currency'
        | 'flight_date'
        | 'min_charge'
        | 'normal_charge'
        | 'charge_terms'
        | 'commodity_type'
    ) {
      return self[key];
    },
    getChargeByStartWeight(
      weight: number,
      end_weight?: number | null,
      createIfNotPresent?: boolean
    ) {
      const charge = self.contract_charges.find(
        (charge) => charge.start_weight === weight && !charge.charge_name && !charge.isDestroyed()
      );
      if (!charge && createIfNotPresent) {
        const charge = ContractCharge.create({
          id: 'new',
          start_weight: weight,
          end_weight: end_weight || null,
          charge_amount: null,
          weight_unit: WEIGHT_UNIT_KGS,
        });
        self.createCharge(charge);
        return null;
      }
      return !!charge ? charge : null;
    },
    getChargeBySurcharge(title: string, charge_tag?: string | null) {
      const charge = self.contract_charges.find(
        (charge) => charge.charge_name === title && !charge.isDestroyed()
      );
      if (!charge) {
        const charge = ContractCharge.create({
          id: 'new',
          min_charge_amount: 0,
          charge_name: title,
          charge_amount: 0,
          charge_tag: charge_tag || null,
          end_weight: null,
        });
        self.createCharge(charge);
        return null;
      }
      return charge ? charge.charge_amount : null;
    },
    getMinChargeBySurcharge(title: string, charge_tag?: string | null) {
      const charge = self.contract_charges.find(
        (charge) => charge.charge_name === title && !charge.isDestroyed()
      );
      if (!charge) {
        const charge = ContractCharge.create({
          id: 'new',
          min_charge_amount: 0,
          charge_name: title,
          charge_amount: 0,
          charge_tag: charge_tag || null,
          end_weight: null,
        });
        self.createCharge(charge);
        return null;
      }
      return charge ? charge.min_charge_amount : null;
    },
    getPayload: (aod: LocationValue, has_discounts = false, removeIds = false) => {
      const lineItem = getSnapshot(self);
      const { ports_of_discharge, port_of_loading, carrier, supplier_company, ...rest } = lineItem;
      if (!has_discounts) {
        // Remove discounts from charges if not required.
        self.contract_charges?.forEach((charge) => charge.removeDiscount());
      }
      return {
        ...rest,
        port_of_discharge_ids: ports_of_discharge.map((aod) => aod.id),
        port_of_loading_id: aod.id,
        carrier_id: carrier?.id,
        supplier_company_id: supplier_company?.id || null,
        id: lineItem.id === 'new' || removeIds ? null : lineItem.id,
        contract_charges: self.contract_charges?.map((charge) => charge.getPayload(removeIds)),
        _destroy: lineItem._destroy ? true : undefined,
      };
    },

    getWeightSlabs: () => {
      const contractCharges = getSnapshot(self.contract_charges);
      const slabs = contractCharges
        .filter((charge) => !charge._destroy)
        .filter((charge) => !charge.charge_name) // assuming, charge with charge_name are configured surcharge wise
        .map((charge) => ({
          start_weight: charge.start_weight,
          end_weight: (charge?.end_weight || null) as number | null,
        }))
        .sort((a, b) => a.start_weight - b.start_weight);
      return slabs;
    },
  }));

export type AirContractLineItemValue = Instance<typeof AirContractLineItem>;

export default AirContractLineItem;
