/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut, IAnyModelType } from 'mobx-state-tree';
import CompanyAccount, { FeatureKey } from './CompanyAccount';
import BranchAccount from './BranchAccount';
import { get } from 'lodash';

const Permission = types.model({
  id: types.identifier,
  permission_name: types.maybe(types.maybeNull(types.string)),
  doc_type: types.maybe(types.maybeNull(types.string)),
  types: types.string,
  is_collaborator: types.maybeNull(types.boolean),
});

const Role = types.model({
  id: types.identifier,
  role_name: types.string,
  permissions: types.maybe(types.array(Permission)),
});

export interface PermissionValue extends Instance<typeof Permission> {}
export interface PermissionIn extends SnapshotIn<typeof Permission> {}
export interface PermissionOut extends SnapshotOut<typeof Permission> {}

const SessionData = types
  .model({
    id: types.identifier,
    first_name: types.string,
    name: types.string,
    last_name: types.maybe(types.maybeNull(types.string)),
    user_level: types.maybe(types.maybeNull(types.string)),
    user_contact_id: types.maybe(types.maybeNull(types.string)),
    tenant_id: types.maybe(types.string),
    email: types.string,
    company_account: types.late((): IAnyModelType => CompanyAccount),
    branch_accounts: types.maybe(types.array(types.late((): IAnyModelType => BranchAccount))),
    role: types.maybe(types.maybeNull(types.late((): IAnyModelType => Role))),
    dashboard_id: types.maybe(types.maybeNull(types.string)),
  })
  .views((self) => ({
    get permissions() {
      return self.role.permissions || [];
    },
    isFeatureEnabled(feature_name: FeatureKey) {
      const features = self.company_account.features;
      return features && !!features[feature_name];
    },
    getSubscriptions() {
      return get(self, 'company_account.subscriptions', []);
    },
  }));

export interface SessionDataValue extends Instance<typeof SessionData> {}
export interface SessionDataIn extends SnapshotIn<typeof SessionData> {}
export interface SessionDataOut extends SnapshotOut<typeof SessionData> {}

export default SessionData;
