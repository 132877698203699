import { Form } from '@shipmnts/pixel-hub';
import React, { useRef } from 'react';
import ContainerDetails from 'operations/components/ContainerDetails';
import { isLiveReeferCargo } from 'operations/models/Cargo';

interface ContainerQuantityProps {
  disabled?: boolean;
  disableAdd?: boolean;
  disableDelete?: boolean;
  allowZeroQty?: boolean;
}
export default function ContainerQuantity(props: ContainerQuantityProps) {
  const { disabled, disableAdd, disableDelete, allowZeroQty = false } = props;
  const container_requests_ref = useRef<{ runValidation: () => boolean }>();

  return (
    <Form.Item noStyle dependencies={['cargos']} shouldUpdate>
      {({ getFieldValue }) => {
        const cargos = getFieldValue('cargos');
        const isLiveReefer = cargos ? isLiveReeferCargo(cargos) : false;
        return (
          <Form.Item
            name="shipment_container_quantity"
            noStyle
            rules={[
              {
                validator: (rule, value) => {
                  if (!container_requests_ref?.current?.runValidation()) {
                    return Promise.resolve();
                  }
                  return Promise.reject();
                },
              },
            ]}
            shouldUpdate={(prevValues: any, currentValues: any) =>
              prevValues.cargos !== currentValues.cargos
            }
          >
            <ContainerDetails
              disabled={disabled}
              disableAdd={disableAdd}
              disableDelete={disableDelete}
              ref={container_requests_ref}
              required_fields={['container_type_code', 'quantity']}
              isLiveReefer={isLiveReefer}
              allowZeroQty={allowZeroQty}
            />
          </Form.Item>
        );
      }}
    </Form.Item>
  );
}
