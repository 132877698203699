import { ApolloError, useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import {
  CreditLimitCheckContext,
  CreditLimitCheckWrapper,
  Form,
  Skeleton,
  message,
} from '@shipmnts/pixel-hub';
import { LOAD_TYPE_FCL } from 'network/baseConstants';
import { SHIPMENT } from 'operations/graphql/shipment';
import Shipment, { ShipmentValue } from 'operations/models/Shipment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useParams } from 'wouter';
import {
  FREIGHT_TYPE_OCEAN,
  SHIPMENT_TYPE_CONSOL,
  TRADE_TYPE_EXPORT,
  TRADE_TYPE_IMPORT,
} from '../../constants';
import {
  getShipmentComponents,
  getShipmentApiHelper,
  redirectToShipment,
  handleShipperConsigneeOnMBLPrefilling,
} from './helpers';
import { omit as _omit, startCase } from 'lodash';
import { useApplicationContentContext, useEmailDrawerContext, useSession } from 'common';
import { getBookingType, getInitialValue } from './getInitialValueHelper';
import {
  getGenericPayloadValue,
  getHouseDocumentPayload,
  getMasterDocumentPayload,
} from './getGenericPayloadHelper';
import FormLayout from '../ShipmentForm/FormLayout';
import { getSalesPersonForCustomer } from 'operations/helpers/fetchSalesPerson';
import { ProductOrderItemValue } from 'operations/models/ProductOrderItem';
import InquiryOption, { InquiryOptionValue } from 'operations/models/InquiryOption';
import { GET_INQUIRY_OPTION } from 'operations/modules/booking/graphql/inquiryOption';
import { getJsonFromError } from 'operations/helpers/ruleEngineHelper';
import { BOOKING_TYPE_SHIPMENT } from 'operations/modules/booking/constants';
import { getFetchDocumentFunction } from 'operations/modules/helpers';
import { SHIPMENT_TYPE_HOUSE } from '../../constants';
function useQuerySearch(): URLSearchParams {
  return new URLSearchParams(window.location.search);
}

interface NewShipmentFormProps {
  showCreditPopupWithAction?: (payload: any) => void;
}

function NewShipmentForm(props: NewShipmentFormProps) {
  const { showCreditPopupWithAction } = props;
  const { 1: navigate } = useLocation();
  const initialValue = window.history.state?.initialValue
    ? JSON.parse(window.history.state?.initialValue)
    : undefined;
  // hooks
  const [form] = Form.useForm();
  const params = useParams<{ id?: string; shipmentStatus?: string }>();
  const query = useQuerySearch();
  const sessionData = useSession();
  const client = useApolloClient();
  const { setVisible, setEmailProps } = useEmailDrawerContext();
  const uploadDocRef = useRef<any>();
  const cargoRef = useRef<{
    runValidation: () => boolean;
    getPayload: () => ProductOrderItemValue[];
  }>();
  // params
  const fromCustomerOrder: string | null | undefined = query.get('fromCustomerOrder');
  const fromOto: string | null | undefined = query.get('from_oto');
  const masterShipmentId: string | null | undefined = query.get('master_shipment_id');
  const { config_data } = useApplicationContentContext();
  // states
  const [shipment, setShipment] = useState<ShipmentValue>();
  const [masterShipment, setMasterShipment] = useState<ShipmentValue>();
  const [shipmentType, setShipmentType] = useState<string | null | undefined>(
    query.get('shipment_type')
  );
  const [freightType, setFreightType] = useState<string>(
    query.get('freight_type') || FREIGHT_TYPE_OCEAN
  );
  const [isBookingAvailable, setIsBookingAvailable] = useState<boolean>(false);

  const [tradeType, setTradeType] = useState<string>(query.get('trade_type') || TRADE_TYPE_EXPORT);
  const [components, setComponents] = useState<any[]>([]);
  const [inquiryOption, setInquiryOption] = useState<InquiryOptionValue>();
  const [clearanceShipment, setClearanceShipment] = useState<boolean>(
    query.get('clearance_shipment') === 'true' ? true : false
  );

  const loadType = Form.useWatch('load_type', form);
  const bookingType = Form.useWatch('booking_type', form);
  const formInquiryOption = Form.useWatch('inquiry_option', form);
  const [sendNotification, setSendNotification] = useState(false);
  const freight_forwarding_shipment = query.get('freight_forwarding_shipment')
    ? true
    : fromOto
    ? true
    : false;
  const default_company = sessionData?.company_account?.default_company;

  // variables
  const { id: shipmentId } = params;
  const type = shipmentId === 'new' ? 'create' : 'update';
  const inquiry_option_id = query.get('inquiry_option_id');

  // mutations
  const [fetchShipment, { data: fetchedShipment }] = useLazyQuery(SHIPMENT);
  const [fetchInquiryOption, { data: fetchedInquiryOptionData }] = useLazyQuery(GET_INQUIRY_OPTION);
  const [fetchMasterShipment, { data: masterShipmentData }] = useLazyQuery(SHIPMENT);

  const [createOrUpdateShipment, { data: createData, loading, error }] = useMutation(
    getShipmentApiHelper(type, shipmentType),
    { context: { skipShowError: true } }
  );

  const goBack = () => {
    window.history.back();
  };

  /**
   * Handle Shipment Form Submission
   * @param values: form values
   * @param throw_error_on_credit_fail: boolean, default false, if true, then throw error on credit fail
   * @returns void : => call createOrUpdateShipment mutation
   */
  const handleShipmentFormSubmission = useCallback(
    (values: any, throw_error_on_credit_fail = false) => {
      const variables: any = {};
      if (values.house?.shipment_document) {
        variables['shipment_document_house'] = getHouseDocumentPayload(values);
      }
      if (values.master?.shipment_document) {
        variables['shipment_document_master'] = getMasterDocumentPayload(values);
      }
      const payload: any = getGenericPayloadValue(
        {
          ...values,
          type,
          shipment_containers: initialValue?.selectedContainers || values?.shipment_containers,
          shipment_type: shipmentType,
          sessionData,
        },
        shipment,
        clearanceShipment,
        masterShipmentId
      );
      if (fromOto) {
        payload.allocate_ocean_transport_orders = [
          {
            id: fromOto,
            container_requests: values.shipment_container_quantity.map((cr: any) => ({
              id: cr.id,
              count: cr.quantity,
            })),
          },
        ];
      }
      if (masterShipmentId) {
        variables['house_shipment'] = {
          ..._omit(payload, [
            'freight_type',
            'trade_type',
            'involved_branch_id',
            'job_date',
            'shipment_type',
          ]),
        };
        variables['master_shipment_id'] = masterShipmentId;
      } else if (!shipmentType && shipmentId === 'new') variables['planned_shipment'] = payload;
      else variables['shipment'] = payload;

      variables['throw_error_on_credit_fail'] = throw_error_on_credit_fail;
      if (shipmentId !== 'new') variables['id'] = shipment?.id;
      createOrUpdateShipment({ variables: variables });
    },
    [
      clearanceShipment,
      createOrUpdateShipment,
      fromOto,
      initialValue?.selectedContainers,
      masterShipmentId,
      sessionData,
      shipment,
      shipmentId,
      shipmentType,
      type,
    ]
  );

  useEffect(() => {
    if (fetchedInquiryOptionData?.get_inquiry_option) {
      const InquiryOptionMbx = InquiryOption.create(fetchedInquiryOptionData?.get_inquiry_option);
      setInquiryOption(InquiryOptionMbx);
      if (InquiryOptionMbx?.inquiry?.trade_type)
        setTradeType(InquiryOptionMbx?.inquiry?.trade_type);
      if (InquiryOptionMbx?.inquiry?.freight_type)
        setFreightType(InquiryOptionMbx?.inquiry?.freight_type);
    }
  }, [fetchedInquiryOptionData]);

  useEffect(() => {
    if (inquiryOption) {
      const services = inquiryOption?.inquiry?.services;
      const origin_clearance_service = services?.includes('origin_custom_clearance');
      const destination_clearance_service = services?.includes('destination_custom_clearance');
      const freight_forwarding_service = services?.includes('freight_forwarding');

      if (
        (origin_clearance_service || destination_clearance_service) &&
        !freight_forwarding_service
      ) {
        setClearanceShipment(true);
      }
    }
  }, [inquiryOption]);

  useEffect(() => {
    if (masterShipmentData?.shipment) {
      setMasterShipment(masterShipmentData?.shipment);
    }
  }, [masterShipmentData, setMasterShipment]);

  useEffect(() => {
    if (masterShipmentData?.shipment) {
      setMasterShipment(masterShipmentData?.shipment);
    }
  }, [masterShipmentData, setMasterShipment]);

  useEffect(() => {
    const uploadDocinAsync = async (createdShpId: any) => {
      await uploadDocRef.current?.updateDocsToParent(createdShpId);
    };
    if (loading) return;
    if (error) {
      const graphQLErrors = error instanceof ApolloError ? error.graphQLErrors : error;
      const data: any = getJsonFromError(graphQLErrors);
      if (data && showCreditPopupWithAction) {
        const values: any = form.getFieldsValue();
        setSendNotification(false);
        showCreditPopupWithAction({
          data: data,
          onSucess: () => {
            handleShipmentFormSubmission(values);
          },
        });
      } else {
        message.error(error.message);
      }
    }
    const shipment =
      createData?.update_shipment_new ||
      createData?.create_house_shipment ||
      createData?.create_planned_shipment ||
      createData?.create_shipment?.shipment ||
      createData?.create_shipment?.house_shipment;
    if (createData && shipment) {
      const createdShipmentId = shipment.id;
      uploadDocinAsync(createdShipmentId);
      redirectToShipment(type, shipmentType ? 'Shipment' : 'Customer Order', createData, navigate);
    }
  }, [
    navigate,
    createData,
    loading,
    error,
    shipment,
    createOrUpdateShipment,
    form,
    showCreditPopupWithAction,
    fromOto,
    initialValue,
    masterShipmentId,
    shipmentId,
    shipmentType,
    type,
    default_company,
    handleShipmentFormSubmission,
  ]);

  useEffect(() => {
    const _shipment =
      createData?.update_shipment_new ||
      createData?.create_house_shipment ||
      createData?.create_planned_shipment ||
      createData?.create_shipment?.shipment ||
      createData?.create_shipment?.house_shipment;
    if (sendNotification && _shipment) {
      openSendEmailDrawer(_shipment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createData]);

  const openSendEmailDrawer = async (_shipment: ShipmentValue) => {
    const { data } = await client.query({
      query: SHIPMENT,
      variables: { id: _shipment.id },
    });
    const shipment = Shipment.create(data.shipment);

    if (!shipment) return;
    const isBookingConfirmed = shipment?.ocean_transport_orders?.length;
    const oto_ids = (shipment?.ocean_transport_orders || []).map((oto) => oto.id);

    setEmailProps({
      title: `${isBookingConfirmed ? 'Booking Confirmation' : 'Customer Order Acknowledgement'}`,
      action_name: `${
        isBookingConfirmed ? 'booking_confirmation' : 'booking_request_acknowledgement'
      }`,
      resource_ids: [shipment?.id],
      fetchDocumentParents: [
        {
          parent_type: BOOKING_TYPE_SHIPMENT,
          parent_ids: [shipment?.id],
        },
        {
          parent_type: 'ocean_transport_order',
          parent_ids: oto_ids,
        },
      ],
      companies_roles_mapping: shipment.getAllPartiesRolesMapping(sessionData),
      showMarkDown: !isBookingConfirmed ? true : false,
      showEmail: isBookingConfirmed ? true : false,
      fetchDocuments: client
        ? getFetchDocumentFunction(shipment, client, sessionData, config_data)
        : undefined,
    });
    setVisible(true);
  };

  useEffect(() => {
    if (shipmentId !== 'new') {
      fetchShipment({ variables: { id: shipmentId } });
    }
  }, [fetchShipment, shipmentId]);

  // fetching inquiry data
  useEffect(() => {
    if (inquiry_option_id) {
      fetchInquiryOption({ variables: { id: inquiry_option_id } });
    }
  }, [fetchInquiryOption, inquiry_option_id]);

  // fetching master shipment data
  useEffect(() => {
    if (masterShipmentId) {
      fetchMasterShipment({
        variables: {
          id: masterShipmentId,
        },
      });
    }
  }, [fetchMasterShipment, masterShipmentId]);

  useEffect(() => {
    if (fetchedShipment?.shipment) {
      const shipmentMbx = Shipment.create(fetchedShipment.shipment);
      if (shipmentMbx.shipment_type === SHIPMENT_TYPE_HOUSE) {
        setMasterShipment(shipmentMbx.master_shipment);
      }
      setShipment(shipmentMbx);
      if (shipmentMbx.freight_type) setFreightType(shipmentMbx.freight_type);
      if (shipmentMbx.trade_type) setTradeType(shipmentMbx.trade_type);
      setShipmentType(shipmentMbx.shipment_type);
    }
  }, [fetchedShipment]);

  // get components
  useEffect(() => {
    setComponents(
      getShipmentComponents({
        freightType: freightType,
        tradeType: tradeType,
        form,
        shipment: shipment,
        type: shipmentId === 'new' ? 'create' : 'update',
        shipmentType: shipmentType,
        fromCustomerOrder,
        fromOto,
        loadType: loadType || shipment?.load_type || LOAD_TYPE_FCL,
        bookingType,
        masterShipmentId: masterShipmentId,
        tennantCountry: sessionData?.company_account?.country_of_incorporation,
        cargoRef: cargoRef,
        isClearance: clearanceShipment,
        inquiryOption: formInquiryOption,
        inquiryOptionState: inquiryOption,
        masterShipment: masterShipment,
        sessionData,
        uploadDocRef,
        setIsBookingAvailable,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    shipment,
    freightType,
    tradeType,
    loadType,
    form,
    bookingType,
    setComponents,
    shipmentId,
    clearanceShipment,
    formInquiryOption,
    uploadDocRef,
    masterShipment,
  ]);

  const formInitialValue: any = getInitialValue(
    shipment || initialValue,
    {
      trade_type: tradeType,
      loadType: loadType || LOAD_TYPE_FCL,
      freight_type: freightType,
      shipment_type: shipmentType,
      clearance_shipment: clearanceShipment,
      freight_forwarding_shipment: freight_forwarding_shipment,
    },
    initialValue ? true : false,
    inquiryOption,
    sessionData
  );

  if (
    (shipmentId !== 'new' && !shipment) ||
    (inquiry_option_id && !inquiryOption) ||
    (masterShipmentId && !masterShipment)
  ) {
    return <Skeleton />;
  }
  if (shipment && shipment.master_shipment && !masterShipment) return <Skeleton />;

  return (
    <Form
      form={form}
      scrollToFirstError
      initialValues={formInitialValue}
      onFinish={(values: any): any => {
        const error = Boolean(cargoRef?.current?.runValidation());
        if (error) return;
        handleShipmentFormSubmission(values, true);
      }}
      layout="vertical"
      style={{
        height: '100%',
      }}
      onValuesChange={async (changedValues, allValues) => {
        const formValues: any = {};
        if (changedValues.terms_and_condition) {
          formValues['terms_and_condition_description'] =
            changedValues.terms_and_condition?.content;
        }
        if (changedValues.hasOwnProperty('customer')) {
          const branch_id = form.getFieldValue('involved_branch')?.id;
          const customer = changedValues.customer;
          if (branch_id && customer) {
            const { response } = await getSalesPersonForCustomer(
              customer?.party_company?.id,
              branch_id,
              sessionData,
              client
            );
            if (response) {
              formValues['sales_agent'] = response;
              formValues['sales_person'] = response;
            }
          }
        }
        if (
          changedValues?.party?.origin_agent &&
          allValues?.trade_type === TRADE_TYPE_IMPORT &&
          allValues?.load_type === LOAD_TYPE_FCL
        ) {
          formValues['vendor'] = changedValues.party.origin_agent;
        }
        if (changedValues?.sales_agent && !allValues?.sales_person) {
          formValues['sales_person'] = changedValues.sales_agent;
        }
        if (changedValues?.load_type) {
          formValues['booking_type'] = getBookingType(
            allValues.trade_type,
            changedValues.load_type
          );
        }
        if (changedValues?.involved_branch) {
          formValues['booking_party'] = {
            ...(allValues?.booking_party ?? {}),
            party_address: changedValues?.involved_branch?.default_address,
          };
        }
        if (changedValues?.consol_type && shipmentType === SHIPMENT_TYPE_CONSOL)
          handleShipperConsigneeOnMBLPrefilling(changedValues?.consol_type, form);
        if (Object.keys(formValues).length > 0) form.setFieldsValue(formValues);
      }}
      requiredMark={true}
    >
      <FormLayout
        form={form}
        components={components}
        formTitle={
          <>
            {shipmentId === 'new' ? 'Create' : 'Update'}{' '}
            {shipmentType ? 'Shipment' : 'Customer Order'}{' '}
            {(shipment?.job_number || shipment?.shipment_booking_number) && (
              <Link to={'~/view/shipment/' + shipment?.id}>
                {shipment?.job_number || shipment?.shipment_booking_number}
              </Link>
            )}
          </>
        }
        loading={loading}
        formSubTitle={startCase(
          shipment?.shipment_type ? shipment?.getShipmentType() : shipmentType || ''
        )}
        formOnSubmitText={`${shipmentId === 'new' ? 'Create' : 'Update'}`}
        onBack={() => {
          if (shipmentId === 'new') {
            if (fromCustomerOrder === 'true') {
              navigate('~/view/shipment/' + initialValue.id);
            } else {
              goBack();
            }
          } else {
            navigate('~/view/shipment/' + shipmentId);
          }
        }}
        sendEmailProps={{
          label: isBookingAvailable ? 'Send Booking Confirmation' : 'Send Order Acknowledgement',
          sendNotification: sendNotification,
          setSendNotification: setSendNotification,
        }}
      />
    </Form>
  );
}

export default function NewShipmentFormWrapper() {
  const sessionData = useSession();
  return (
    <CreditLimitCheckWrapper sessionData={sessionData}>
      <CreditLimitCheckContext.Consumer>
        {(contextProps: any) => {
          return (
            <NewShipmentForm showCreditPopupWithAction={contextProps?.showCreditPopupWithAction} />
          );
        }}
      </CreditLimitCheckContext.Consumer>
    </CreditLimitCheckWrapper>
  );
}
