import { gql } from '@apollo/client';
import { salesPersonFields } from './salesPerson';
import { addressFields } from './shipmentEstimate';

export const GET_INQUIRY = gql`
  query get_inquiry($id: ID!) {
    get_inquiry(id: $id) {
      id
      inquiry_global_id
      trade_type
      load_type
      freight_type
      priority
      last_action_status
      service_type
      movement_mode
      inquiry_number
      port_of_loading {
        id
        type
        name
        unlocode
      }
      port_of_discharge {
        id
        type
        name
        unlocode
      }
      cargos {
        id
        cargo_properties {
          is_perishable
          is_temp_controlled
          is_hazardous
          is_battery
          un_number
          un_imo_class
          proper_shipping_name
          technical_name
          packaging_group
          net_weight
          net_weight_unit
          flash_point
          flash_point_unit
          ems_number
          emergency_contact_name
          emergency_contact_number
          aircraft_type
          is_radioactive
          temperature_unit
          temperature
          battery_type
        }
        product_description
        outer_package_qty
        outer_package_type
        gross_volume
        gross_weight
        weight_unit
        volume_unit
        commodity_id
        product_name
        net_weight
        commodity_type
        volumetric_weight
        chargeable_weight
        invoice_number
        invoice_date
        batch_number
        custom_ref
        serial_number
        eway_bill_no
        eway_bill_validity
        commodity {
          id
          name
          commodity_classification_code
          commodity_coding_system
          dangerous_cargo
          dry_cargo
          live_reefer_cargo
          oog_cargo
        }
        id
        gross_volume
        height
        outer_package_qty
        outer_per_packet_wt
        width
        length
        dimension_unit
      }
      container_requests {
        id
        container_settings {
          is_active_reefer
          temperature
          temperature_unit
          ventilation_requested
          air_flow
          air_flow_unit
          humidity_control_requested
          relative_humidity_percent
          vent_setting
          additional_instructions
          genset_requested
          controlled_atmosphere_requested
          oxygen_level_percent
          nitrogen_level_percent
          carbon_dioxide_level_percent
          length
          width
          height
          lbh_unit
        }
        container_type
        container_type_code
        is_shipper_owned
        quantity
        quantity_fulfilled
        quantity_unfulfilled
        quantity_picked_up
        quantity_origin_port_gated_in
        quantity_loaded_on_vessel
        quantity_shutout
        quantity_cancelled
        quantity_offloaded
        weight_per_container
        weight_unit
      }
      customer_company {
        id
        company_type
        registered_name
        status
        logo
        company_group
        country_of_incorporation
      }
      customer_address {
        id
        name
        print_address
      }
      sales_person {
        ...salesPersonFields
      }
      pricing_person {
        id
        first_name
        last_name
        name
      }
      origin {
        ... on LocationObjectType {
          id
          name
          type
          city {
            name
          }
          state
          country {
            name
          }
          country_code
          unlocode
        }
        ... on AddressObjectType {
          ...addressFields
        }
      }
      destination {
        ... on LocationObjectType {
          id
          name
          type
          city {
            name
          }
          state
          country {
            name
          }
          country_code
          unlocode
        }
        ... on AddressObjectType {
          ...addressFields
        }
      }
      origin_custom_clearance_location {
        id
        name
        unlocode
      }
      destination_custom_clearance_location {
        id
        name
        unlocode
      }
      incoterms
      description
      routing_remarks
      target_rate
      target_closing_date
      received_at
      cargo_ready_date
      target_delivery_date
      preferred_carriers {
        id
        carrier_type
        iata_awb_prefix
        iata_carrier_code
        name
        standard_alpha_carrier_code
        meta_information
        short_code
      }
      services
      tags
      next_actions
      business_vertical {
        name
        code
        fields
        id
      }
      involved_branch {
        id
        name
      }
      freight_terms
      other_terms
      destination_port_free_days
      origin_carrier_free_days
      destination_carrier_free_days
      inquiry_options {
        id
        quotation_number
        status
        shipment_estimates {
          id
        }
      }
      billing_party {
        id
        registered_name
        status
        logo
        company_group
        country_of_incorporation
      }
      billing_party_address {
        id
        name
        print_address
      }
      place_of_carrier_receipt {
        id
        name
        type
        unlocode
      }
      place_of_carrier_delivery {
        id
        name
        type
        unlocode
      }
      additional_loss_remarks
      business_received_through
      freight_terms
      other_terms
      destination_port_free_days
      origin_carrier_free_days
      destination_carrier_free_days
      teams {
        id
        role
        branch_accounts {
          id
          name
        }
        user_contact {
          id
          first_name
          last_name
          name
        }
        sales_person {
          ...salesPersonFields
        }
        reference_id
        reference_type
      }
    }
  }
  ${salesPersonFields}
  ${addressFields}
`;
