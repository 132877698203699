import { gql } from '@apollo/client';

export const cargoFields = gql`
  fragment cargoFields on ProductOrderItemObjectType {
    id
    commodity {
      id
      name
      dangerous_cargo
      dry_cargo
      live_reefer_cargo
      oog_cargo
    }
    product_name
    product_description
    outer_package_qty
    qty
    allocation_pending_quantity
    gross_weight
    weight_unit
    volume_unit
    parent_id
    parent_type
    cargo_properties {
      emergency_contact_name
      emergency_contact_number
      ems_number
      flash_point
      flash_point_unit
      is_battery
      is_hazardous
      is_perishable
      is_temp_controlled
      is_radioactive
      aircraft_type
      net_weight
      net_weight_unit
      packaging_group
      proper_shipping_name
      technical_name
      un_imo_class
      un_number
      temperature
      temperature_unit
      battery_type
    }
    height
    width
    length
    outer_package_qty
    outer_per_packet_wt
    outer_package_type
    gross_volume
    dimension_unit
    net_weight
    net_weight_unit
    invoice_number
    invoice_date
    batch_number
    custom_ref
    serial_number
    eway_bill_no
    eway_bill_validity
    chargeable_weight
    volumetric_weight
  }
`;

export const containerCargoDetailsFields = gql`
  fragment containerCargoDetailsFields on ContainerCargoDetailObjectType {
    gross_volume
    gross_weight
    id
    net_weight
    package_str
    shipment_container_id
    shipment_id
    total_number_of_packages
    type_of_package
  }
`;
