import Location, { LocationValue } from 'common/models/Location';
import { Instance, getSnapshot, types } from 'mobx-state-tree';
import { ContractCharge, ContractChargeValue } from './ContractCharge';
import Carrier, { CarrierValue } from 'common/models/Carrier';
import { isArray as _isArray } from 'lodash';
import { cloneNode } from '../helper';

export const AdditionalChargeLineItem = types
  .model({
    id: types.identifier,
    charge_name: types.maybeNull(types.string),
    charge_basis: types.maybeNull(types.string),
    charge_terms: types.maybeNull(types.enumeration(['prepaid', 'collect'])),
    locations: types.optional(types.array(Location), []),
    terminals: types.optional(types.array(Location), []),
    currency: types.maybe(types.maybeNull(types.string)),
    remarks: types.maybeNull(types.string),
    contract_charges: types.optional(types.array(ContractCharge), []),
    commodity_type: types.maybeNull(types.string),
    carriers: types.optional(types.array(Carrier), []),
    _destroy: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    setChargeName: (value: string) => (self.charge_name = value),
    setChargeBasis: (value: string) => {
      // Destory all charges, if basis changes between [shipment, gross_weight, ch_wt] <-> container
      if (
        (value !== 'container' && self.charge_basis === 'container') ||
        (value === 'container' && self.charge_basis !== 'container')
      ) {
        const filteredCharges =
          self.contract_charges?.filter((charge) => {
            if (charge.id && charge.id !== 'new') {
              charge.setDestroy(true); // Set _destroy to true
              return true; // Keep the charge in the list
            }
            return false; // Remove the charge from the list
          }) || [];
        self.contract_charges.replace(filteredCharges);
      }
      self.charge_basis = value;
    },
    setChargeTerms: (value: 'prepaid' | 'collect') => (self.charge_terms = value),
    setCurrency: (value: string) => (self.currency = value),
    setFixedCharge: (value: number) => {
      try {
        const charge = self.contract_charges?.find(
          (charge) => !charge.container_type && !charge.isDestroyed()
        );

        if (!charge) {
          const newCharge = ContractCharge.create({
            id: 'new',
            charge_amount: Number(value),
            start_weight: 0,
            end_weight: null,
          });

          self.contract_charges?.push(newCharge);
          return;
        } else {
          charge.setChargeAmount(Number(value));
        }
      } catch (error) {
        console.error(error);
      }
    },
    setRemarks: (value: string) => (self.remarks = value),
    setCommodityType: (value: string) => (self.commodity_type = value),
    addLocation: (value: LocationValue) => {
      try {
        value.id = String(value.id);
        self.locations.push(value);
      } catch (error) {
        console.error(error);
      }
    },
    addContractCharge: (charge: ContractChargeValue) => {
      self.contract_charges.push(charge);
    },
    setChargeByContainerType: (type: string, amount: number) => {
      try {
        const charge = self.contract_charges?.find(
          (charge) => charge.container_type === type && !charge.isDestroyed()
        );
        if (charge) {
          charge.setChargeAmount(Number(amount));
        } else {
          const charge = ContractCharge.create({
            id: 'new',
            container_type: type,
            charge_amount: Number(amount),
            end_weight: null,
          });
          self.contract_charges.push(charge);
        }
      } catch (error) {
        console.error(error);
      }
    },
    setDestroy: (value: boolean) => (self._destroy = value),
    updateCarriers: (value: CarrierValue[]) => {
      try {
        const carriers = value.map((carrier) => ({
          ...carrier,
          id: String(carrier.id),
        }));
        self.carriers?.replace(carriers);
      } catch (error) {
        console.error(error);
      }
    },
    updateTerminals: (value: LocationValue[]) => {
      try {
        const terminals = value.map((terminal) => ({
          ...terminal,
          id: String(terminal.id),
        }));
        self.terminals?.replace(terminals);
      } catch (error) {
        console.error(error);
      }
    },
    updateLocations: (value: LocationValue | LocationValue[]) => {
      try {
        if (!_isArray(value)) value = [value];
        const locations = value
          .filter((location) => !!location)
          .map((location) => ({
            ...location,
            city: cloneNode(location.city),
            country: cloneNode(location.country),
            id: String(location.id),
          }));
        self.locations.replace(locations);
      } catch (error) {
        console.error(error);
      }
    },
    isDestroy: () => self._destroy,
  }))
  .views((self) => ({
    getChargeName: () => self.charge_name,
    getChargeBasis: () => self.charge_basis,
    getChargeTerms: () => self.charge_terms,
    getCurrency: () => self.currency,
    getCommodityType: () => self.commodity_type,
    getCarriers: () => self.carriers,
    getFixedCharge: () => {
      let charge = self.contract_charges?.find(
        (charge) => !charge.container_type && !charge.isDestroyed()
      );

      if (!charge) {
        charge = ContractCharge.create({
          id: 'new',
          charge_amount: null,
          start_weight: 0,
          end_weight: null,
        });
        self.addContractCharge(charge);
        return charge;
      }

      return charge.charge_amount;
    },
    getRemarks: () => self.remarks,
    getAllLocations: () => self.locations,
    getTerminals: () => self.terminals,
    getChargeByContainerType: (type: string) => {
      let charge = self.contract_charges?.find(
        (charge) => charge.container_type === type && !charge.isDestroyed()
      );
      if (!charge) {
        charge = ContractCharge.create({
          id: 'new',
          container_type: type,
          charge_amount: null,
          start_weight: 0,
          end_weight: null,
        });
        self.addContractCharge(charge);
        return charge;
      }

      return charge.charge_amount;
    },
    getPayload: (removeIds = false) => {
      const surcharge = getSnapshot(self);
      const { contract_charges, locations, terminals, carriers, ...rest } = surcharge;
      const contract_charge_payload = self.contract_charges.map((charge) =>
        charge.getPayload(removeIds)
      );
      const payload = {
        ...rest,
        contract_charges: contract_charge_payload,
        location_ids: self.locations.map((location) => location.id),
        terminal_ids: terminals.map((terminal) => terminal.id),
        carrier_ids: carriers?.map((c) => c.id),
        id: self.id === 'new' || removeIds ? null : self.id,
        _destroy: surcharge._destroy || false,
      };
      return payload;
    },
  }));

export type AdditionalChargeLineItemValue = Instance<typeof AdditionalChargeLineItem>;

export default AdditionalChargeLineItem;
