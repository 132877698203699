/**
 * Context To Handle Containeer/Shipment Tracker
 */
import React, { createContext, useContext, useEffect, useState } from 'react';

import { EventType, TrackingReferenceType, EventStateType, WorkflowType } from './types';
import { isEqual, some } from 'lodash';
import { Drawer, Popover } from '@shipmnts/pixel-hub';
import { SessionDataValue } from 'common/models/SessionData';

export type WrapperType = 'DRAWER' | 'POPUP' | 'NO_WRAPPER';

type Props = {
  children: React.ReactNode;
  referenceType: TrackingReferenceType;
  id: string; // id of reference to be trackeed i.e container/shipment
  events?: EventType[];
  editEventId?: string | null; //event to be edited in edit tmode
  session?: SessionDataValue; // current sessssion data
  afterSubmit?: (success?: boolean) => void; //callback after form submission
  onClose: () => void; //on drawer/form close
  editable?: boolean;
  wrapper?: WrapperType; // children to bre rendered in popup or draweer default will be DRAWER
  headerText: string | React.ReactNode; // header of drawer or popup
  isOpen: boolean;
  refData?: any;
  workflow_type?: WorkflowType;
};

type Context = {
  referenceType: TrackingReferenceType;
  id: string; // id of reference to be trackeed i.e container/shipment
  events?: EventType[];
  session?: SessionDataValue; // current sessssion data
  afterSubmit?: (success?: boolean) => void; //callback after form submission
  onClose: () => void; //on drawer/form close
  // Last Event
  // Edit
  editEventId?: string | null; //event to be edited in edit tmode
  editable: boolean;
  getStateOfEvent: (
    eventss: EventType[],
    lastOccuredEvent: EventType | undefined | null
  ) => EventStateType;
  refData?: any; // handle object types
  workflow_type?: WorkflowType;
  wrapper: WrapperType;
};

const TrackerContext = createContext<Context | null>(null);

export const TrackerContextProvider = ({
  children,
  id,
  referenceType,
  events: _events,
  editEventId,
  session,
  afterSubmit,
  onClose,
  editable = false,
  wrapper = 'DRAWER',
  headerText,
  isOpen,
  refData,
  workflow_type = 'main',
}: Props): JSX.Element => {
  // States
  const [events, setEvents] = useState<EventType[]>();

  //get header state of event
  const getStateOfEvent = (events: EventType[], lastOccuredEvent: EventType | undefined | null) => {
    // if last occured event not found
    if (!lastOccuredEvent) {
      return 'future';
    }
    // Finding event group with last occured event
    const isLastEvent = events?.find((o) => isEqual(o, lastOccuredEvent));
    let state: EventStateType = 'future'; //default state
    // if last event found in group status will be active
    if (isLastEvent) {
      state = 'active';
    }
    // else if atleast one event from the group hass actual date then status will bee inacctivee
    else if (some(events, (i) => i.actual_date)) {
      state = 'inactive';
    }
    return state;
  };

  // Effects
  useEffect(() => {
    setEvents(_events);
  }, [_events]);

  return (
    <TrackerContext.Provider
      value={{
        id,
        referenceType,
        events,
        editEventId,
        session,
        afterSubmit,
        onClose,
        editable: editEventId ? true : editable,
        getStateOfEvent,
        refData,
        workflow_type,
        wrapper,
      }}
    >
      {wrapper === 'POPUP' && (
        <Popover
          title={headerText}
          overlayClassName="event-add-edit-modal"
          open={isOpen}
          content={children}
          trigger="click"
          onOpenChange={onClose}
          placement="leftBottom"
          autoAdjustOverflow={true}
          arrow={{ pointAtCenter: true }}
        />
      )}
      {wrapper === 'DRAWER' && (
        <Drawer
          title={headerText}
          placement="right"
          onClose={onClose}
          open={isOpen}
          width={697}
          styles={{
            header: {
              fontSize: '18px',
            },
          }}
          className="tracking-drawer"
        >
          <div className="main-flex">{children}</div>
        </Drawer>
      )}
      {wrapper === 'NO_WRAPPER' && <div className="main-flex">{children}</div>}
    </TrackerContext.Provider>
  );
};

export const useTrackerContext = (): Context => {
  const context = useContext(TrackerContext);

  if (!context) throw new Error('TrackerContext must be called from within the TrackerProvider');

  return context;
};
