/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut, cast, IAnyModelType } from 'mobx-state-tree';
import Location from 'operations/models/Location';
import Carrier from 'operations/models/Carrier';
import { CompanyValue } from 'operations/models/Company';
import ContainerRequest from 'operations/models/ContainerRequest';
import RoutingLeg, { getCarriageWiseRoutingLegs } from 'operations/models/RoutingLeg';
import { getCargoPackagesString } from 'operations/models/Cargo';
import UserAccount from 'operations/models/UserAccount';
import SalesPerson from 'common/models/SalesPerson';
import { CarriageWiseRoutingLegs, hasPrecarriageLeg } from 'operations/models/RoutingLeg';
import { convertValuesToDayJs, DayJsDate as DayJsDateObj, Dayjs } from '@shipmnts/pixel-hub';
import ShipmentContainer, {
  ShipmentContainerValue,
  containersCountByTypeAndSetting,
  validContainers,
} from 'operations/models/ShipmentContainer';
import Shipment from './Shipment';
import {
  BOOKING_TYPE_SELF,
  BOOKING_TYPE_SHIPPING_LINE,
  LOAD_TYPE_FCL,
  LOAD_TYPE_LCL,
} from 'operations/baseConstants';
import { startCase as _startCase } from 'lodash';
import {
  STATUS_REQUESTED,
  STATUS_CONFIRMED,
  STATUS_EXPIRED,
  STATUS_CANCELLED,
  DOCUMENT_STATUS_EXECUTED,
} from 'operations/modules/reports/constants';
import { ShipmentPartyValue } from './ShipmentParty';
import { ShipmentDocumentValue } from './ShipmentDocument';
import Template from 'operations/models/Template';
import Company from 'operations/models/Company';
import Address from 'operations/models/Address';
import ProductOrderItem from './ProductOrderItem';
import BranchAccount from './BranchAccount';

export interface CompanyRoleObject {
  id: string;
  registered_name: string;
  address_id?: string;
  role: string;
}

export interface AmendmentDisableDetail {
  isDisable: boolean;
  disableReason?: string;
  blReleased?: boolean;
  disableFields: string[];
}

const sortRoutingLegs = (
  routing_legs: Instance<typeof RoutingLeg>[]
): Instance<typeof RoutingLeg>[] =>
  (routing_legs || []).slice().sort((a, b) => a.sequence_number - b.sequence_number);

const DayJsDate = types.custom<Dayjs, Dayjs>(DayJsDateObj);

const OceanTransportOrder = types
  .model({
    id: types.identifier,
    status: types.maybe(
      types.union(
        types.literal(STATUS_REQUESTED),
        types.literal(STATUS_CONFIRMED),
        types.literal(STATUS_EXPIRED),
        types.literal(STATUS_CANCELLED)
      )
    ),
    cancellation_reason: types.maybe(types.string),
    booking_number: types.maybe(types.maybeNull(types.string)),
    booking_date: types.maybe(types.maybeNull(DayJsDate)),
    booking_type: types.maybe(types.maybeNull(types.string)),
    cargo_ready_date: types.maybe(types.maybeNull(types.maybeNull(DayJsDate))),
    contract_number: types.maybe(types.string),
    created_at: types.maybe(types.number),
    form13_cutoff_date: types.maybe(types.maybeNull(types.maybeNull(DayJsDate))),
    gate_close_date: types.maybe(types.maybeNull(types.maybeNull(DayJsDate))),
    gate_open_date: types.maybe(types.maybeNull(types.maybeNull(DayJsDate))),
    doc_cutoff_date: types.maybe(types.maybeNull(types.maybeNull(DayJsDate))),
    last_free_date_at_destination: types.maybe(types.maybeNull(types.maybeNull(DayJsDate))),
    last_free_date_at_origin: types.maybe(types.maybeNull(types.maybeNull(DayJsDate))),
    shipping_bill_cutoff_date: types.maybe(types.maybeNull(types.maybeNull(DayJsDate))),
    si_cutoff_date: types.maybe(types.maybeNull(types.maybeNull(DayJsDate))),
    vgm_cutoff_date: types.maybe(types.maybeNull(types.maybeNull(DayJsDate))),
    dg_indexing_date: types.maybe(types.maybeNull(DayJsDate)),
    load_type: types.maybe(types.string),
    stuffing_type: types.maybe(types.string),
    valid_till_date: types.maybe(types.maybeNull(types.maybeNull(DayJsDate))),
    empty_pickup_location: types.maybe(types.maybeNull(types.late((): IAnyModelType => Location))),
    empty_return_location: types.maybe(types.maybeNull(types.late((): IAnyModelType => Location))),
    global_carrier: types.maybe(types.maybeNull(types.late((): IAnyModelType => Carrier))),
    container_requests: types.maybe(types.array(types.late((): IAnyModelType => ContainerRequest))),
    cargos: types.array(types.late((): IAnyModelType => ProductOrderItem)),
    routing_legs: types.array(types.late((): IAnyModelType => RoutingLeg)),
    created_by: types.maybe(types.maybeNull(types.late((): IAnyModelType => UserAccount))),
    is_independent: types.maybe(types.boolean),
    is_amendment_pending: types.maybe(types.boolean),
    house_shipment_ids: types.maybe(types.maybeNull(types.array(types.string))),
    master_shipment_id: types.maybe(types.maybeNull(types.string)),
    shipments: types.maybe(types.array(types.late((): IAnyModelType => Shipment))),
    shipment: types.maybe(types.maybeNull(types.late((): IAnyModelType => Shipment))),
    shipment_containers: types.maybe(
      types.array(types.late((): IAnyModelType => ShipmentContainer))
    ),
    origin_detention_free_days: types.maybe(types.maybeNull(types.number)),
    origin_demurrage_free_days: types.maybe(types.maybeNull(types.number)),
    destination_detention_free_days: types.maybe(types.maybeNull(types.number)),
    destination_demurrage_free_days: types.maybe(types.maybeNull(types.number)),
    voyage_schedule_id: types.maybe(types.maybeNull(types.string)),
    remarks: types.maybe(types.maybeNull(types.string)),
    terms_and_condition_description: types.maybe(types.maybeNull(types.string)),
    terms_and_condition: types.maybe(types.maybeNull(Template)),
    surveyor_company: types.maybe(types.maybeNull(Company)),
    surveyor_address: types.maybe(types.maybeNull(Address)),
    customer_company: types.maybe(types.maybeNull(Company)),
    customer_address: types.maybe(types.maybeNull(Address)),
    vendor_company: types.maybe(types.maybeNull(Company)),
    vendor_address: types.maybe(types.maybeNull(Address)),
    sales_person: types.maybe(types.maybeNull(types.late((): IAnyModelType => SalesPerson))),
    booked_by_branch: types.maybe(types.maybeNull(types.late((): IAnyModelType => BranchAccount))),
    service_type: types.maybe(types.maybeNull(types.string)),
  })
  .views((self) => ({
    get isShippingLineBooking(): boolean {
      return self.booking_type === BOOKING_TYPE_SHIPPING_LINE;
    },
    get carriageWiseRoutingLegs(): CarriageWiseRoutingLegs {
      return getCarriageWiseRoutingLegs(self.routing_legs);
    },
    get vendorName() {
      if (
        self.booking_type === BOOKING_TYPE_SHIPPING_LINE ||
        self.booking_type === BOOKING_TYPE_SELF ||
        self.load_type === LOAD_TYPE_LCL
      )
        return self.global_carrier?.name;
      return self.vendor_company?.registered_name;
    },
    get salesPersonName() {
      return self.sales_person?.name;
    },
    get bookedByBranch() {
      return self.customer_address;
    },
    get createdByName() {
      return (
        _startCase(self.created_by?.first_name) + ' ' + _startCase(self.created_by?.last_name || '')
      );
    },
    get is_unfulfilled() {
      if (!self.container_requests) return false;
      return self.container_requests.reduce((unfulfilled, cr) => {
        return unfulfilled || Boolean(cr.quantity_unfulfilled);
      }, false);
    },
    get isFullyUnallocated() {
      if (!self.shipment) return true;
      return false;
    },
    get totalGrossWeight() {
      return self.cargos.reduce((sum, cargo) => {
        sum += cargo.gross_weight || 0;
        return sum;
      }, 0);
    },
    get totalPackagesString() {
      return getCargoPackagesString(self.cargos);
    },
    get portOfDischarge() {
      return self.routing_legs.filter((rl) => rl.routing_type === 'main_carriage').at(-1)
        ?.destination?.location;
    },
    amendmentDisableDetails(section: string): AmendmentDisableDetail {
      let isAmendmentDisable = false,
        blReleased = false;
      const disableFields: string[] = [];
      const amendmentDisableReason: any[] = [];
      if (
        self.shipment &&
        [
          'overview',
          'routing_details',
          'container_requests',
          'cargos',
          'cutoff_details',
          'other_details',
        ].includes(section)
      ) {
        isAmendmentDisable = true;
        amendmentDisableReason.push('Shipment is linked');
      }
      if (self.status === STATUS_CANCELLED) {
        isAmendmentDisable = true;
        amendmentDisableReason.push('Booking is cancelled');
      } else if (self.status === STATUS_EXPIRED) {
        isAmendmentDisable = true;
        amendmentDisableReason.push('Booking is expired');
      } else if (self.load_type === LOAD_TYPE_LCL) {
        isAmendmentDisable = true;
        amendmentDisableReason.push('LCL vendor booking');
      } else {
        if (section === 'cargos' && self.load_type === LOAD_TYPE_FCL && !this.isFullyUnallocated) {
          isAmendmentDisable = true;
          amendmentDisableReason.push('Vendor booking is linked with customer order');
        }
        const isLinkedWithShipments = self.shipments && self.shipments.length > 0;
        if (['overview', 'cargos'].includes(section) && isLinkedWithShipments) {
          isAmendmentDisable = true;
          amendmentDisableReason.push('Job created');
        }
        blReleased = (self.shipments || []).some((shipment) =>
          (shipment.shipment_documents || []).some(
            (sd: ShipmentDocumentValue) => sd.document_status === DOCUMENT_STATUS_EXECUTED
          )
        );
      }
      return {
        isDisable: isAmendmentDisable,
        disableReason: amendmentDisableReason.join(', '),
        blReleased,
        disableFields,
      };
    },
    isAnyContainerPickedUp(): boolean {
      if (!self.shipment_containers) return false;
      const containers: ShipmentContainerValue[] = self.shipment_containers;
      return containers.some((sc) => Boolean(sc.container_number));
    },
    parentShipmentContainersCountByTypeAndSetting(shipment_id: string | undefined) {
      const parentShipmentContainers: ShipmentContainerValue[] = (
        self.shipment_containers || []
      ).filter((sc) => sc.shipment_id === shipment_id);
      return containersCountByTypeAndSetting(parentShipmentContainers);
    },
    get is_confirmed() {
      return self.status === 'confirmed';
    },
    get isHazardous() {
      return self.cargos.some((cargo: any) => {
        return cargo?.cargo_properties?.is_hazardous;
      });
    },
    getDisabledCutoffDateMapping() {
      const disabled = Boolean(self.voyage_schedule_id && !hasPrecarriageLeg(self.routing_legs));
      return {
        gate_open_date: Boolean(self.voyage_schedule_id),
        gate_close_date: Boolean(self.voyage_schedule_id),
        si_cutoff_date: disabled,
        doc_cutoff_date: disabled,
      };
    },
    getAllCompaniesRolesMapping(default_company: CompanyValue): CompanyRoleObject[] {
      const companies_roles_mapping: CompanyRoleObject[] = [];
      companies_roles_mapping.push({
        id: default_company.id,
        registered_name: default_company.registered_name,
        address_id: self.customer_address?.id,
        role: 'Your Company',
      });

      if (self.shipment) {
        const shipment = self.shipment;
        const customer = shipment?.customer_company;
        if (customer) {
          companies_roles_mapping.push({
            id: customer?.id,
            registered_name: customer?.registered_name,
            address_id: shipment?.customer_address?.id,
            role: 'Customer',
          });
        }
        const shipper = (shipment?.shipment_parties || []).find(
          (party: ShipmentPartyValue) => party.name === 'shipper'
        );
        if (shipper?.party_company)
          companies_roles_mapping.push({
            id: shipper?.party_company?.id,
            registered_name: shipper?.party_company?.registered_name,
            address_id: shipper?.party_address?.id,
            role: 'Shipper',
          });
      }
      return companies_roles_mapping;
    },
  }))
  .actions((self) => ({
    setRoutingLegs(newRoutingLegs: Instance<typeof RoutingLeg>[]) {
      self.routing_legs = cast(sortRoutingLegs(newRoutingLegs));
    },
    setContainerRequests(newContainerRequests?: Instance<typeof ContainerRequest>[]) {
      self.container_requests = cast(newContainerRequests);
    },
    setCargos(newCargos: Instance<typeof ProductOrderItem>[]) {
      self.cargos = cast(newCargos);
    },
    updateShipmentContainers(shipmentContainers?: ShipmentContainerValue[]) {
      const self_containers = self.shipment_containers || [];
      (shipmentContainers || []).forEach((sc) => {
        const index = self_containers.findIndex((c) => c.id === sc.id);
        if (index !== -1) self_containers[index] = { ...self_containers[index], ...sc };
      });
    },
    updateOceanTransportOrder(ocean_transport_orders_fields: OceanTransportOrderValue) {
      self = cast(ocean_transport_orders_fields);
    },
    setBasicDetails(oceanTransportOrder: OceanTransportOrderValue) {
      self.sales_person = oceanTransportOrder.sales_person;
      self.booking_number = oceanTransportOrder.booking_number;
      self.customer_company = oceanTransportOrder.customer_company;
      self.customer_address = oceanTransportOrder.customer_address;
      self.vendor_company = oceanTransportOrder.vendor_company;
      self.vendor_address = oceanTransportOrder.vendor_address;
    },
  }))
  .preProcessSnapshot(({ routing_legs, shipment_containers, ...snapshot }) => ({
    ...snapshot,
    ...convertValuesToDayJs(snapshot, [
      'booking_date',
      'cargo_ready_date',
      'form13_cutoff_date',
      'gate_close_date',
      'gate_open_date',
      'doc_cutoff_date',
      'last_free_date_at_destination',
      'last_free_date_at_origin',
      'shipping_bill_cutoff_date',
      'si_cutoff_date',
      'vgm_cutoff_date',
      'valid_till_date',
      'dg_indexing_date',
    ]),
    routing_legs: sortRoutingLegs(routing_legs as Instance<typeof RoutingLeg>[]),
    shipment_containers: validContainers(
      shipment_containers as Instance<typeof ShipmentContainer>[]
    ),
  }));

export interface OceanTransportOrderValue extends Instance<typeof OceanTransportOrder> {}
export interface OceanTransportOrderIn extends SnapshotIn<typeof OceanTransportOrder> {}
export interface OceanTransportOrderOut extends SnapshotOut<typeof OceanTransportOrder> {}

export default OceanTransportOrder;
