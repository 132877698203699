/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useCallback, useEffect, useMemo } from 'react';

import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Select, Tag, Spin, SelectProps } from '@shipmnts/pixel-hub';
import { debounce as _debounce, omit as _omit, uniqBy as _uniqBy, get as _get } from 'lodash';

import { VesselValue } from 'common/models/Vessel';

export type PartialVesselValue = Omit<VesselValue, 'is_frequent'>;

export interface VesselSearchProps {
  value?: VesselValue | undefined | null | Array<VesselValue>;
  onChange?: (value: PartialVesselValue | Array<PartialVesselValue> | undefined | null) => void;
  selectMode?: 'multiple';
  global_network_only?: boolean;
  disabled?: boolean | undefined;
  selectProps?: SelectProps;
  limit?: number;
}

const { Option } = Select;

const OCEAN_VESSEL_SEARCH = gql`
  query OCEAN_VESSEL_SEARCH($query: String!, $global_network_only: Boolean, $limit: Int) {
    vessels: ocean_vessel_search(
      query: $query
      global_network_only: $global_network_only
      limit: $limit
    ) {
      flag_id
      id
      imo
      mmsi
      name
      vessel_type
      is_frequent
    }
  }
`;

const UPSERT_VESSEL = gql`
  mutation UPSERT_OCEAN_VESSEL($ocean_vessel: OceanVesselInputType) {
    upsert_ocean_vessel(ocean_vessel: $ocean_vessel) {
      imo
    }
  }
`;

interface VesselGraphqlValue extends VesselValue {
  __typename: string;
}

const getSelectValue = (value: VesselValue | Array<VesselValue> | undefined | null) => {
  if (Array.isArray(value)) {
    return value.map(
      (val) =>
        val && {
          value: val.imo || _get(val, 'id') + '',
          label: val.name || _get(val, 'record_details'),
          key: val.imo || _get(val, 'id') + '',
        }
    );
  }
  return value
    ? {
        value: value.imo || _get(value, 'id'),
        label: value.name || _get(value, 'record_details'),
        key: value.imo || _get(value, 'id'),
      }
    : undefined;
};

const getInitState = (value: any): Array<VesselValue> => {
  if (Array.isArray(value))
    return value.map((v) => {
      return {
        id: v.imo || _get(v, 'id') + '',
        name: v.name || _get(v, 'record_details'),
        imo: v.imo || _get(v, 'id') + '',
      } as unknown as VesselValue;
    });
  return [
    {
      id: value.imo || _get(value, 'id') + '',
      name: value.name || _get(value, 'record_details'),
      imo: value.imo || _get(value, 'id') + '',
    } as unknown as VesselValue,
  ];
};

const VesselSearch = React.memo(function VesselSearch(props: VesselSearchProps): JSX.Element {
  const { value, onChange, selectMode, disabled, global_network_only = false, limit = 20 } = props;
  const [vessels, setVessels] = useState<Array<VesselValue>>(
    value ? (Array.isArray(value) ? getInitState(value) : getInitState(value)) : []
  );
  const [selectValue, setSelectValue] = useState(getSelectValue(value));
  const [vesselInternal, setVesselInternal] = useState(value);
  const [getVessels, { loading, data, error }] = useLazyQuery(OCEAN_VESSEL_SEARCH);
  const [upsertVessel] = useMutation(UPSERT_VESSEL);

  const fetchVessels = useMemo(
    () =>
      _debounce(
        (query) =>
          getVessels({
            variables: { query, global_network_only, limit },
          }),
        500
      ),
    [getVessels, global_network_only, limit]
  );

  const onVesselChange = useCallback(
    (changeValue: any, data: any) => {
      let vessel: PartialVesselValue | Array<PartialVesselValue> | undefined = data?.vessel
        ? _omit<VesselGraphqlValue, ['__typename', 'is_frequent']>(
            data.vessel,
            '__typename',
            'is_frequent'
          )
        : undefined;
      let lastSelectedVessel = vessel;
      if (Array.isArray(changeValue)) {
        vessel = changeValue.map((val) => {
          let return_val = null;
          if (Array.isArray(vesselInternal) && vesselInternal.find((v) => v.imo === val.value))
            return_val = vesselInternal.find((v: VesselValue) => v.imo === val.value);
          if (!return_val) {
            return_val = _omit<VesselGraphqlValue, ['__typename', 'is_frequent']>(
              data.find(
                (v: { value: string; vessel?: VesselGraphqlValue }) => v.value === val.value
              )?.vessel,
              '__typename',
              'is_frequent'
            );
            lastSelectedVessel = return_val;
          }
          return return_val;
        });
      }
      if (onChange) onChange(vessel);
      if (lastSelectedVessel && !global_network_only)
        upsertVessel({
          variables: {
            ocean_vessel: _omit(lastSelectedVessel, 'id'),
          },
        });
    },
    [global_network_only, onChange, upsertVessel, vesselInternal]
  );

  useEffect(() => {
    setVesselInternal(value);
    setSelectValue(getSelectValue(value));
  }, [value]);

  useEffect(() => {
    if (data && data.vessels) setVessels(_uniqBy<VesselValue>(data.vessels, 'imo'));
  }, [data]);

  let notFoundContent = undefined;
  if (loading) {
    notFoundContent = <Spin size="small" />;
  } else if (error) {
    notFoundContent = (
      <div className="text-color-secondary">Unable to fetch vessels. Try searching again</div>
    );
  }

  return (
    <Select
      mode={selectMode}
      value={selectValue}
      onChange={onVesselChange}
      placeholder={'Search for vessels...'}
      notFoundContent={notFoundContent}
      filterOption={false}
      showSearch
      onSearch={fetchVessels}
      allowClear
      optionLabelProp="title"
      labelInValue
      popupMatchSelectWidth={450}
      disabled={disabled}
      style={{ width: '100%' }}
      {...props.selectProps}
    >
      {!loading &&
        !error &&
        vessels.map((vessel) => (
          <Option title={vessel.name} key={vessel.imo} value={vessel.imo} vessel={vessel}>
            <div>
              <div>{vessel.name}</div>
              <div>
                <Tag color="blue">IMO: {vessel.imo}</Tag>
                {vessel.flag_id && <Tag color="geekblue">FLAG: {vessel.flag_id}</Tag>}
                {vessel.vessel_type && <Tag color="cyan">{vessel.vessel_type}</Tag>}
                {!global_network_only && vessel.is_frequent && (
                  <Tag color="purple">Frequently used</Tag>
                )}
              </div>
            </div>
          </Option>
        ))}
    </Select>
  );
});

export default VesselSearch;
