/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree';

import Commodity, { CommodityType } from './Commodity';
import { ProductOrderItemValue } from 'operations/models/ProductOrderItem';

export const CargoProperty = types.model({
  is_perishable: types.maybe(types.maybeNull(types.boolean)),
  is_temp_controlled: types.maybe(types.maybeNull(types.boolean)),
  is_hazardous: types.maybe(types.maybeNull(types.boolean)),
  is_battery: types.maybe(types.maybeNull(types.boolean)),
  is_radioactive: types.maybe(types.maybeNull(types.boolean)),
  aircraft_type: types.maybe(types.maybeNull(types.string)),
  un_number: types.maybe(types.maybeNull(types.string)),
  un_imo_class: types.maybe(types.maybeNull(types.string)),
  proper_shipping_name: types.maybe(types.maybeNull(types.string)),
  technical_name: types.maybe(types.maybeNull(types.string)),
  packaging_group: types.maybe(types.maybeNull(types.string)),
  net_weight: types.maybe(types.maybeNull(types.number)),
  net_weight_unit: types.maybe(types.maybeNull(types.string)),
  flash_point: types.maybe(types.maybeNull(types.number)),
  flash_point_unit: types.maybe(types.maybeNull(types.string)),
  ems_number: types.maybe(types.maybeNull(types.string)),
  emergency_contact_name: types.maybe(types.maybeNull(types.string)),
  emergency_contact_number: types.maybe(types.maybeNull(types.string)),
  battery_type: types.maybe(types.maybeNull(types.string)),
});

const Cargo = types.model({
  id: types.maybe(types.identifier),
  _id: types.maybe(types.string),
  commodity: types.maybe(types.maybeNull(Commodity)),
  product_name: types.maybe(types.maybeNull(types.string)),
  // hs_code: types.maybe(types.maybeNull(types.string)),
  outer_package_type: types.maybe(types.maybeNull(types.string)),
  outer_package_qty: types.maybe(types.maybeNull(types.integer)),
  gross_weight: types.maybe(types.maybeNull(types.number)),
  weight_unit: types.maybe(types.maybeNull(types.string)),
  gross_volume: types.maybe(types.maybeNull(types.number)),
  volume_unit: types.maybe(types.maybeNull(types.string)),
  cargo_properties: types.maybe(types.maybeNull(CargoProperty)),
  dimension_unit: types.maybe(types.maybeNull(types.string)),
});

export interface CargoValue extends Instance<typeof Cargo> {}
export interface CargoIn extends SnapshotIn<typeof Cargo> {}
export interface CargoOut extends SnapshotOut<typeof Cargo> {}

export interface CargoPropertyValue extends Instance<typeof CargoProperty> {}
export interface CargoPropertyIn extends SnapshotIn<typeof CargoProperty> {}
export interface CargoPropertyOut extends SnapshotOut<typeof CargoProperty> {}

export default Cargo;

export const isLiveReeferCargo = (cargos: ProductOrderItemValue[]): boolean => {
  const cargoTypes: string[] = [];
  const commodityTypes: Array<CommodityType> = [
    'dry_cargo',
    'live_reefer_cargo',
    'dangerous_cargo',
    'oog_cargo',
  ];
  cargos.forEach((cargo) => {
    commodityTypes.forEach((commodityType: CommodityType) => {
      if (cargo?.commodity?.[commodityType] && !cargoTypes.includes(commodityType)) {
        cargoTypes.push(commodityType);
      }
    });
  });

  if (cargoTypes.length === 1 && cargoTypes[0] === 'live_reefer_cargo') return true;
  return false;
};

export const getCargoPackagesString = (cargos: ProductOrderItemValue[]): string => {
  const packagesHash: { [key: string]: number } = {};
  cargos.forEach((cargo) => {
    if (cargo.outer_package_qty && cargo.outer_package_type) {
      if (!packagesHash[cargo.outer_package_type])
        packagesHash[cargo.outer_package_type] = cargo.outer_package_qty;
      else packagesHash[cargo.outer_package_type] += cargo.outer_package_qty;
    }
  });
  return Object.keys(packagesHash)
    .map((pkgType) => {
      return `${packagesHash[pkgType]} ${pkgType}`;
    })
    .join(', ');
};
