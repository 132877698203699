import React, { useCallback } from 'react';
import { BaseTable, SearchDocTypeEditor, RenderLinkByDocType, dayjs } from '@shipmnts/pixel-hub';
import { compact as _compact } from 'lodash';
import { Column } from 'operations/models/Report';
import { useBillingReportContext } from './WmsBillingReportLayout';

const BillingTable = () => {
  const { reportRows, session } = useBillingReportContext();
  const defaultCurrency = session?.company_account?.default_currency || '';

  const getColumns = useCallback(() => {
    const columnDefs: Column[] = _compact([
      {
        headerName: 'Transaction Date',
        field: 'wms_stock_ledgers_transaction_date',
        colId: 'wms_stock_ledgers_transaction_date',
        columnType: 'Date',
        valueGetter: (params: any) => {
          const date = params?.data?.wms_stock_ledgers_transaction_date;
          return date ? dayjs(date).format('ddd, DD MMM YYYY') : '';
        },
      },
      {
        headerName: 'Transaction #',
        field: 'wms_stock_ledgers_warehouse_transaction_id',
        colId: 'wms_stock_ledgers_warehouse_transaction_id',
        columnType: 'String',
        cellRenderer: (params: any) => {
          if (params.node.isRowPinned()) return <></>;
          return (
            <RenderLinkByDocType
              key={params?.data?.wms_stock_ledgers_warehouse_transaction_id?.id}
              doc_type_id={'Wms::WarehouseTransaction'}
              id={params?.data?.wms_stock_ledgers_warehouse_transaction_id?.id}
              value={params?.data?.wms_stock_ledgers_warehouse_transaction_id?.record_details}
              extra_fields={{}}
              colDef={undefined}
            />
          );
        },
        valueGetter: (params: any) => {
          return params?.data?.wms_stock_ledgers_warehouse_transaction_id?.record_details;
        },
      },
      {
        headerName: 'Receipt #',
        field: 'wms_stock_ledgers_receipt_number',
        colId: 'wms_stock_ledgers_receipt_number',
        columnType: 'String',
      },
      {
        headerName: 'Product Name',
        field: 'wms_stock_ledgers_product_name',
        colId: 'wms_stock_ledgers_product_name',
        cellEditor: 'String',
        editable: false,
        maxWidth: 300,
      },
      {
        headerName: 'Cust. Product Code',
        field: 'wms_stock_ledgers_product_code',
        colId: 'wms_stock_ledgers_product_code',
        columnType: 'String',
        editable: false,
      },
      {
        headerName: 'Qty',
        field: 'wms_stock_ledgers_qty',
        colId: 'wms_stock_ledgers_qty',
        columnType: 'String',
        editable: false,
      },
      {
        headerName: 'Gross Vol',
        field: 'wms_stock_ledgers_gross_volume',
        colId: 'wms_stock_ledgers_gross_volume',
        columnType: 'String',
        editable: false,
      },
      {
        headerName: 'No of Days',
        field: 'wms_stock_ledgers_days_pending',
        colId: 'wms_stock_ledgers_days_pending',
        columnType: 'String',
        editable: false,
      },
      {
        headerName: 'Total Volume',
        field: 'wms_stock_ledgers_total_gross_volume',
        colId: 'wms_stock_ledgers_total_gross_volume',
        columnType: 'String',
        editable: false,
      },
      {
        headerName: 'Storage Amount',
        field: 'wms_stock_ledgers_storage_amount',
        colId: 'wms_stock_ledgers_storage_amount',
        columnType: 'String',
        editable: false,
        cellRenderer: (params: any) => {
          return `${params?.value || 0} ${defaultCurrency}`;
        },
      },
    ]);
    return columnDefs;
    // eslint-disable-next-line
  }, []);
  const allColumnsDefs = getColumns() || [];
  const components = {
    SearchDocTypeEditor,
  };
  return (
    <div style={{ margin: '10px', padding: '0 10px' }}>
      <BaseTable
        reportName="wms_billing_report"
        rowData={reportRows?.data || []}
        height={'70vh'}
        columns={allColumnsDefs}
        reportConfig={{
          components: components,
          pinnedTopRowData: [{ ...reportRows?.opening_balance, hideRowIndex: true }],
          pinnedBottomRowData: [
            { ...reportRows?.total_balance, hideRowIndex: true },
            { ...reportRows?.closing_balance, hideRowIndex: true },
          ],
        }}
      />
    </div>
  );
};

export default BillingTable;
