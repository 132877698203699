import { gql } from '@apollo/client';

export const FETCH_CONTAINER = gql`
  query fetch_container($id: ID!) {
    fetch_basic_container_details_by_id(id: $id) {
      container_number
      tracking_statuses {
        ldb_tracker {
          id
          last_event {
            name
            estimated_date
            current_location
            actual_date
          }
          last_tracked_at
          status
        }
        dockflow_tracker {
          id
          last_event {
            name
            estimated_date
            current_location
            actual_date
          }
          last_tracked_at
          status
        }
      }
      last_action_status
      company_role_list {
        role
        name
        company_id
        address_id
      }
    }
  }
`;

export const FETCH_SHIPMENT = gql`
  query shipment($id: ID!) {
    shipment(id: $id) {
      id
      status
      job_number
      last_action_status
      freight_type
      trade_type
      load_type
      shipment_type
      shipment_booking_number
      port_of_discharge {
        id
        name
        country {
          code
        }
      }
      place_of_carrier_delivery {
        id
        name
        country {
          code
        }
      }
      company_role_list {
        role
        name
        company_id
        address_id
      }
      shipment_parties {
        id
        name
        party_address {
          id
          name
        }
        party_company {
          id
          registered_name
        }
      }
    }
  }
`;

export const FETCH_SHIPMENT_CUSTOM_DETAIL = gql`
  query shipment_custom_detail($id: ID!) {
    shipment_custom_detail(id: $id) {
      id
      last_action_status
      custom_document_number
      custom_document_date
      shipment {
        trade_type
        job_number
        port_of_discharge {
          id
        }
        port_of_loading {
          type
        }
        place_of_carrier_receipt {
          name
        }
        place_of_carrier_delivery {
          country_code
        }
      }
      company_role_list {
        role
        name
        company_id
        address_id
      }
      custom_clearance_location {
        country_code
      }
    }
  }
`;
export const FETCH_INQUIRY_DETAIL = gql`
  query get_inquiry($id: ID!) {
    get_inquiry(id: $id) {
      id
      trade_type
      load_type
      freight_type
      next_actions
      inquiry_number
      movement_mode
      service_type
    }
  }
`;
export const GET_INQUIRY_FROM_GLOBAL_ID = gql`
  query get_inquiry_from_global_id($id: ID!) {
    get_inquiry_from_global_id(id: $id) {
      id
      trade_type
      load_type
      freight_type
      next_actions
      inquiry_number
    }
  }
`;
export const GET_LDB_EVENTS = gql`
  query getLdbEvents($tracking_request_id: ID!) {
    get_ldb_events(tracking_request_id: $tracking_request_id) {
      location
      events {
        latitude
        longitude
        current_location
        actual_date
        name
        estimated_date
      }
    }
  }
`;

// Fetch Event And Milestones
export const FETCH_EVENT_AND_MILESTONES = gql`
  query ($ids: [ID!]!, $reference_type: String!, $workflow_type: String!) {
    fetch_tracking_events(
      ids: $ids
      reference_type: $reference_type
      workflow_type: $workflow_type
    ) {
      id
      name
      last_updated_by
      updated_at
      event_data
      event_type
      workflow_type
      actual_date
      estimated_date
      location_tag
      sequence_number
      reference_type
      reference_id
      location {
        ... on AddressObjectType {
          id
          name
          country_code
          city {
            name
          }
          company {
            id
            registered_name
          }
          print_address
        }
        ... on PortObjectType {
          id
          name
          country_code
          city {
            name
          }
        }
      }
    }
  }
`;

// Update tracking event
export const UPDATE_TRACKING_EVENT = gql`
  mutation update_tracking_event($ids: [ID!]!, $input: TrackingEventInputType) {
    update_tracking_event(ids: $ids, input: $input) {
      id
      name
      last_updated_by
      updated_at
      event_data
      actual_date
      estimated_date
      location_tag
      sequence_number
      location {
        ... on AddressObjectType {
          id
          name
        }
        ... on PortObjectType {
          id
          name
        }
      }
    }
  }
`;

export const getContainerReferenceFieldGraphQL = (refFields: string[]) => {
  return gql`
    query fetch_reference_field_containers($id: ID!) {
      fetch_reference_field_containers(id: $id) {
        id
        ${refFields.join(' ')}
      }
    }
  `;
};

export const getShipmentReferenceFieldGraphQL = (refFields: string[]) => {
  return gql`
    query fetch_reference_field_shipments($id: ID!) {
      fetch_reference_field_shipments(id: $id) {
        id
        ${refFields.join(' ')}
      }
    }
  `;
};

export const FETCH_DOCKFLOW_EVENTS = gql`
  query fetchDockflowEvents($tracking_request_id: ID!) {
    fetch_dockflow_events(tracking_request_id: $tracking_request_id) {
      location
      events {
        name
        current_location
        estimated_date
        actual_date
        latitude
        longitude
      }
    }
  }
`;

export const START_INDIAN_CUSTOMS_TRACKING = gql`
  mutation startTracking($reference_id: ID!, $reference_type: String!) {
    indian_custom_tracker_start_tracking(
      reference_id: $reference_id
      reference_type: $reference_type
    ) {
      id
    }
  }
`;

export const GET_INDIAN_CUSTOMS_TRACKING_REQUEST = gql`
  query IndianCustomsTrackingRequestByCustomDetailID($shipment_custom_detail_id: ID!) {
    indian_customs_tracking_request_by_custom_detail_id(
      shipment_custom_detail_id: $shipment_custom_detail_id
    ) {
      id
      status
      shipment_custom_detail_id
      last_event
      last_tracked_at
    }
  }
`;

export const REFRESH_TRACKING = gql`
  mutation refreshTracking($tracking_request_id: ID!) {
    indian_custom_tracker_refresh_tracking(tracking_request_id: $tracking_request_id) {
      id
      status
      shipment_custom_detail_id
      last_event
      last_tracked_at
    }
  }
`;
