import React, { useContext } from 'react';

interface InquiryOptionDependedContextProps {
  inquiryOptionId: string;
  chargeableWeight?: number;
  inquiryOptionCurrency?: string;
  inquiryOptionExchangeRate?: number;
  resolveMisMatchQty?: boolean;
}
export const InquiryOptionDependedContext = React.createContext<
  Partial<InquiryOptionDependedContextProps>
>({});

export const useInquiryOptionDependedContext = () => useContext(InquiryOptionDependedContext);
