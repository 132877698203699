import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Card, List, message, Modal, Form, dayjsGenerateConfig } from '@shipmnts/pixel-hub';
import { useApolloClient, useMutation } from '@apollo/client';
import {
  omit as _omit,
  range as _range,
  pick as _pick,
  differenceWith as _differenceWith,
  isEqual as _isEqual,
  omit,
} from 'lodash';
import ContainerDetails from 'operations/components/ContainerDetails';
import ShipmentBasicDetails from 'operations/modules/shipment/components/ShipmentBasicDetails';
import ShipmentDocumentDetails from 'operations/modules/shipment/components/ShipmentDocumentDetails';
import OceanLclUtilisationProgress from './OceanLclUtilisationProgress';
import { dayjs, Dayjs } from '@shipmnts/pixel-hub';
import { useSession, usePrevious } from 'common';
import { DrawerFooter } from '@shipmnts/pixel-hub';
import { ShipmentPartyValue, transformPartyDetails } from 'operations/models/ShipmentParty';
import { LocationValue } from 'operations/models/Location';
import { CarrierValue, CarrierMetaInfo } from 'operations/models/Carrier';
import { VesselValue } from 'operations/models/Vessel';
import Shipment, { ShipmentValue } from 'operations/models/Shipment';
import { ContainerSettingValue } from 'operations/models/ContainerRequest';
import { SHIPMENT_TYPE_CONSOL, TRADE_TYPE_IMPORT } from 'operations/modules/shipment/constants';
import {
  BOOKING_TYPE_SHIPPING_LINE,
  BOOKING_TYPE_VENDOR,
  LOAD_TYPE_FCL,
  LOAD_TYPE_LCL,
} from 'operations/baseConstants';
import {
  SHIPPING_LINE_SERVICE_TYPES,
  BOOKING_THROUGH_TYPES,
  FREIGHT_TYPE_OCEAN,
  TRADE_TYPE_EXPORT,
  DOCUMENT_TYPE_MASTER,
  CONSIGNEE_TYPE_ACTUAL_CONSIGNEE,
  BOOKING_THROUGH_OVERSEAS_AGENT,
} from 'operations/modules/shipment/constants';
import {
  shipment_document_initial_value,
  DocumentDetailFormValue,
} from 'operations/modules/reports/components/CreateShipmentFormContent';
import {
  CONSOL_FROM_HOUSES_WARNINGS,
  CREATE_CONSOL_FROM_HOUSES,
} from 'operations/modules/reports/graphql/shipmentReport';
import { getContainerCapacity } from 'operations/modules/reports/helpers/consolHelper';
import { new_cr } from 'operations/components/ContainerDetails';
import { getSalesPersonForCustomer } from 'operations/helpers/fetchSalesPerson';
import { useLocation } from 'wouter';
import { ShipmentServiceRenderType } from 'operations/modules/shipment/components/ShipmentServiceDetails';
import { SalesPersonValue } from 'common/models/SalesPerson';
import BookingRoutingDetailsNew from 'operations/modules/booking/components/BookingOrderForm/BookingRoutingDetailsNew';
import { getRoutingDetailsFormValue } from 'operations/modules/booking/helpers/DuplicateBookingHelper';
import {
  getCargoPayloadWithExistingCargo,
  getRoutingLegsPayload,
} from 'operations/modules/shipment/components/ShipmentForm/helpers';
import { getNewOtoPayload } from '../../helpers/allocationHelper';
import {
  ROUTING_TYPE_MAIN_CARRIAGE,
  ROUTING_TYPE_ON_CARRIAGE,
  ROUTING_TYPE_PRE_CARRIAGE,
  getCarriageWiseRoutingLegs,
} from 'operations/models/RoutingLeg';
import BookingConfirmationDetails from 'operations/modules/shipment/components/NewShipmentForm/BookingConfirmationDetails';
import { getRoutingValues } from 'operations/modules/shipment/components/NewShipmentForm/getInitialValueHelper';
import BookingDetailsShipmentFrom from 'operations/modules/shipment/components/NewShipmentForm/BookingDetailsShipmentForm';
import { SessionDataValue } from 'operations/models/SessionData';
import CargoNewComponent from 'operations/modules/shipment/components/DetailLayout/Cargo/CargoNewComponent/CargoNewComponent';
import { ProductOrderItemValue } from 'operations/models/ProductOrderItem';

interface ConsolShipmentFromHousesFormProps {
  house_shipments: ShipmentValue[];
  consol_type: string;
  port_of_loading: LocationValue;
  onClose: () => void;
  onSuccess?: () => void;
}

export interface ContainerDetailsValue {
  _id?: string;
  container_type: string;
  container_type_code: string;
  quantity: number;
  maximum_cargo_weight: number;
  maximum_cargo_volume: number;
  container_settings?: ContainerSettingValue;
}

export type PartialContainerValue = Omit<ContainerDetailsValue, 'quantity'>;

interface ConsolShipmentFormValue {
  job_date?: Dayjs;
  business_type?: string;
  load_type?: string;
  incoterms?: string;
  customer?: ShipmentPartyValue;
  party?: { [key: string]: ShipmentPartyValue };
  master?: DocumentDetailFormValue;
  carrier?: CarrierValue;
  estimated_time_of_departure?: Dayjs;
  estimated_time_of_arrival?: Dayjs;
  place_of_carrier_receipt?: LocationValue;
  port_of_loading?: LocationValue;
  port_of_discharge?: LocationValue;
  place_of_carrier_delivery?: LocationValue;
  vessel?: VesselValue;
  container_requests?: ContainerDetailsValue[];
  sales_agent?: SalesPersonValue;
  services?: ShipmentServiceRenderType[];
  booking_type?: string;
  trade_type?: string;
}

export interface ContainerMetaInfo {
  maximum_cargo_weight: number;
  maximum_cargo_volume: number;
}

const getCreateConsolShipmentPayload = (
  values: any,
  house_shipment_ids: string[],
  house_shipments: ShipmentValue[],
  sessionData: SessionDataValue
) => {
  const {
    job_date,
    load_type,
    business_type,
    party,
    master,
    customer,
    carrier,
    container_requests,
    vessel,
    sales_agent,
    cargos,
    ...restVal
  } = values;
  const originServices: any = house_shipments[0]?.services?.origin;
  const destinatinoServices: any = house_shipments[0]?.services?.destination;
  const services = {
    ...house_shipments[0]?.services,
    origin: _omit(originServices, '__typename'),
    destination: _omit(destinatinoServices, '__typename'),
  };
  const overseas_agent =
    business_type === BOOKING_THROUGH_OVERSEAS_AGENT
      ? { overseas_agent: master?.party?.destination_agent }
      : {};
  const default_company = sessionData?.company_account?.default_company;
  const shipment_containers = container_requests?.reduce(
    (sc: PartialContainerValue[], cr: ContainerDetailsValue) => {
      const { quantity, _id, ...rest } = cr;
      const containers = _range(quantity).map((number) => rest);
      return sc.concat(containers);
    },
    []
  );
  const ocean_transport_order = getNewOtoPayload(values);
  const carriageWiseRoutingLegs: any = getCarriageWiseRoutingLegs(
    values.routing_details?.routing_legs
  );
  const routingNodes: any = Object.values(values.routing_details?.routing_nodes) || [];
  const main_carriage_routing_legs = carriageWiseRoutingLegs[ROUTING_TYPE_MAIN_CARRIAGE];
  const pre_carriage_routing_legs = carriageWiseRoutingLegs[ROUTING_TYPE_PRE_CARRIAGE] || [];
  const on_carriage_routing_legs = carriageWiseRoutingLegs[ROUTING_TYPE_ON_CARRIAGE] || [];
  const port_of_loading_id = routingNodes.find(
    (r: any) =>
      r.id === main_carriage_routing_legs?.[0]?.origin_id ||
      r._id === main_carriage_routing_legs?.[0]?.origin_id
  ).location?.id;
  const port_of_discharge_id = routingNodes.find(
    (r: any) =>
      r.id ===
        main_carriage_routing_legs?.[main_carriage_routing_legs?.length - 1]?.destination_id ||
      r._id === main_carriage_routing_legs?.[main_carriage_routing_legs?.length - 1]?.destination_id
  ).location?.id;
  const place_of_carrier_receipt_id = routingNodes.find(
    (r: any) =>
      r.id === pre_carriage_routing_legs[0]?.origin_id ||
      r._id === pre_carriage_routing_legs[0]?.origin_id
  ).location?.id;
  const place_of_carrier_delivery_id = routingNodes.find(
    (r: any) =>
      r.id === on_carriage_routing_legs[on_carriage_routing_legs?.length - 1]?.destination_id ||
      r._id === on_carriage_routing_legs[on_carriage_routing_legs?.length - 1]?.destination_id
  ).location?.id;
  const payload = {
    house_shipment_ids,
    shipment: _omit(
      {
        ...restVal,
        ocean_transport_order: ocean_transport_order,
        customer_company_id: customer?.party_company?.id,
        customer_address_id: customer?.party_address?.id,
        load_type,
        business_type,
        job_date: job_date?.format('YYYY-MM-DD'),
        shipment_parties: transformPartyDetails(
          Object.assign({}, master?.party || {}, party, overseas_agent, {
            freight_forwarding_agent: { party_company: default_company },
          })
        ),
        routing_legs: getRoutingLegsPayload(values, null),
        routing_nodes: Object.values(values.routing_details?.routing_nodes || {}).map((rn: any) => {
          const { location, address, company, terminal, id, _id, tags } = rn;
          return {
            id: id || null,
            _id: _id || null,
            location_id: location?.id || null,
            terminal_id: terminal?.id || null,
            address_id: address?.id || null,
            company_id: company?.id || null,
            tags: tags || null,
          };
        }),
        consignee_party_name: master?.consignee_party_name,
        place_of_carrier_receipt_id: place_of_carrier_receipt_id,
        port_of_loading_id: port_of_loading_id,
        port_of_discharge_id: port_of_discharge_id,
        place_of_carrier_delivery_id: place_of_carrier_delivery_id,
        carrier_id: carrier?.id,
        ocean_vessel_id: vessel?.imo,
        ...(load_type !== LOAD_TYPE_LCL ? { shipment_containers } : {}),
        services: _omit(services, '__typename'),
        sales_agent_id: sales_agent?.id,
        voyage_schedule_id: values?.voyage_schedule_id,
        cargos: getCargoPayloadWithExistingCargo(values, undefined),
      },
      [
        'allocation_ocean_transport_order',
        'booking_number',
        'booking_date',
        'service_type',
        'contract_number',
        'routing_details',
        'booking_type',
        'vendor',
        'carrier',
        'surveyor',
        'sales_person',
        'booking_party',
        'si_cutoff_date',
        'empty_pickup_location',
        'empty_return_location',
        'gate_open_date',
        'gate_close_date',
        'valid_till_date',
        'doc_cutoff_date',
        'origin_demurrage_free_days',
        'origin_detention_free_days',
        'destination_demurrage_free_days',
        'destination_detention_free_days',
        'confirmed_booking',
        'terms_and_condition',
        'terms_and_condition_description',
        'stuffing_location',
        'destuffing_location',
        'pickup_location',
        'delivery_location',
        'stuffing_type',
        'destuffing_type',
        'voyage_schedule_id',
      ]
    ),
    shipment_document_master: {
      ..._omit(master?.shipment_document, [
        'origin_agent_same_as_shipper',
        'destination_agent_same_as_consignee',
      ]),
      shipper_on_document: master?.shipment_document?.origin_agent_same_as_shipper
        ? 'origin_agent'
        : 'shipper',
      consignee_on_document: master?.shipment_document?.destination_agent_same_as_consignee
        ? 'destination_agent'
        : 'consignee',
    },
  };

  return payload;
};

const showcolumns = [
  'container_type_code',
  'quantity',
  'maximum_cargo_weight',
  'maximum_cargo_volume',
  'container_settings',
];

const initialContainerDetials = [
  {
    _id: 'cr_0',
    ..._pick(new_cr, showcolumns),
  },
] as unknown as ContainerDetailsValue[];

const ConsolShipmentFromHousesForm = React.memo(function ConsolShipmentFromHousesForm(
  props: ConsolShipmentFromHousesFormProps
): JSX.Element {
  const { consol_type, port_of_loading, house_shipments, onClose, onSuccess } = props;
  const [payload, setPayload] = useState<any>();
  const load_type = house_shipments?.[0]?.load_type ?? undefined;
  const freightType: any = house_shipments?.[0]?.freight_type;
  const tradeType: any = house_shipments?.[0]?.trade_type;
  const routing_details_ref = useRef<{ runValidation: () => boolean }>();
  const [containerCapacity, setContainerCapacity] = useState<ContainerMetaInfo>();
  const [containerDetails, setContainerDetails] =
    useState<ContainerDetailsValue[]>(initialContainerDetials);
  const sessionData = useSession();
  const [
    validateCreateConsol,
    { data: consolFromHousesWarnings, loading: validating, error: validationError },
  ] = useMutation(CONSOL_FROM_HOUSES_WARNINGS);
  const [createConsolFromHouses, { data, loading, error }] = useMutation(CREATE_CONSOL_FROM_HOUSES);
  const { 1: navigate } = useLocation();

  const client = useApolloClient();
  useEffect(() => {
    if (consolFromHousesWarnings?.consol_from_houses_warnings && !validationError) {
      if (consolFromHousesWarnings.consol_from_houses_warnings?.warnings?.length > 0) {
        Modal.confirm({
          title: 'Please confirm before proceeding further',
          content: (
            <List
              size="small"
              style={{ marginTop: '20px' }}
              bordered
              dataSource={consolFromHousesWarnings.consol_from_houses_warnings.warnings}
              renderItem={(item: string) => <List.Item>{item}</List.Item>}
            />
          ),
          onOk: () => createConsolFromHouses({ variables: payload }),
          width: 500,
        });
      } else {
        createConsolFromHouses({ variables: payload });
      }
    }
    // Removing payload from the dependencies because
    // once payload is set and warnings are set , popup appears.
    // now, if someone click cancel and again click onFinish,
    // then setpayload triggers opening of warning popup with old warnings in place ( first popup), and api call triggers new warnings ( 2nd popup )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consolFromHousesWarnings, createConsolFromHouses, validationError]);

  useEffect(() => {
    if (data?.create_consol_from_houses && !error) {
      message.success('Shipment Successfully Created !');
      onClose();
      const shipment_id = data?.create_consol_from_houses?.shipment?.id;
      if (shipment_id) {
        navigate(`~/view/shipment/${shipment_id}/documents`);
      }
      if (onSuccess) onSuccess();
    }
  }, [data, error, onClose, onSuccess, navigate]);

  const [form] = Form.useForm();
  const house_shipment_ids = house_shipments.map((hs) => hs.id);

  const previousContainerDetails = usePrevious(containerDetails);

  const getNewContainerDetails = useCallback(
    (
      currentContainerDetails: ContainerDetailsValue[],
      carrierChanged: boolean,
      carrierMetaInfo?: CarrierMetaInfo | null
    ) => {
      let newContainerDetails = [...currentContainerDetails];
      let containerDetailsUpdated = false;
      if (carrierChanged) {
        newContainerDetails = currentContainerDetails.map((containerDetail) => {
          return {
            ...containerDetail,
            maximum_cargo_weight:
              carrierMetaInfo?.[containerDetail?.container_type_code]?.maximum_cargo_weight || 0,
            maximum_cargo_volume:
              carrierMetaInfo?.[containerDetail?.container_type_code]?.maximum_cargo_volume || 0,
          };
        });
        containerDetailsUpdated = true;
      } else {
        const changedRow = _differenceWith(
          currentContainerDetails,
          previousContainerDetails || [],
          _isEqual
        )?.[0];

        const currentContainerDetail = changedRow;
        const previousContainerDetail = (previousContainerDetails || []).find(
          (containerDetail: ContainerDetailsValue) => containerDetail?._id === changedRow?._id
        );
        let changedField = '';
        if (currentContainerDetail) {
          const keys = Object.keys(currentContainerDetail) as Array<keyof ContainerDetailsValue>;
          keys.forEach((objKey: keyof ContainerDetailsValue) => {
            if (currentContainerDetail?.[objKey] !== previousContainerDetail?.[objKey]) {
              changedField = objKey;
            }
          });
        }

        if (['container_type', 'container_type_code'].includes(changedField)) {
          newContainerDetails = currentContainerDetails.map((containerDetail) => {
            if (containerDetail._id === changedRow._id) {
              return {
                ...containerDetail,
                maximum_cargo_weight:
                  carrierMetaInfo?.[containerDetail?.container_type_code]?.maximum_cargo_weight ||
                  0,
                maximum_cargo_volume:
                  carrierMetaInfo?.[containerDetail?.container_type_code]?.maximum_cargo_volume ||
                  0,
              };
            }
            return containerDetail;
          });
        }
        containerDetailsUpdated = true;
      }
      if (containerDetailsUpdated) form.setFieldsValue({ container_requests: newContainerDetails });
      return newContainerDetails;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [previousContainerDetails]
  );

  useEffect(() => {
    if (house_shipments.length > 0) {
      const shipment = Shipment.create(house_shipments[0]);
      const routingDetails = getRoutingDetailsFormValue(shipment?.routing_legs);
      form.setFieldValue('routing_details', {
        routing_nodes: routingDetails?.routing_nodes,
        routing_legs: (routingDetails?.routing_legs || []).map((rl) => _omit(rl, 'id')),
      });
    }
  }, [house_shipments, form]);

  const formInitialValues = {
    ...getRoutingValues(house_shipments[0]),
    shipment_type: SHIPMENT_TYPE_CONSOL,
    consol_type,
    job_date: dayjsGenerateConfig.getNow(),
    business_type: BOOKING_THROUGH_TYPES[0].key,
    load_type: load_type === LOAD_TYPE_FCL ? LOAD_TYPE_FCL : undefined,
    trade_type: tradeType,
    booking_type:
      tradeType === TRADE_TYPE_IMPORT ? BOOKING_TYPE_VENDOR : BOOKING_TYPE_SHIPPING_LINE,
    service_type: SHIPPING_LINE_SERVICE_TYPES[0].key,
    shipping_line_service_type: SHIPPING_LINE_SERVICE_TYPES[0].key,
    valid_till_date: dayjs().add(3, 'day'),
    booking_date: dayjs(),
    master: {
      shipment_document: {
        ...shipment_document_initial_value.shipment_document,
        origin_agent_same_as_shipper: true,
        destination_agent_same_as_consignee: true,
      },
      consignee_party_name: CONSIGNEE_TYPE_ACTUAL_CONSIGNEE.key,
      party: {
        origin_agent: {
          party_company: sessionData.company_account.default_company,
          party_address: house_shipments[0]?.involved_branch?.default_address,
        },
      },
    },
    booking_party: {
      party_company: sessionData.company_account.default_company,
    },
    port_of_loading,
    place_of_carrier_receipt: port_of_loading,
    services: house_shipments[0]?.services,
    freight_type: freightType,
    cargos: house_shipments
      ?.map((hs) => hs?.cargos)
      ?.flat()
      .map((cargo) => ({ ...cargo, id: undefined })),
  };

  const cargoRef = useRef<{
    runValidation: () => boolean;
    getPayload: () => ProductOrderItemValue[];
  }>();
  return (
    <Form
      form={form}
      name="create_master_shipment"
      layout="vertical"
      initialValues={formInitialValues}
      onValuesChange={async (changedValues, allValues) => {
        let formValues: ConsolShipmentFormValue = {};

        if (changedValues.hasOwnProperty('port_of_discharge')) {
          formValues = {
            place_of_carrier_delivery: changedValues.port_of_discharge,
          };
        }
        if (
          (changedValues.hasOwnProperty('carrier') ||
            changedValues.hasOwnProperty('container_requests')) &&
          allValues.container_requests &&
          allValues.load_type !== LOAD_TYPE_LCL
        ) {
          const newContainerDetails = getNewContainerDetails(
            allValues.container_requests,
            changedValues.hasOwnProperty('carrier'),
            allValues?.carrier?.meta_information
          );
          const containerCapacityNew = getContainerCapacity(newContainerDetails);
          setContainerCapacity(containerCapacityNew);
        }

        if (changedValues.hasOwnProperty('container_requests')) {
          setContainerDetails([...(changedValues.container_requests || [])]);
        }
        if (changedValues.hasOwnProperty('customer')) {
          const branch_id = house_shipments[0].involved_branch_id;
          const customer = changedValues.customer;
          if (branch_id && customer) {
            const { response } = await getSalesPersonForCustomer(
              customer.id,
              branch_id,
              sessionData,
              client
            );
            if (response) {
              formValues['sales_agent'] = response;
            }
          }
        }
        if (changedValues.hasOwnProperty('load_type')) {
          if (allValues?.trade_type !== TRADE_TYPE_IMPORT) {
            if (changedValues.load_type === LOAD_TYPE_FCL) {
              formValues['booking_type'] = BOOKING_TYPE_SHIPPING_LINE;
            } else if (changedValues.load_type === LOAD_TYPE_LCL) {
              formValues['booking_type'] = BOOKING_TYPE_VENDOR;
            }
          }
        }
        if (Object.keys(formValues).length > 0) form.setFieldsValue(formValues);
      }}
      onFinish={(values: ConsolShipmentFormValue) => {
        const error = Boolean(cargoRef?.current?.runValidation());
        if (error) return;
        const payload = getCreateConsolShipmentPayload(
          { ...omit(values, ['all_cargo_properties']), cargos: cargoRef?.current?.getPayload() },
          house_shipment_ids,
          house_shipments,
          sessionData
        );
        setPayload(payload);
        validateCreateConsol({
          variables: {
            house_shipment_ids,
            shipment: payload?.shipment,
          },
        });
      }}
    >
      <Form.Item noStyle name="shipment_type">
        <span />
      </Form.Item>
      <Form.Item noStyle name="freight_type">
        <span />
      </Form.Item>
      <Form.Item noStyle name="consol_type">
        <span />
      </Form.Item>
      <Form.Item noStyle name="trade_type">
        <span />
      </Form.Item>
      <Form.Item noStyle name="services">
        <span />
      </Form.Item>
      {load_type !== LOAD_TYPE_FCL && (
        <Form.Item noStyle dependencies={['load_type']}>
          {({ getFieldValue }) => {
            const load_type = getFieldValue('load_type');
            if (load_type === LOAD_TYPE_LCL) return null;
            return (
              <OceanLclUtilisationProgress
                houseShipments={house_shipments}
                maximumVolume={containerCapacity?.maximum_cargo_volume || 0}
                maximumWeight={containerCapacity?.maximum_cargo_weight || 0}
              />
            );
          }}
        </Form.Item>
      )}
      <Card title="Basic Details" className="custom-card margin-top-md">
        <ShipmentBasicDetails
          shipmentType={SHIPMENT_TYPE_CONSOL}
          disableLoadType={load_type === LOAD_TYPE_FCL}
          form={form}
          hideConsolType
          hideServiceType
        />
      </Card>
      <Card title="Cargo Details" className="custom-card margin-top-md">
        <CargoNewComponent form={form} ref={cargoRef} />
      </Card>
      {load_type !== LOAD_TYPE_FCL && (
        <Form.Item noStyle dependencies={['load_type']}>
          {({ getFieldValue }) => {
            const load_type = getFieldValue('load_type');
            if (load_type === LOAD_TYPE_LCL) return null;
            return (
              <Card title="Container Details" className="custom-card margin-top-md">
                <Form.Item name="container_requests" noStyle>
                  <ContainerDetails showcolumns={showcolumns} />
                </Form.Item>
              </Card>
            );
          }}
        </Form.Item>
      )}
      <Card title="Booking Details" className="custom-card margin-top-md">
        <BookingDetailsShipmentFrom form={form} />
      </Card>

      <Card title="Routing Details" className="custom-card margin-top-md">
        <Form.Item name="routing_details" noStyle>
          <BookingRoutingDetailsNew
            form={form}
            ref={routing_details_ref}
            freightType={freightType}
            loadType={load_type || 'fcl'}
            allowVoyageScheduleSearch={true}
            showPolBufferSection={tradeType === TRADE_TYPE_EXPORT}
            showStuffingSection={tradeType === TRADE_TYPE_EXPORT}
            showDeStuffingSection={tradeType === TRADE_TYPE_IMPORT}
            showPodBufferSection={tradeType === TRADE_TYPE_IMPORT}
          />
        </Form.Item>
      </Card>

      <Card title="Shipment Cutoff Details" className="custom-card margin-top-md">
        <BookingConfirmationDetails form={form} />
      </Card>
      <Card
        title="MBL Instructions"
        className="custom-card margin-top-md"
        style={{ marginBottom: '44px' }}
      >
        <ShipmentDocumentDetails
          freightType={FREIGHT_TYPE_OCEAN}
          tradeType={TRADE_TYPE_EXPORT}
          shipmentType={SHIPMENT_TYPE_CONSOL}
          documentType={DOCUMENT_TYPE_MASTER}
          form={form}
        />
      </Card>
      <div
        style={{
          position: 'absolute',
          bottom: '22px',
          width: '98%',
          borderTop: '1px solid #e8e8e8',
          textAlign: 'right',
          background: '#fff',
          padding: '7px',
          margin: '-22px -20px',
        }}
      >
        <DrawerFooter
          saveText="Create Consol Shipment"
          loading={loading || validating}
          onClose={onClose}
        />
      </div>
    </Form>
  );
});

export default ConsolShipmentFromHousesForm;
