import React from 'react';

import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  GlobalSearch,
  InputNumber,
  Row,
} from '@shipmnts/pixel-hub';
import { useBillingReportContext } from './WmsBillingReportLayout';
import { transformFormData } from './BillingFilterHelpers';

const BillingFilter = () => {
  const { form, session, fetchData } = useBillingReportContext();
  const defaultCurrency = session?.company_account?.default_currency;

  return (
    <div
      style={{
        width: '100%',
        padding: '10px 24px',
      }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(values: any) => {
          const payload = transformFormData(values);
          fetchData && fetchData(payload);
        }}
      >
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item
              name="customer"
              label="Customer"
              rules={[{ required: true }]}
              style={{ marginBottom: '8px' }}
            >
              <GlobalSearch doc_type="Network::Company" />
            </Form.Item>
            <Form.Item noStyle={true} name="pro_rata" valuePropName="checked">
              <Checkbox>Pro Rata</Checkbox>
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              name="warehouse"
              label="Warehouse"
              rules={[{ required: true }]}
              style={{ marginBottom: '8px' }}
            >
              <GlobalSearch
                doc_type="Wms::WarehouseUnit"
                extraProps={{
                  type: 'warehouse',
                  branch_ids: (session?.branch_accounts || []).map((obj: any) => obj.id),
                }}
              />
            </Form.Item>
            <Form.Item noStyle={true} name="include_delivery_day" valuePropName="checked">
              <Checkbox>Include Delivery Day</Checkbox>
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              name="transaction_date"
              label={'Transaction Date'}
              rules={[{ required: true }]}
              style={{ marginBottom: '8px' }}
            >
              <DatePicker.RangePicker
                mode={['date', 'date']}
                placeholder={[`From`, `To`]}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              name="rates"
              label={`Rates/Day/CBM ${defaultCurrency ? `(${defaultCurrency})` : ''}`}
              rules={[{ required: true }]}
              style={{ marginBottom: '8px' }}
            >
              <InputNumber
                min={0}
                precision={3}
                step={0.001}
                placeholder={'Rates'}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default BillingFilter;
