import React, { useRef, useState, useEffect, useCallback } from 'react';
import { gql, useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import {
  Card,
  Col,
  Input,
  message,
  Row,
  Select,
  Form,
  FormInstance,
  dayjsGenerateConfig,
  DatePicker,
  GlobalSearch,
  DrawerFooter,
} from '@shipmnts/pixel-hub';
import { startCase as _startCase, omit as _omit, pick as _pick, get } from 'lodash';
import { Dayjs } from '@shipmnts/pixel-hub';
import { CarrierType, CarrierValue } from 'operations/models/Carrier';
import { LocationValue } from 'operations/models/Location';
import { RoutingDetailsValue } from 'operations/components/RoutingDetails/RoutingDetails';
// import { SalesPersonValue } from 'common';
import { ContainerReportData } from 'operations/modules/reports/reportModels';
import { getMainHopEtd, hasPrecarriageLeg, RoutingLegValue } from 'operations/models/RoutingLeg';
import ContainerDetails from 'operations/components/ContainerDetails';
import BookingOrderConfirmation from 'operations/modules/booking/components/BookingOrderForm/BookingOrderConfirmation';
import ShiftingContainersListView from 'operations/modules/booking/components/MergeSplitBooking/ShiftingContainersListView';
import {
  BOOKING_TYPE_SHIPPING_LINE,
  containerSettingsMap,
  LOAD_TYPE_FCL,
  LOAD_TYPE_LCL,
} from 'operations/baseConstants';
import { ContainerRequestValue } from 'operations/models/ContainerRequest';
import {
  getContainerRequestsForSplitContainers,
  getRoutingDetailsFormValue,
} from 'operations/modules/booking/helpers/DuplicateBookingHelper';
import { getOceanTransportOrderPayload } from 'operations/modules/booking/components/BookingOrderForm/BookingOrderForm';
import { SPLIT_OTO_FOR_ROLLOVER_SHUTOUT } from 'operations/modules/booking/graphql/oceanTransportOrder';
import {
  ROLLOVER_BY_REASONS,
  SHUTOUT_BY,
  ACTION_ROLLOVER_UPDATE_BOOKING,
} from 'operations/modules/reports/constants';
import { getDisbaledCutoffDateMapping } from 'operations/modules/booking/helpers/CutoffDateHelper';
import {
  getRoutingDetailsOnVoyageReset,
  checkVoyageResetOnValuesChange,
  getDefaultPreviousValue,
} from 'operations/modules/booking/helpers/RoutingDetailsHelper';
import { fetchSplitUpdateOTOWarnings } from 'operations/modules/reports/helpers/containerActionsHelper';
import { PartyValue } from '../reports/components/CreateShipmentFormContent';
import { EVENT_ROUTING_UPDATED } from '../actionHelper/constants';
import { SalesPersonValue } from 'common/models/SalesPerson';
import { ShipmentValue } from 'operations/models/Shipment';
import { getNewOtoPayload } from '../reports/helpers/allocationHelper';
import { UPDATE_ROUTING_DETAILS } from 'operations/graphql/shipment';
import ValidateShipmentCreation from '../shipment/components/ValidateShipmentCreation';
import { GridOptions } from '@ag-grid-community/core';
import BookingOrderNewDesign from '../booking/components/BookingOrderForm/BookingOrderNewDesign';
import { ROW_GUTTER } from '../shipment/constants';
import BookingRoutingDetailsNew from '../booking/components/BookingOrderForm/BookingRoutingDetailsNew';
import { getRoutingValues } from '../shipment/components/NewShipmentForm/getInitialValueHelper';

// Fetch Event And Milestones
export const FETCH_EVENT_AND_MILESTONES = gql`
  query ($ids: [ID!]!, $reference_type: String!, $workflow_type: String!) {
    fetch_tracking_events(
      ids: $ids
      reference_type: $reference_type
      workflow_type: $workflow_type
    ) {
      id
      name
      last_updated_by
      updated_at
      event_data
      workflow_type
      actual_date
      estimated_date
      location_tag
      sequence_number
      reference_type
      reference_id
      location {
        ... on AddressObjectType {
          id
          name
          country_code
          city {
            name
          }
          print_address
        }
        ... on PortObjectType {
          id
          name
          country_code
          city {
            name
          }
        }
      }
    }
  }
`;

export interface SplitBookingFormValue {
  id?: string;
  carrier?: CarrierValue;
  vendor?: PartyValue;
  customer?: PartyValue;
  sales_person?: SalesPersonValue;
  booking_number?: string;
  booking_date?: Dayjs;
  contract_number?: string;
  container_requests?: Record<string, number>;
  routing_details?: RoutingDetailsValue;
  empty_pickup_location?: LocationValue;
  confirmed_booking?: boolean;
  valid_till_date?: Dayjs;
  gate_open_date?: Dayjs;
  si_cutoff_date?: Dayjs;
  gate_close_date?: Dayjs;
  doc_cutoff_date?: Dayjs;
  origin_detention_free_days?: number;
  origin_demurrage_free_days?: number;
  destination_detention_free_days?: number;
  destination_demurrage_free_days?: number;
  voyage_schedule_id?: string;
  rollover_at?: Dayjs;
  rollover_by?: string;
  rollover_reason?: string;
  cancelled_by?: string;
  cancelled_reason?: string;
}

interface SplitAndCreateBookingProps {
  ocean_transport_order?: any;
  containers?: ContainerReportData[];
  rolloverOrShutout?: 'rollover';
  onClose?: () => void;
  onSuccess?: (sendEmail?: boolean | undefined, rs?: string[]) => void;
  splitFromShipment?: boolean;
  shipment?: ShipmentValue;
  onlyUpdateRouting?: boolean;
  setLoading: (loading: boolean) => void;
  sendEmail?: boolean;
  form: FormInstance<any>;
  showFooter?: boolean;
}

const getSplitAndCreateNewPayload = (
  values: SplitBookingFormValue,
  shipment_container_ids: string[],
  rolloverOrShutout?: 'rollover'
) => {
  const rolloverShutoutFields = [
    `rollover_at`,
    `rollover_by`,
    `rollover_reason`,
    `cancelled_by`,
    `cancelled_reason`,
  ];
  const ocean_transport_order = getOceanTransportOrderPayload(_omit(values, rolloverShutoutFields));
  const rollover_shutout_payload = _pick(values, rolloverShutoutFields);
  const payload = {
    ocean_transport_order: rolloverOrShutout
      ? _omit(ocean_transport_order, ['container_requests'])
      : ocean_transport_order,
    shipment_container_ids,
    action: ACTION_ROLLOVER_UPDATE_BOOKING,
    action_at: !!rolloverOrShutout && rollover_shutout_payload['rollover_at']?.unix(),
    action_by: !!rolloverOrShutout && rollover_shutout_payload['rollover_by'],
    action_reason: !!rolloverOrShutout && rollover_shutout_payload['rollover_reason'],
  };
  return payload;
};

const SplitAndCreateBooking = React.memo(function SplitAndCreateBooking(
  props: SplitAndCreateBookingProps
): JSX.Element {
  const [isMergeSplitModalOpen, setIsMergeSplitModalOpen] = useState(false);
  const [allowUpdateRouting, setAllowUpdateRouting] = useState(false);
  const [selectedBookingId, setSelectedBookingId] = useState<string | undefined>();

  const gridRefForBooking = useRef<GridOptions>();

  const {
    onClose,
    onSuccess,
    shipment,
    splitFromShipment = false,
    onlyUpdateRouting = false,
    sendEmail,
    setLoading,
    form,
    showFooter = true,
  } = props;
  const source_ocean_transport_order =
    props.ocean_transport_order || window.history?.state?.ocean_transport_order;
  const rolloverOrShutout = props.rolloverOrShutout || window.history?.state?.rolloverOrShutout;

  const formHiddenFields = [
    'booking_type',
    'load_type',
    'booking_date',
    'sales_person',
    'customer',
  ];

  if (!rolloverOrShutout) {
    formHiddenFields.push('carrier');
  }

  const containers = (props.containers || window.history?.state?.containers || []).map(
    (container: any) => ({
      ...container,
      global_carrier:
        source_ocean_transport_order?.global_carrier || source_ocean_transport_order?.carrier,
      booking_cargos: source_ocean_transport_order?.cargos,
      port_of_discharge:
        source_ocean_transport_order?.portOfDischarge ||
        source_ocean_transport_order?.port_of_discharge,
    })
  );
  const routing_details_ref = useRef<{ runValidation: () => boolean }>();
  const [previousValue, setPreviousValue] = useState<{
    isFirstContainerTypeReefer?: boolean;
    mainCarriage?: {
      locationId?: string;
      vessel?: string;
    };
    carrierId?: string;
  }>(getDefaultPreviousValue(source_ocean_transport_order));
  const client = useApolloClient();

  const [
    splitForRolloverShoutout,
    {
      data: splitForRolloverShoutoutData,
      loading: splitForRolloverShoutoutLoading,
      error: splitForRolloverShoutoutError,
    },
  ] = useMutation(SPLIT_OTO_FOR_ROLLOVER_SHUTOUT);
  const [fetchEvents, { data, loading }] = useLazyQuery(FETCH_EVENT_AND_MILESTONES);

  const [
    updateRouting,
    { data: updateRoutingData, loading: updateRoutingLoading, error: updateRoutingError },
  ] = useMutation(UPDATE_ROUTING_DETAILS);

  const handleFetchEvent = useCallback(() => {
    const ids = containers.map((c: any) => c.id);
    fetchEvents({
      variables: {
        ids: ids,
        reference_type: 'Shipment::ShipmentContainer',
        workflow_type: 'main',
      },
    });
  }, [containers, fetchEvents]);

  useEffect(() => {
    if (splitForRolloverShoutoutError) {
      message.error(splitForRolloverShoutoutError.message);
      setAllowUpdateRouting(false);
    }
    if (splitForRolloverShoutoutData?.split_oto_for_rollover_shutout) {
      message.success('Container(s) updated successfully.');
      if (onSuccess) {
        handleFetchEvent();
      }
    }
  }, [
    splitForRolloverShoutoutData,
    containers,
    fetchEvents,
    splitForRolloverShoutoutError,
    onSuccess,
    handleFetchEvent,
  ]);

  useEffect(() => {
    if (updateRoutingError) {
      message.error(updateRoutingData.message);
      setAllowUpdateRouting(false);
    }
    if (updateRoutingData?.update_routing) {
      message.success('Container(s) updated successfully.');
      window.open(`/view/shipment/${updateRoutingData?.update_routing?.new_shipment_id}`, '_blank');
      if (onSuccess) {
        handleFetchEvent();
      } else onClose && onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateRoutingData, updateRoutingError]);

  useEffect(() => {
    if (onSuccess && !loading && data) {
      const events = get(data, 'fetch_tracking_events', []);
      const rollback_events = events
        .filter((e: any) => e.name === EVENT_ROUTING_UPDATED)
        .sort(
          (a: { sequence_number: number }, b: { sequence_number: number }) =>
            b.sequence_number - a.sequence_number
        );
      onSuccess(
        sendEmail,
        rollback_events.map((c: any) => c.id)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (allowUpdateRouting) {
      const values = form.getFieldsValue();
      const otoPayload = getNewOtoPayload(values) || {};

      const routing_legs = (values?.routing_details?.routing_legs || [])
        .map((rl: RoutingLegValue) => {
          const {
            vessel,
            estimated_time_of_departure,
            estimated_time_of_arrival,
            global_carrier,
            ...restRL
          } = rl;
          return {
            ...restRL,
            global_carrier_id: global_carrier?.id,
            vessel_id: vessel?.imo,
            estimated_time_of_departure: estimated_time_of_departure?.unix(),
            estimated_time_of_arrival: estimated_time_of_arrival?.unix(),
          };
        })
        .map((rl: RoutingLegValue) => _omit(rl, ['id']));
      const routing_nodes = Object.values(values?.routing_details?.routing_nodes || {})
        .map((rn: any) => {
          const { location, company, address, terminal, ...restRN } = rn;
          return { ...restRN, location_id: location?.id, terminal_id: terminal?.id };
        })
        .map((rn: any) => {
          const new_rn = { ...rn };
          new_rn['_id'] = rn._id || rn.id;
          return _omit(new_rn, ['id', 'terminal', 'address']);
        });

      otoPayload['routing_nodes'] = routing_nodes;
      otoPayload['routing_legs'] = routing_legs;
      otoPayload['cargos'] = (values.cargos || []).map((cargo: any) => {
        return {
          ..._pick(cargo, [
            '_destroy',
            'linked_to_id',
            'cargo_properties',
            'commodity_description',
            'package_type',
            'total_packages',
            'gross_weight',
            'gross_volume',
            'volume_unit',
            'weight_unit',
            'net_weight',
            'invoice_number',
            'invoice_date',
            'batch_number',
            'custom_ref',
            'serial_number',
          ]),
          commodity_id: cargo?.commodity?.id,
        };
      });
      const containersByOtoId: { oto_id: string; container_ids: string[] }[] = [];

      containers.forEach((container: any) => {
        const otoId = container?.ocean_transport_order_ids?.[0];

        if (otoId) {
          const existingGroupIndex = containersByOtoId.findIndex((group) => group.oto_id === otoId);

          if (existingGroupIndex !== -1) {
            containersByOtoId[existingGroupIndex].container_ids.push(container.id);
          } else {
            containersByOtoId.push({
              oto_id: otoId,
              container_ids: [container.id],
            });
          }
        }
      });

      const selectedContainers = containersByOtoId.map((group: any) => ({
        ocean_transport_order_id: group.oto_id,
        shipment_container_ids: group.container_ids,
      }));

      const reason_for_cancellation = values.reason_for_cancellation;
      const updated_by_reason = reason_for_cancellation.split('----');
      const routing_updated_at = values.routing_updated_at.unix();

      updateRouting({
        variables: {
          oto_args: otoPayload,
          old_shipment_id: shipment?.id,
          selected_containers: !onlyUpdateRouting ? selectedContainers : [],
          selected_oto_id: !onlyUpdateRouting ? selectedBookingId || null : undefined,
          only_update_routing: onlyUpdateRouting,
          routing_update: {
            routing_updated_at: routing_updated_at,
            routing_updated_by: updated_by_reason[0],
            routing_updated_reason: updated_by_reason[1],
            shipment_container_ids: containers.map((container: any) => container.id), //just sending as it is required in the mutation
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowUpdateRouting]);

  const resetMainHopVoyageNumber = useCallback(
    (routing_details: RoutingDetailsValue, voyage_schedule_id?: string) => {
      form.setFieldsValue({
        routing_details,
        voyage_schedule_id,
      });
    },
    [form]
  );

  const field_prefix = 'rollover';
  const reasons = ROLLOVER_BY_REASONS;

  const routingDetails = getRoutingDetailsFormValue(source_ocean_transport_order?.routing_legs);
  const routing_nodes = routingDetails?.routing_nodes;
  const routing_legs = (routingDetails?.routing_legs || []).map((rl) => _omit(rl, 'id'));

  const loadType = source_ocean_transport_order?.load_type;
  const bookingType = source_ocean_transport_order?.booking_type;
  const carrier_types: CarrierType[] = ['ocean', 'nvocc'];
  if (loadType === LOAD_TYPE_LCL) carrier_types.push('coloader');

  return (
    <>
      {isMergeSplitModalOpen && (
        <ValidateShipmentCreation
          shipment={shipment}
          selectedContainers={containers as any}
          onClose={() => setIsMergeSplitModalOpen(false)}
          handleSubmit={() => setAllowUpdateRouting(true)}
          setSelectedBookingId={setSelectedBookingId}
          gridRef={gridRefForBooking}
        />
      )}
      <Form
        form={form}
        onFinish={async (values: any) => {
          if (splitFromShipment) {
            if (onlyUpdateRouting) {
              setAllowUpdateRouting(true);
            } else {
              setIsMergeSplitModalOpen(true);
            }
          } else {
            const reason_for_cancellation = values.reason_for_cancellation;
            const updated_by_reason = reason_for_cancellation.split('----');
            const routing_updated_at = values.routing_updated_at.unix();

            // setting id of sales_person in payload.
            const sales_person = values.sales_person;
            values.sales_person_id = sales_person?.id;

            values = _omit(values, ['reason_for_cancellation', 'routing_updated_at']);
            values.container_requests = values?.container_requests
              ? getContainerRequestsForSplitContainers(values.container_requests, containers)
              : [];

            const payload = getSplitAndCreateNewPayload(
              values,
              containers.map((c: any) => c.id),
              rolloverOrShutout
            );
            const otoPayload = getOceanTransportOrderPayload(values);
            const routing_nodes = otoPayload.routing_nodes.map((rn: any) => {
              const new_rn = { ...rn };
              new_rn['_id'] = rn._id || rn.id;
              return _omit(new_rn, ['id', 'terminal', 'address']);
            });
            const routing_legs = otoPayload.routing_legs.map((rl: RoutingLegValue) =>
              _omit(rl, ['id'])
            );
            otoPayload['routing_nodes'] = routing_nodes;
            otoPayload['routing_legs'] = routing_legs;
            otoPayload['cargos'] = otoPayload?.cargos?.map((cargo: any) => {
              delete cargo.id;
              delete cargo.allocation_pending_quantity;
              return cargo;
            });
            await fetchSplitUpdateOTOWarnings(
              client,
              {
                ocean_transport_order: _omit(payload.ocean_transport_order, [
                  'cancelled_by',
                  'cancelled_reason',
                ]),
                action: 'split_booking',
                shipment_container_ids: payload.shipment_container_ids,
              },
              setLoading,
              () => {
                splitForRolloverShoutout({
                  variables: {
                    ocean_transport_order: {
                      ...otoPayload,
                      container_requests: source_ocean_transport_order?.container_requests.map(
                        (container_request: ContainerRequestValue) =>
                          _omit(container_request, [
                            '_id',
                            'quantity_origin_port_gated_in',
                            'quantity_unfulfilled',
                            'quantity_fulfilled',
                            'quantity_picked_up',
                            'quantity_loaded_on_vessel',
                            'quantity_offloaded',
                            'quantity_shutout',
                            'quantity_cancelled',
                          ])
                      ),
                    },
                    routing_update: {
                      routing_updated_at: routing_updated_at,
                      routing_updated_by: updated_by_reason[0],
                      routing_updated_reason: updated_by_reason[1],
                      shipment_container_ids: containers.map((container: any) => container.id),
                    },
                  },
                });
              }
            );
          }
        }}
        name="booking_order"
        layout="vertical"
        style={{
          height: '100%',
        }}
        initialValues={{
          ...source_ocean_transport_order,
          ...getRoutingValues(undefined, source_ocean_transport_order),
          booking_date: dayjsGenerateConfig.getNow(),
          routing_updated_at: dayjsGenerateConfig.getNow(),
          routing_details: { routing_legs, routing_nodes },
          carrier: source_ocean_transport_order?.global_carrier,
          confirmed_booking: true,
          booking_type: source_ocean_transport_order?.booking_type,
          load_type: source_ocean_transport_order?.load_type || LOAD_TYPE_FCL,
          cargos: source_ocean_transport_order?.cargos,
          sales_person: source_ocean_transport_order?.sales_person,
          customer: {
            party_company: source_ocean_transport_order?.customer_company,
            party_address: source_ocean_transport_order?.customer_address,
          },
          surveyor: {
            party_company: source_ocean_transport_order?.surveyor_company,
            party_address: source_ocean_transport_order?.surveyor_address,
          },
          [`${rolloverOrShutout}_at`]: !!rolloverOrShutout && dayjsGenerateConfig.getNow(),
          [`${field_prefix}_by`]: !!rolloverOrShutout && SHUTOUT_BY[0],
          [`${field_prefix}_reason`]: !!rolloverOrShutout && reasons[SHUTOUT_BY[0]][0],
        }}
        onValuesChange={(changedValues, allValues) => {
          if (changedValues.routing_details) {
            const etd = getMainHopEtd(changedValues.routing_details.routing_legs);
            const current_valid_till_date = form.getFieldValue('valid_till_date');
            if (etd && etd.isBefore(current_valid_till_date))
              form.setFieldsValue({ valid_till_date: etd.subtract(1, 'day') });
          }
          if (changedValues && changedValues[`${field_prefix}_by`]) {
            form.setFieldsValue({
              [`${field_prefix}_reason`]: reasons[changedValues[`${field_prefix}_by`]][0],
            });
          }
          const { shouldReset, currentValue } = checkVoyageResetOnValuesChange({
            changedValues,
            allValues,
            previousValue,
          });
          setPreviousValue(currentValue);
          if (shouldReset) {
            getRoutingDetailsOnVoyageReset(allValues.routing_details, resetMainHopVoyageNumber);
          }
        }}
      >
        {!onlyUpdateRouting && <ShiftingContainersListView containers={containers} />}
        {formHiddenFields.map((field: string, index: number) => (
          <Form.Item noStyle name={field} key={index}>
            <span />
          </Form.Item>
        ))}
        {source_ocean_transport_order?.vendor && (
          <Form.Item noStyle name="vendor">
            <span />
          </Form.Item>
        )}
        <Card>
          <Row gutter={24}>
            <>
              {!onlyUpdateRouting && (
                <>
                  <Col span={12}>
                    <Form.Item
                      name="reason_for_cancellation"
                      label="Select reason for changing the route"
                      required
                      rules={[{ required: true }]}
                    >
                      <Select showSearch allowClear={false}>
                        {Object.keys(ROLLOVER_BY_REASONS).map((rollover_by: string) => (
                          <Select.OptGroup key={rollover_by} label={_startCase(rollover_by)}>
                            {ROLLOVER_BY_REASONS[rollover_by].map((option, index) => (
                              <Select.Option
                                key={`${rollover_by}_${index}`}
                                value={`${rollover_by}----${option}`}
                              >
                                {_startCase(option)}
                              </Select.Option>
                            ))}
                          </Select.OptGroup>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item required name="routing_updated_at" label="Rollover At">
                      <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                </>
              )}
              {onlyUpdateRouting && (
                <Col span={12}>
                  <Form.Item
                    required={
                      bookingType === BOOKING_TYPE_SHIPPING_LINE || loadType === LOAD_TYPE_LCL
                    }
                    rules={[
                      {
                        required:
                          bookingType === BOOKING_TYPE_SHIPPING_LINE || loadType === LOAD_TYPE_LCL,
                      },
                    ]}
                    name="carrier"
                    label={loadType === LOAD_TYPE_LCL ? 'Carrier/Co-loader' : 'Carrier'}
                  >
                    <GlobalSearch
                      doc_type="Global::Carrier"
                      searchProps={{ carrier_type: carrier_types }}
                    />
                  </Form.Item>
                </Col>
              )}
              {!onlyUpdateRouting && source_ocean_transport_order?.booking_type && (
                <Col span={12}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.confirmed_booking !== currentValues.confirmed_booking
                    }
                  >
                    {({ getFieldValue }) => {
                      const confirmed_booking = getFieldValue('confirmed_booking');

                      return (
                        <Form.Item
                          required={confirmed_booking}
                          rules={[{ required: confirmed_booking }]}
                          name="booking_number"
                          label="Booking number"
                        >
                          <Input placeholder="Booking Number" />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
              )}
            </>
          </Row>
          {!onlyUpdateRouting && source_ocean_transport_order?.booking_type && (
            <Row gutter={ROW_GUTTER}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.confirmed_booking !== currentValues.confirmed_booking ||
                  prevValues.routing_details !== currentValues.routing_details
                }
              >
                {({ getFieldValue }) => {
                  const confirmed_booking = getFieldValue('confirmed_booking');
                  return (
                    <BookingOrderNewDesign
                      loadType={loadType}
                      bookingType={bookingType}
                      disableEmptyPickupLocation
                      areCutoffRequired={
                        confirmed_booking &&
                        source_ocean_transport_order?.booking_type === BOOKING_TYPE_SHIPPING_LINE
                      }
                    />
                  );
                }}
              </Form.Item>
            </Row>
          )}
        </Card>
        <br />
        <Card title="Routing Details">
          <Form.Item noStyle name="voyage_schedule_id">
            <span />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.carrier !== currentValues.carrier
            }
            dependencies={['carrier']}
          >
            {({ getFieldValue }) => {
              return (
                <Form.Item
                  name="routing_details"
                  rules={[
                    {
                      validator: (rule, value) => {
                        if (!routing_details_ref?.current?.runValidation()) {
                          return Promise.resolve();
                        }
                        return Promise.reject();
                      },
                    },
                  ]}
                  noStyle
                >
                  <BookingRoutingDetailsNew
                    form={form}
                    ref={routing_details_ref}
                    validateVesselVoyage={true}
                    globalCarrierId={getFieldValue('carrier')?.id}
                    bookingType={source_ocean_transport_order?.booking_type}
                    isReeferContainer={Object.keys(containerSettingsMap.reefer).includes(
                      containers?.[0]?.container_type_code || ''
                    )}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Card>
        <br />

        {/* <Card title="Cargo Details">
          <CargoDetails form={form} load_type={source_ocean_transport_order?.load_type} />
        </Card> */}
        {!rolloverOrShutout && (
          <>
            <Card title="Container Details">
              <Form.Item name="container_requests" noStyle>
                <ContainerDetails
                  disableFields={[
                    'container_type_code',
                    'quantity',
                    'weight_per_container',
                    'weight_unit',
                    'is_shipper_owned',
                  ]}
                />
              </Form.Item>
            </Card>
            <br />
          </>
        )}
        {source_ocean_transport_order?.booking_type && (
          <Card title="Booking Cutoff Details">
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.confirmed_booking !== currentValues.confirmed_booking ||
                prevValues.routing_details !== currentValues.routing_details
              }
            >
              {({ getFieldValue }) => {
                const confirmed_booking = getFieldValue('confirmed_booking');
                const routing_legs = getFieldValue('routing_details')?.routing_legs;
                const etd = getMainHopEtd(routing_legs);
                const voyage_schedule_id = getFieldValue('voyage_schedule_id');
                const hasPrecarriage = hasPrecarriageLeg(routing_legs);
                return (
                  <>
                    <BookingOrderConfirmation
                      etd={etd}
                      areCutoffRequired={
                        confirmed_booking &&
                        source_ocean_transport_order?.booking_type === BOOKING_TYPE_SHIPPING_LINE
                      }
                      disableCutoff={getDisbaledCutoffDateMapping(
                        Boolean(voyage_schedule_id),
                        hasPrecarriage
                      )}
                      load_type={source_ocean_transport_order?.load_type}
                      disableEmptyPickupLocation
                      booking_type={source_ocean_transport_order?.booking_type}
                    />
                  </>
                );
              }}
            </Form.Item>
          </Card>
        )}
        {showFooter && (
          <DrawerFooter
            saveText="Update booking"
            onSave={() => {
              form.submit();
            }}
            loading={splitForRolloverShoutoutLoading || updateRoutingLoading}
            onClose={() => {
              if (onClose) onClose();
            }}
            showSendEmail
            sendEmail={sendEmail}
            // setSendEmail={setSendEmail}
            sendEmailText={`Send Split Confirmation`}
          />
        )}
      </Form>
    </>
  );
});

export default SplitAndCreateBooking;
