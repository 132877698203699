import { ShipmentValue } from 'operations/models/Shipment';
import { LOAD_TYPE_FTL_BREAK_BULK, LOAD_TYPE_FTL_BULK } from 'operations/baseConstants';
import { get, omit, pick } from 'lodash';
import { validateContainerNumber } from 'operations/models/ShipmentContainer';
import { errorMessageHandler } from 'common';
import { LOAD_TYPE_FCL } from 'network/baseConstants';
import {
  createDocument,
  fetchTemplates,
  getProperUserName,
} from 'operations/modules/reports/components/ShipmentDocumentReports/document';
import { SessionDataValue } from 'operations/models/SessionData';
import { FreightType, TradeType } from '../../constants';
import { message } from '@shipmnts/pixel-hub';
import { SHIPMENT_DOCUMENT_TYPE_HOUSE } from 'operations/modules/reports/constants';
import { DOC_TYPE_GENERATE_FUNCTION_MAPPING } from 'operations/modules/reports/components/ShipmentDocumentReports/DocumentGeneration';
import { DOCUMENT_TYPE_CONSIGNMENT_NOTE } from 'operations/modules/reports/constants';

export type LRSuggestionType = { value: string; label: string };
export const getVehicleData = (shipment: ShipmentValue) => {
  return {
    shipmentId: shipment.id,
    shipmentNumber: shipment.job_number || '',
    vehicle: shipment.vehicle || undefined,
    driver: shipment.driver,
    value: shipment?.vehicle?.vehicle_license_plate_number || '',
    loadCount:
      (shipment.load_type === LOAD_TYPE_FCL
        ? shipment?.shipment_containers?.length
        : shipment.load_type === LOAD_TYPE_FTL_BREAK_BULK
        ? shipment.total_number_of_packages
        : shipment.gross_weight) || 0,
    loadType:
      shipment.load_type === LOAD_TYPE_FCL
        ? 'Container'
        : shipment.load_type === LOAD_TYPE_FTL_BREAK_BULK
        ? 'Qty'
        : 'Gross Wt',
  };
};

export const autoGenerateLR = async (shipment: ShipmentValue, userData: SessionDataValue) => {
  const houseDocumentId = shipment?.shipment_documents?.find(
    (document) => document.document_type === SHIPMENT_DOCUMENT_TYPE_HOUSE
  )?.id;
  if (!houseDocumentId) return;

  const templates = await fetchTemplates(
    shipment?.freight_type as FreightType,
    shipment.trade_type as TradeType
  );

  const template_id = templates?.templates?.consignment_note?.id;
  if (!template_id) return;
  const params = {
    created_by_id: userData.id,
    created_by_name: getProperUserName(userData),
    document_ids: null,
    shipment_document_id: houseDocumentId,
    shipment_id: shipment.id,
    shipment_type: shipment.shipment_type,
    template_id,
  };

  const initialPayloadFunct = get(
    DOC_TYPE_GENERATE_FUNCTION_MAPPING,
    DOCUMENT_TYPE_CONSIGNMENT_NOTE,
    () => ({})
  );
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const initialPayload = await initialPayloadFunct({
    shipment: shipment,
    shipment_id: shipment.id,
  });

  const { response, error } = await createDocument({
    ...params,
    values: initialPayload,
  });

  if (response) {
    const data = response?.data;
    const { generate_document } = data;
    if (generate_document?.is_new_document) {
      window
        .open(`${process.env.OPERATIONS_URL}/view/documents/${generate_document.id}`, '_blank')
        ?.focus();
    } else {
      window
        .open(
          `${process.env.SHIPMNTS_WEB_URL}/shipment/${generate_document.shipment_id}/documents_latest/${generate_document.id}`,
          '_blank'
        )
        ?.focus();
    }

    message.success('Document created successfully');
  } else if (error) {
    message.error(errorMessageHandler(error as string));
  }
};

export const getShipmentLR = (shipment: ShipmentValue) => {
  const document = shipment?.shipment_documents?.find(
    (document) => document.document_type === 'house'
  );
  if (document) return document.shipment_number;
  else return '';
};

export const getTripByShipments = (
  shipments: ShipmentValue[] | undefined | null
): { [key: string]: ShipmentValue } => {
  const tripsByShipments: { [key: string]: ShipmentValue } = {};
  shipments?.forEach((shipment: ShipmentValue) => {
    if (!shipment.id) return;
    tripsByShipments[shipment.id] = shipment;
  });
  return tripsByShipments;
};

const calculateWeightOfPiece = (total_weight: number, total_qty: number, piece: number) => {
  return (total_weight / (total_qty || 1)) * piece;
};

export const generateAssignContainerPayload = (
  containers: any[],
  shipment?: ShipmentValue,
  isAddNewHouse?: boolean
) => {
  const updatedContainers: any[] = [];
  let errorOccured = false;
  const loads: LoadType[] = [];

  // itrate through container
  containers?.forEach((containerRow) => {
    const updatedContainerRow = {
      ...containerRow,
      container_settings: omit(containerRow.container_settings, '__typename'),
    };
    const errors: { [key: string]: string } = {};

    const vehicle_number = updatedContainerRow?.vehicle_details?.vehicle_license_plate_number;

    let shipmentId = null;
    let move_from_shipment_id = null;
    if (isAddNewHouse) {
      shipmentId = shipment?.id;
      move_from_shipment_id = shipment?.id;
      if (containerRow?.lr_number) {
        move_from_shipment_id = containerRow?.lr_number;
      }
    } else {
      if (shipment?.split_from_order_id) {
        // trip assignment
        if (containerRow?.lr_number) {
          shipmentId = containerRow?.lr_number;
        } else {
          shipmentId = shipment?.id;
        }
      } else {
        shipmentId = containerRow?.assign_vehicle_load?.shipmentId;
      }
    }

    if (!vehicle_number && !containerRow.is_row_selected) {
      updatedContainers.push({ ...updatedContainerRow });
      return;
    }

    const is_non_iso_container = updatedContainerRow.is_non_iso_container;
    const container_number = updatedContainerRow.container_number;
    try {
      if (!is_non_iso_container && container_number) {
        validateContainerNumber(container_number);
      }
    } catch (e) {
      errors['container_number'] = e as string;
    }

    if (Object.keys(errors).length) {
      errorOccured = true;
      updatedContainers.push({ ...updatedContainerRow, errors });
      return;
    }

    loads.push({
      order_id: shipment?.split_from_order_id || shipment?.id,
      shipment_id: shipmentId,
      container_id: updatedContainerRow.id,
      move_from_shipment_id,
    });

    updatedContainers.push({ ...updatedContainerRow });
  });
  return { updatedContainers, errorOccured, loads };
};

const VALIDATION_MESSAGES = {
  ALLOCATE_MORE_THAN_PENDING: `Can't Allocate more than Pending Qty`,
  CANT_MOVE_CARGO: `Can't move entire cargo`,
};
export const cargoValidations = (
  cargoRow: any,
  load_type: string,
  isUpdateAndAssignLoad = false,
  isAddNewHouse = false
) => {
  const allocatedQty = cargoRow.allocated_qty;
  const allocatedGrossWeight = cargoRow.allocated_gross_weight;
  const allocationPendingQuantity = cargoRow.allocation_pending_quantity;
  const errors: { [key: string]: string } = {};
  // validate required field presences
  if (isUpdateAndAssignLoad) {
    if (load_type === LOAD_TYPE_FTL_BREAK_BULK) {
      if (!cargoRow.outer_package_type) errors['outer_package_type'] = 'Package Type is required.';
      if (!cargoRow.outer_package_qty) errors['outer_package_qty'] = 'Qty is required.';
    } else {
      if (!cargoRow.gross_weight) {
        errors['gross_weight'] = 'Gross Weight is required.';
      }
    }
  } else if (!isAddNewHouse) {
    if (load_type === LOAD_TYPE_FTL_BREAK_BULK && !allocatedQty)
      errors['allocated_qty'] = 'Allocate Qty is required.';
    if (load_type !== LOAD_TYPE_FTL_BREAK_BULK && !allocatedGrossWeight)
      errors['allocated_gross_weight'] = 'Allocate Gross Weight is required.';
  }

  if (isAddNewHouse) {
    if (load_type === LOAD_TYPE_FTL_BREAK_BULK) {
      if (allocatedQty === allocationPendingQuantity && cargoRow.is_single_load)
        errors['allocated_qty'] = VALIDATION_MESSAGES.CANT_MOVE_CARGO;
      if (allocatedQty > cargoRow.outer_package_qty) {
        errors['allocated_qty'] = VALIDATION_MESSAGES.ALLOCATE_MORE_THAN_PENDING;
      }
    }
    if (load_type !== LOAD_TYPE_FTL_BREAK_BULK) {
      if (allocatedGrossWeight === allocationPendingQuantity && cargoRow.is_single_load)
        errors['allocated_gross_weight'] = VALIDATION_MESSAGES.CANT_MOVE_CARGO;
      if (allocatedGrossWeight > cargoRow.gross_weight) {
        errors['allocated_gross_weight'] = VALIDATION_MESSAGES.ALLOCATE_MORE_THAN_PENDING;
      }
    }
  }

  return errors;
};

export const checkCargoToAddAsLoad = (cargoRow: any, load_type: string) => {
  const allocatedQty = cargoRow.allocated_qty;
  const allocatedGrossWeight = cargoRow.allocated_gross_weight;
  const vehicle_number = cargoRow?.vehicle_details?.vehicle_license_plate_number;
  //no fields are set so skip
  if (!cargoRow.id) {
    if (!vehicle_number && !cargoRow.product_name) {
      if (load_type === LOAD_TYPE_FTL_BREAK_BULK) {
        if (!cargoRow.outer_package_type && !cargoRow.outer_package_qty) {
          return false;
        }
      } else {
        if (!cargoRow.gross_weight) {
          return false;
        }
      }
      return;
    }
  } else {
    if (cargoRow.is_row_selected) return true;
    if (!vehicle_number && !allocatedQty && !allocatedGrossWeight) {
      return false;
    }
  }

  return true;
};

export type LoadType = {
  shipment_id?: string;
  order_id?: string;
  container_id?: string;
  cargo?: any;
  move_cargo_id?: string;
  move_from_shipment_id?: string;
  partial_movement?: boolean;
};

export const generateAssignCargoPayload = (
  cargos: any[],
  initialCargos: any[],
  shipment?: ShipmentValue,
  isAddNewHouse?: boolean
) => {
  const updatedCargos: any[] = [];
  let errorOccured = false;
  const loads: LoadType[] = [];
  const load_type = shipment?.load_type as string;
  let splitFromCargoId: string | undefined;
  if (load_type === LOAD_TYPE_FTL_BULK) {
    splitFromCargoId = initialCargos?.[0]?.id;
    if (!splitFromCargoId) return { cargos, errorOccured: true, loads };
  }

  const intialCargoWeightById: { [key: string]: any } = {};
  initialCargos.forEach((cargo) => {
    if (cargo.id)
      intialCargoWeightById[cargo.id] = {
        gross_weight: cargo.gross_weight,
        net_weight: cargo.net_weight,
      };
  });

  // itrate through cargos
  cargos.forEach((cargoRow) => {
    const allocatedQty = cargoRow.allocated_qty;
    const allocatedGrossWeight = cargoRow.allocated_gross_weight;
    let errors: { [key: string]: string } = {};
    let shipmentId = null;
    let move_from_shipment_id = null;

    if (isAddNewHouse) {
      shipmentId = shipment?.id;
      move_from_shipment_id = shipment?.id;
      if (cargoRow?.lr_number) {
        move_from_shipment_id = cargoRow?.lr_number;
      }
    } else {
      if (shipment?.split_from_order_id) {
        // trip assignment
        if (cargoRow?.lr_number) {
          shipmentId = cargoRow?.lr_number;
        } else {
          shipmentId = shipment?.id;
        }
      } else {
        shipmentId = cargoRow?.assign_vehicle_load?.shipmentId;
      }
    }

    //no fields are set so skip
    const addToLoad = checkCargoToAddAsLoad(cargoRow, load_type);
    if (!addToLoad) {
      updatedCargos.push({ ...cargoRow });
      return;
    }

    // validate current row
    errors = {
      ...errors,
      ...cargoValidations(cargoRow, load_type, !cargoRow.id, isAddNewHouse),
    };

    if (Object.keys(errors).length) {
      errorOccured = true;
      updatedCargos.push({ ...cargoRow, errors });
      return;
    }

    let consider_gross_weight = cargoRow.gross_weight;
    let consider_net_weight = cargoRow.net_weight;

    if (load_type === LOAD_TYPE_FTL_BREAK_BULK) {
      if (!cargoRow.id) {
        // if new cargo then if allocated qty less than outer_package_qty then calculate gross_weight & net_weight
        if (cargoRow.outer_package_qty !== cargoRow.allocated_qty) {
          consider_gross_weight = calculateWeightOfPiece(
            cargoRow.gross_weight,
            cargoRow?.outer_package_qty,
            cargoRow?.allocated_qty
          );
          consider_net_weight = calculateWeightOfPiece(
            cargoRow.net_weight,
            cargoRow?.outer_package_qty,
            cargoRow?.allocated_qty
          );
        }
      } else {
        // if it's existing cargo
        // then if the gross weight is changed take changed gross_weight else calculate new gross weight based to allocated_qty
        // similarly calculate net_weight
        if (cargoRow.gross_weight !== intialCargoWeightById?.[cargoRow.id]?.gross_weight)
          consider_gross_weight = cargoRow.gross_weight;
        else
          consider_gross_weight = calculateWeightOfPiece(
            cargoRow.gross_weight,
            cargoRow?.outer_package_qty,
            cargoRow?.allocated_qty
          );
        if (cargoRow.net_weight !== intialCargoWeightById?.[cargoRow.id]?.net_weight)
          consider_net_weight = cargoRow.net_weight;
        else
          consider_net_weight = calculateWeightOfPiece(
            cargoRow.net_weight,
            cargoRow?.outer_package_qty,
            cargoRow?.allocated_qty
          );
      }
    }
    const cargo = {
      ...pick(cargoRow, [
        'batch_number',
        'custom_ref',
        'eway_bill_no',
        'eway_bill_validity',
        'gross_volume',
        'invoice_date',
        'invoice_number',
        'outer_package_type',
        'serial_number',
        'shipment_packages',
        'linked_to_id',
        'volume_unit',
        'weight_unit',
        'product_name',
        'dimension_unit',
      ]),
      cargo_properties: omit(cargoRow.cargo_properties, '__typename'),
      linked_to_id: splitFromCargoId || cargoRow.id,
      gross_weight: allocatedGrossWeight || consider_gross_weight || null,
      net_weight: consider_net_weight || cargoRow.net_weight,
      net_weight_unit: cargoRow.weight_unit,
      outer_package_qty: allocatedQty || cargoRow.outer_package_qty,
    };
    let partial_movement = !cargoRow.is_row_selected;
    if (cargoRow.is_row_selected) {
      partial_movement = false;
    } else {
      if (load_type === LOAD_TYPE_FTL_BREAK_BULK) {
        if (cargoRow.outer_package_qty === allocatedQty) partial_movement = false;
        else partial_movement = true;
      } else {
        if (consider_gross_weight === allocatedGrossWeight) partial_movement = false;
        else partial_movement = true;
      }
    }

    loads.push({
      order_id: shipment?.split_from_order_id || shipment?.id,
      shipment_id: shipmentId,
      cargo,
      move_cargo_id: isAddNewHouse ? cargoRow.id : undefined,
      move_from_shipment_id: move_from_shipment_id,
      partial_movement: partial_movement,
    });

    updatedCargos.push({ ...cargoRow });
  });
  return { updatedCargos, errorOccured, loads };
};
