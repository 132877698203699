import { GridOptions } from '@ag-grid-community/core';
import {
  Checkbox,
  Col,
  Form,
  FormInstance,
  message,
  Row,
  Tabs,
  TabsProps,
} from '@shipmnts/pixel-hub';
import { pick } from 'lodash';
import CargoProperties from 'operations/components/CargoProperties';
import { ProductOrderItemValue } from 'operations/models/ProductOrderItem';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { BY_PACKAGES, BY_TOTALS, cargoPickFields, setCargosProperty } from './helpers';
import CargoTableForm from './CargoTableForm';
import { FREIGHT_TYPE_AIR } from 'operations/utils/constants';
interface CargoNewComponentProps {
  form: FormInstance;
  loadType?: string;
  freightType?: string;
  disabled?: boolean;
  showAddDeleteButton?: boolean;
}

const CargoNewComponent = forwardRef(function CargoNewComponent(
  props: CargoNewComponentProps,
  ref
): JSX.Element {
  const [activeKey, setActiveKey] = useState<string>(BY_TOTALS);
  const { form } = props;
  const [isCargoPropDrawerOpen, setCargoPropDrawerOpen] = useState(false);
  const loadType = Form.useWatch('load_type', form) || props.loadType;
  const freightType = Form.useWatch('freight_type', form) || props.freightType;
  const gridRef = useRef<GridOptions>();
  const [requiredFieldMap, setRequiredFieldMap] = useState<Array<{ [key: string]: string }>>([]);
  useEffect(() => {
    if (freightType === FREIGHT_TYPE_AIR) setActiveKey(BY_PACKAGES);
    else setActiveKey(BY_TOTALS);
  }, [freightType]);

  useImperativeHandle(ref, () => ({
    runValidation() {
      const cargos: ProductOrderItemValue[] = [];
      gridRef?.current?.api?.forEachNode((node: any) => {
        cargos.push({
          ...node.data,
          dimension_unit: node?.data?.dimension_unit || 'cms',
          weight_unit: node?.data?.weight_unit || 'kgs',
          outer_package_type: node?.data?.outer_package_type || 'Box',
        });
      });
      const missingFieldsMessages: string[] = [];
      cargos.forEach((cargo: any) => {
        const missingFields = requiredFieldMap.filter((field) => {
          return !(cargo.hasOwnProperty(field['key']) && cargo[field['key']]);
        });

        if (missingFields.length > 0) {
          const formattedFields = missingFields.map((field) => {
            return field['value'];
          });
          missingFieldsMessages.push(formattedFields.join(', ') + ' is required.');
        }
      });
      if (missingFieldsMessages.length > 0) {
        missingFieldsMessages.forEach((errMsg) => message.error(errMsg));
        return true;
      }
      return false;
    },
    getPayload() {
      const cargos: ProductOrderItemValue[] = [];
      gridRef?.current?.api?.forEachNode((node: any) => {
        cargos.push(node.data);
      });
      return cargos.map((cargo) => {
        return {
          ...pick(cargo, cargoPickFields),
          weight_unit: cargo?.weight_unit || 'kgs',
          dimension_unit: cargo?.dimension_unit || 'cms',
          outer_package_type: cargo?.outer_package_type || 'Box',
        };
      });
    },
    getActiveTab() {
      return activeKey;
    },
    setActiveTab(key: string) {
      setActiveKey(key);
    },
  }));

  const items: TabsProps['items'] = [
    {
      key: BY_TOTALS,
      label: (
        <>
          By Totals
          <div style={{ fontSize: '12px' }}>Without Package Details</div>
        </>
      ),
      children: (
        <Form.Item name={'cargos'} noStyle>
          <CargoTableForm
            {...props}
            loadType={loadType}
            freightType={freightType}
            gridRef={gridRef}
            activeTab={activeKey}
            setRequiredFieldMap={setRequiredFieldMap}
          />
        </Form.Item>
      ),
    },
    {
      key: BY_PACKAGES,
      label: (
        <>
          By Packages
          <div style={{ fontSize: '12px' }}>With Package Details</div>
        </>
      ),
      children: (
        <Form.Item name={'cargos'} noStyle>
          <CargoTableForm
            {...props}
            loadType={loadType}
            freightType={freightType}
            gridRef={gridRef}
            activeTab={activeKey}
            setRequiredFieldMap={setRequiredFieldMap}
          />
        </Form.Item>
      ),
    },
  ];
  return (
    <>
      <Row>
        <Col span={6} xs={24} sm={12} lg={6}>
          <Form.Item
            name={['all_cargo_properties', 'is_hazardous']}
            valuePropName="checked"
            style={{ marginBottom: 0 }}
          >
            <Checkbox
              onChange={() => {
                setCargosProperty(form);
                setCargoPropDrawerOpen(true);
              }}
            >
              Hazardous Cargo
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span={6} xs={24} sm={12} lg={6}>
          <Form.Item
            name={['all_cargo_properties', 'is_temp_controlled']}
            valuePropName="checked"
            style={{ marginBottom: 0 }}
          >
            <Checkbox
              onChange={() => {
                setCargosProperty(form);
                setCargoPropDrawerOpen(true);
              }}
            >
              Temperature Controlled
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span={6} xs={24} sm={12} lg={6}>
          <Form.Item
            name={['all_cargo_properties', 'is_battery']}
            valuePropName="checked"
            style={{ marginBottom: 0 }}
          >
            <Checkbox
              onChange={() => {
                setCargosProperty(form);

                freightType === FREIGHT_TYPE_AIR && setCargoPropDrawerOpen(true);
              }}
            >
              Battery Inside
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span={6} xs={24} sm={12} lg={6}>
          <Form.Item
            name={['all_cargo_properties', 'is_perishable']}
            valuePropName="checked"
            style={{ marginBottom: 0 }}
          >
            <Checkbox onChange={() => setCargosProperty(form)}>Perishable</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name={['all_cargo_properties']} style={{ marginBottom: 0 }}>
        <CargoProperties
          showButton={false}
          freightType={freightType}
          title="Apply Cargo Properties For All"
          formKey={'all'}
          isDrawerOpen={isCargoPropDrawerOpen}
          setIsDrawerOpen={setCargoPropDrawerOpen}
          onChange={() => {
            const fields = form?.getFieldsValue();
            const new_cargos: any = [];
            fields?.cargos?.forEach((cargo: any) => {
              new_cargos.push({ ...cargo, cargo_properties: { ...fields?.all_cargo_properties } });
            });
            form?.setFieldsValue({ cargos: new_cargos });
          }}
        />
      </Form.Item>
      <Tabs
        onChange={(params) => {
          setActiveKey(params);
        }}
        activeKey={activeKey}
        destroyInactiveTabPane
        defaultActiveKey={activeKey}
        items={items}
      />
    </>
  );
});

export default CargoNewComponent;
