import { GridOptions } from '@ag-grid-community/core';
import { FormInstance } from '@shipmnts/pixel-hub';
import { ProductOrderItemValue } from 'operations/models/ProductOrderItem';
import { MutableRefObject } from 'react';
import React from 'react';

export interface CargoTableProps {
  form?: FormInstance;
  value?: Array<any>;
  onChange?: (value: Array<any>) => void;
  disabled?: boolean;
  loadType?: string;
  freightType?: string;
  gridRef: MutableRefObject<GridOptions | undefined>;
  showAddDeleteButton?: boolean;
  activeTab?: string;
  setRequiredFieldMap?: (value: Array<{ [key: string]: string }>) => void;
}
export const BY_PACKAGES = 'by_package';
export const BY_TOTALS = 'by_totals';

export function getFinalOptions(listOfValue: { name: string }[]) {
  const newList: { value: string; label: string }[] = [];

  listOfValue.forEach((option) => {
    newList.push({
      label: option.name,
      value: option.name,
    });
  });
  return newList;
}
export const disableCellStyle = { 'background-color': '#f2f2f2' };

export const weight_unit = [
  { value: 'kgs', label: 'kgs' },
  { value: 'mts', label: 'mts' },
];

export const dimension_unit = [
  { value: 'cms', label: 'cms' },
  { value: 'inches', label: 'inches' },
  { value: 'meter', label: 'meter' },
];

export const commodity_type = [
  { value: 'General Cargo', label: 'General Cargo' },
  { value: 'Pharmaceuticals', label: 'Pharmaceuticals' },
  { value: 'Perishable', label: 'Perishable' },
  { value: 'Dangerous Cargo', label: 'Dangerous Cargo' },
  { value: 'Human Remains', label: 'Human Remains' },
  { value: 'Live Animal', label: 'Live Animal' },
  { value: 'Valuable', label: 'Valuable' },
  { value: 'Others', label: 'Others' },
];

export const RequireHeaderWrapper = ({ text }: { text: string }) => {
  return <span style={{ whiteSpace: 'pre-line', fontSize: '14px', color: '#c23934' }}>{text}</span>;
};

export const getHeight = (value: any) => {
  if (!value) {
    return undefined;
  }
  let detailPanelHeight = 5 * 39 + 160;

  if ((value || []).length < 6) {
    detailPanelHeight = (value || []).length * 39 + 160;
  }

  return detailPanelHeight;
};

export const setCargosProperty = (form: FormInstance) => {
  const cargo_properties = form.getFieldValue('all_cargo_properties');
  const cargos = form?.getFieldValue('cargos');
  form?.setFieldsValue({
    cargos: cargos.map((crg: any) => {
      return {
        ...crg,
        cargo_properties: { ...crg.cargo_properties, ...cargo_properties },
      };
    }),
  });
};

export function createCargoPayload(cargo: any[]) {
  const remove_prefix = ['cargos_'];
  const rename = {};
  function removePrefixesAndRenameKeys(
    obj: any,
    prefixes: string[],
    renameMap: Record<string, string>
  ) {
    const result: any = {};

    for (const key in obj) {
      let newKey = key;

      // Remove prefixes
      prefixes.forEach((prefix) => {
        if (newKey.startsWith(prefix)) {
          newKey = newKey.slice(prefix.length);
        }
      });

      // Rename keys
      if (renameMap[key]) {
        newKey = renameMap[key];
      }

      const value = obj[key];

      // Process nested arrays or objects, but avoid processing dayjs objects
      if (Array.isArray(value)) {
        result[newKey] = value.map((item) =>
          removePrefixesAndRenameKeys(item, prefixes, renameMap)
        );
      } else if (
        typeof value === 'object' &&
        value !== null &&
        !value?.isValid() // Avoid processing dayjs objects
      ) {
        result[newKey] = removePrefixesAndRenameKeys(value, prefixes, renameMap);
      } else {
        result[newKey] = value;
      }
    }

    return result;
  }

  let cargos = cargo.map((item) => removePrefixesAndRenameKeys(item, remove_prefix, rename));

  cargos = cargos.map((c: any) => {
    if (c?.invoice_date) c['invoice_date'] = c?.['invoice_date']?.unix();
    if (c?.eway_bill_validity) c['eway_bill_validity'] = c?.['eway_bill_validity']?.unix();
    return c;
  });
  return cargos;
}

const convertToKgs = (uom: string, value: number) => {
  if (uom === 'lbs') return value * 0.453;
  else if (uom === 'mts') return value * 1000;
  return value;
};

const convertToMtr = (uom: string, value: number) => {
  if (uom === 'meter') return value;
  else if (uom === 'inches') return value * 0.0254;
  return value * 0.01;
};

const calculateGrossVolume = (data: any) => {
  const length = convertToMtr(data?.dimension_unit, data?.length || 0);
  const width = convertToMtr(data?.dimension_unit, data?.width || 0);
  const height = convertToMtr(data?.dimension_unit, data?.height || 0);
  return length * width * height * (data?.outer_package_qty || 0);
};

const calculateVolumetricWeight = (data: any) => {
  return (Math.pow(10, 3) * data.gross_volume) / 6;
};
const calculateChargeableWeight = (data: any) => {
  return Math.max(convertToKgs(data.weight_unit, data.gross_weight), data.volumetric_weight);
};
const calculateGrossWeight = (data: any) => {
  return (data.outer_per_packet_wt || 0) * (data.outer_package_qty || 0);
};

export const recalculateAndAssign = (params: any, targetField: string) => {
  if (!params || !params.data) return false;
  const data = params.data;
  let newValue = Number(Number(params.newValue || 0).toFixed(3));
  newValue = isNaN(newValue) ? params.newValue : newValue;
  if (['length', 'width', 'height', 'dimension_unit'].includes(targetField)) {
    data[targetField] = newValue;
    data.gross_volume = calculateGrossVolume(data);
    data.volumetric_weight = calculateVolumetricWeight(data);
    data.chargeable_weight = calculateChargeableWeight(data);
  } else if (targetField === 'gross_volume') {
    data[targetField] = newValue;
    data.volumetric_weight = calculateVolumetricWeight(data);
    data.chargeable_weight = calculateChargeableWeight(data);
  } else if (targetField === 'volumetric_weight') {
    data[targetField] = newValue;
    data.chargeable_weight = calculateChargeableWeight(data);
  } else if (targetField === 'chargeable_weight') {
    data[targetField] = newValue;
  } else if (targetField === 'outer_per_packet_wt') {
    data[targetField] = newValue;
    data.gross_weight = calculateGrossWeight(data);
    data.chargeable_weight = calculateChargeableWeight(data);
  } else if (['gross_weight', 'weight_unit'].includes(targetField)) {
    data[targetField] = newValue;
    data.chargeable_weight = calculateChargeableWeight(data);
  } else if (['outer_package_qty'].includes(targetField)) {
    data[targetField] = newValue;
    data.gross_volume = calculateGrossVolume(data);
    data.gross_weight = calculateGrossWeight(data);
    data.volumetric_weight = calculateVolumetricWeight(data);
    data.chargeable_weight = calculateChargeableWeight(data);
  } else {
    data[targetField] = newValue;
  }
  return true;
};

export const getInitialValueForCargoProperties = (cargos: ProductOrderItemValue[] | undefined) => {
  if (!cargos || cargos.length === 0) return undefined;
  const initialProperties = { ...cargos[0].cargo_properties };

  return initialProperties;
};

export const cargoPickFields = [
  'id',
  'linked_to_id',
  'parent_id',
  'parent_type',
  'commodity',
  'product_name',
  'commodity_type',
  'outer_package_type',
  'outer_package_qty',
  'weight_unit',
  'outer_per_packet_wt',
  'gross_weight',
  'dimension_unit',
  'length',
  'width',
  'height',
  'volumetric_weight',
  'chargeable_weight',
  'gross_volume',
  'cargo_properties',
  'serial_number',
  'invoice_number',
  'invoice_date',
  'batch_number',
  'custom_ref',
  'eway_bill_no',
  'eway_bill_validity',
  'net_weight',
];
