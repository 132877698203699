import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Drawer,
  Checkbox,
  Select,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Radio,
  Tooltip,
  Space,
} from '@shipmnts/pixel-hub';
import { FireOutlined, QuestionCircleOutlined } from '@shipmnts/pixel-hub';
import {
  packingGroup,
  TEMPERATURE_UNIT_CELSIUS,
  TEMPERATURE_UNIT_FAHRENHEIT,
  WEIGHT_UNIT_KGS,
  getUNDGNumberOptions,
} from 'operations/baseConstants';
import { CargoPropertyValue } from 'operations/models/Cargo';
import { batteryTypes } from 'operations/modules/reports/constants';
// import { FreightType } from 'operations/modules/shipment/constants';
const ROW_GUTTER = 16;
const { Option } = Select;

interface CargoPropertiesProps {
  value?: CargoPropertyValue | null;
  formKey: any;
  onChange?: (value: CargoPropertyValue) => void;
  freightType?: string;
  disabled?: boolean;
  title?: string;
  showButton?: boolean;
  isDrawerOpen?: boolean;
  setIsDrawerOpen?: (value: boolean) => void;
}

export const renderInputWithUnit = (
  key: string | Array<string>,
  unit_key: string | Array<string>,
  label: string,
  options: Array<{ key: string; name: string }>,
  disabledUnitKey?: boolean,
  inputNumberProps?: object,
  defaultSelect?: string
) => {
  return (
    <Input.Group compact style={{ width: '100%' }}>
      <Form.Item name={key} label={label} style={{ width: '70%' }}>
        <InputNumber style={{ width: '100%' }} placeholder={label} {...inputNumberProps} />
      </Form.Item>
      <Form.Item name={unit_key} label="Unit" style={{ width: '30%' }}>
        <Select disabled={disabledUnitKey} defaultValue={defaultSelect}>
          {options.map((unit) => (
            <Option key={unit.key} value={unit.key}>
              {unit.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Input.Group>
  );
};

const CargoProperties = React.memo(function CargoProperties(
  props: CargoPropertiesProps
): JSX.Element {
  let { value, title = 'Add Properties' } = props;
  const { disabled = false, showButton = true, isDrawerOpen = false, setIsDrawerOpen } = props;
  const { onChange } = props;
  const [openDrawer, setOpenDrawer] = useState(isDrawerOpen);
  const { formKey } = props;
  const [form] = Form.useForm();

  if (value?.is_hazardous) {
    title = 'Hazmat';
  }

  useEffect(() => {
    form.setFieldsValue({
      is_temp_controlled: value?.is_temp_controlled ? true : false,
      is_hazardous: value?.is_hazardous ? true : false,
      is_battery: value?.is_battery ? true : false,
    });
    setOpenDrawer(isDrawerOpen);
  }, [form, value, isDrawerOpen]);

  if (!value) {
    value = {
      net_weight_unit: WEIGHT_UNIT_KGS,
      flash_point_unit: TEMPERATURE_UNIT_CELSIUS,
    } as CargoPropertyValue;
  }

  let haz_data: any = {};
  haz_data = require('operations/dg_mapping.json');

  const closeDrawer = () => {
    form.resetFields();
    setOpenDrawer(false);
    setIsDrawerOpen && setIsDrawerOpen(false);
  };
  return (
    <div>
      {showButton && (
        <Button
          disabled={disabled}
          size="small"
          onClick={() => setOpenDrawer(true)}
          icon={value?.is_hazardous && <FireOutlined />}
        >
          {title}
        </Button>
      )}
      <Drawer
        forceRender
        title={`Cargo Properties`}
        width="40%"
        onClose={closeDrawer}
        open={openDrawer}
        footer={
          <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
            <Popconfirm
              title="Are you sure to discard the unsaved changes?"
              onConfirm={closeDrawer}
              okText="Yes"
              cancelText="No"
            >
              <Button>Close</Button>
            </Popconfirm>
            <Button
              htmlType="submit"
              type="primary"
              key="save"
              style={{ marginLeft: '16px' }}
              onClick={form.submit}
            >
              Save Settings
            </Button>
          </Space>
        }
      >
        <Form
          name={`${formKey}_cargo_properties`}
          form={form}
          scrollToFirstError
          onFinish={(values) => {
            if (onChange) onChange(values as CargoPropertyValue);
            setOpenDrawer(false);
            setIsDrawerOpen && setIsDrawerOpen(false);
          }}
          layout="vertical"
          initialValues={value}
          onValuesChange={(changedValues, allValues) => {
            if (Object.keys(changedValues).includes('un_number')) {
              const dgValues = haz_data[changedValues.un_number];
              form.setFieldsValue({
                un_imo_class: dgValues.class,
                proper_shipping_name: dgValues.psn,
              });
            }
            if (
              Object.keys(changedValues).includes('is_hazardous') &&
              !changedValues.is_hazardous
            ) {
              form.setFieldsValue({
                un_number: undefined,
                un_imo_class: undefined,
                proper_shipping_name: undefined,
                technical_name: undefined,
                packaging_group: undefined,
                net_weight: undefined,
                flash_point: undefined,
                ems_number: undefined,
                emergency_contact_number: undefined,
                emergency_contact_name: undefined,
              });
            }
          }}
        >
          <Row>
            <Form.Item name="is_hazardous" valuePropName="checked">
              <Checkbox>
                Hazardous Cargo
                <Tooltip title="Cargo with explosive, flammable, toxic, or radioactive properties">
                  <QuestionCircleOutlined />
                </Tooltip>
              </Checkbox>
            </Form.Item>
          </Row>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              return getFieldValue('is_hazardous') ? (
                <>
                  <Row gutter={ROW_GUTTER}>
                    <Col lg={12} md={24}>
                      <Form.Item
                        required
                        rules={[{ required: true }]}
                        label="UNDG Number"
                        name="un_number"
                        dependencies={['is_hazardous']}
                        tooltip={
                          "The UNDG number indicates the substance's hazard classification and is used to identify the correct handling, packaging, and labelling"
                        }
                      >
                        <Select
                          showSearch
                          placeholder="Enter Number"
                          options={getUNDGNumberOptions()}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={12} md={24}>
                      <Form.Item
                        name="un_imo_class"
                        required
                        rules={[{ required: true }]}
                        label="UN IMO Class"
                        dependencies={['is_hazardous', 'un_number']}
                        tooltip="The UN IMO class is a system used to classify dangerous goods into nine classes based on their properties"
                      >
                        <Input placeholder="Enter IMO Class" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={ROW_GUTTER}>
                    <Col lg={12} md={24}>
                      <Form.Item
                        name="proper_shipping_name"
                        label="Proper Shipping Name"
                        dependencies={['is_hazardous', 'un_number']}
                      >
                        <Input placeholder="Enter Name" />
                      </Form.Item>
                    </Col>
                    <Col lg={12} md={24}>
                      <Form.Item name="technical_name" label="Technical Name">
                        <Input placeholder="Enter Name" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={ROW_GUTTER}>
                    <Col lg={12} md={24}>
                      <Form.Item name="packaging_group" label="Packaging Group">
                        <Select placeholder="Select Packaging Group" options={packingGroup} />
                      </Form.Item>
                    </Col>
                    <Col lg={12} md={24}>
                      {renderInputWithUnit(
                        'net_weight',
                        'net_weight_unit',
                        'Net Weight',
                        [{ key: WEIGHT_UNIT_KGS, name: WEIGHT_UNIT_KGS }],
                        true,
                        { min: 0 }
                      )}
                    </Col>
                  </Row>
                  <Row gutter={ROW_GUTTER}>
                    <Col lg={12} md={24}>
                      {renderInputWithUnit('flash_point', 'flash_point_unit', 'Flash Point', [
                        { key: TEMPERATURE_UNIT_CELSIUS, name: TEMPERATURE_UNIT_CELSIUS },
                        { key: TEMPERATURE_UNIT_FAHRENHEIT, name: TEMPERATURE_UNIT_FAHRENHEIT },
                      ])}
                    </Col>
                    <Col lg={12} md={24}>
                      <Form.Item name="ems_number" label="EMS Number">
                        <Input placeholder="Enter Number" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={ROW_GUTTER}>
                    <Col lg={12} md={24}>
                      <Form.Item name="emergency_contact_name" label="Emergency Contact Person">
                        <Input placeholder="Enter Name" />
                      </Form.Item>
                    </Col>
                    <Col lg={12} md={24}>
                      <Form.Item name="emergency_contact_number" label="Emergency Contact Number">
                        <Input placeholder="Enter Number" />
                      </Form.Item>
                    </Col>
                  </Row>
                  {props.freightType === 'air' && (
                    <Row gutter={ROW_GUTTER}>
                      <Col md={24} lg={12}>
                        <Form.Item name="aircraft_type" label="Aircraft Type">
                          <Radio.Group defaultValue="passenger">
                            <Radio.Button value="cargo">Cargo</Radio.Button>
                            <Radio.Button value="passenger">Passenger</Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col md={24} lg={12}>
                        <Form.Item name="is_radioactive" label="RadioActive">
                          <Radio.Group
                            defaultValue={false}
                            options={[
                              { label: 'Yes', value: true },
                              { label: 'No', value: false },
                            ]}
                          ></Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </>
              ) : (
                <span />
              );
            }}
          </Form.Item>
          <Row>
            <Form.Item name="is_temp_controlled" valuePropName="checked">
              <Checkbox>
                Temperature Controlled
                <Tooltip title="If the goods being shipped require temperature-controlled transportation">
                  <QuestionCircleOutlined />
                </Tooltip>
              </Checkbox>
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                return (
                  getFieldValue('is_temp_controlled') && (
                    <>
                      <Row>
                        {renderInputWithUnit('temperature', 'temperature_unit', 'Temperature', [
                          { key: TEMPERATURE_UNIT_CELSIUS, name: TEMPERATURE_UNIT_CELSIUS },
                          { key: TEMPERATURE_UNIT_FAHRENHEIT, name: TEMPERATURE_UNIT_FAHRENHEIT },
                        ])}
                      </Row>
                    </>
                  )
                );
              }}
            </Form.Item>
          </Row>
          <Row>
            <Form.Item name="is_battery" valuePropName="checked">
              <Checkbox>Battery Inside</Checkbox>
            </Form.Item>
          </Row>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              return getFieldValue('is_battery') && props.freightType === 'air' ? (
                <Row gutter={ROW_GUTTER}>
                  <Col lg={12} md={24}>
                    <Form.Item
                      required
                      rules={[{ required: true }]}
                      label="Battery Type"
                      name="battery_type"
                      dependencies={['is_battery']}
                      initialValue={'LI-on'}
                    >
                      <Select showSearch options={batteryTypes} placeholder="Select Battery Type" />
                    </Form.Item>
                  </Col>
                </Row>
              ) : (
                <span />
              );
            }}
          </Form.Item>
          <Row>
            <Form.Item name="is_perishable" valuePropName="checked">
              <Checkbox>
                Perishable
                <Tooltip title="Limited shelf life and must be transported in a controlled temperature and humidity environment to prevent spoilage or decay.">
                  <QuestionCircleOutlined />
                </Tooltip>
              </Checkbox>
            </Form.Item>
          </Row>
        </Form>
      </Drawer>
    </div>
  );
});

export default CargoProperties;
